@import 'mixins';
$item-padding: 1rem;
$vertical-margin: 0.5rem;
$max-image-post-container-height: 219px;

.featureCard {
  cursor: pointer;
  overflow: hidden;

  .image {
    min-height: $max-image-post-container-height;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--bali-hai);
    font-size: 0.75rem;
    overflow: hidden;

    .eCard {
      border-radius: 12px;
      width: 90%;
      margin: 1rem 0;
    }
  }

  .body {
    padding: $item-padding;

    .id {
      color: var(--text-light-gray);
      margin-bottom: $vertical-margin;
    }

    .details {
      @include clamp-on-line;
    }

    .label {
      color: var(--text-light-gray);
      margin-bottom: $vertical-margin;
    }

    .separator {
      margin: 1rem 0;
    }

    .stats {
      display: flex;

      & > * {
        flex: 1;
        display: flex;
        flex-flow: column;
      }
    }
  }
}
