@import 'mixins';

.cardSelectionSection {
  margin-top: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > a {
    text-decoration: none;
  }
}

a {
  max-width: 100%;
}

.selectionCard {
  width: 30rem;
  height: 5rem;
  max-width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @include for-mobile {
    max-width: 45vh;
  }

  .selectionCardImage {
    height: 100%;
  }

  .selectionCardImageMaple {
    padding: 1rem;
  }

  .selectionCardContent {
    display: flex;
    flex-direction: column;
    justify-items: space-around;
    height: 100%;
    flex: 2;
    padding-left: 1rem;
    justify-content: center;
    text-align: left;

    > h5 {
      margin: 0;
      font-size: 1rem;
      color: var(--charcoal-gray);
      font-weight: 500;
    }

    > p {
      margin: 0;
      font-size: 14px;
      font-weight: normal;
      margin-top: 0.25rem;
      color: var(--charcoal-gray);
    }
  }

  .selectionCardIcon {
    flex: 0.2;
  }
}
