@import 'mixins';

.contactUsCard {
  padding: 2.5rem 1.4375rem;

  @include for-desktop {
    padding: 2.125rem 0;
  }
  p {
    margin: 0;
    margin-bottom: 0.875rem;
    line-height: normal;
  }
}

.buttons {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.button {
  margin-top: 0.5rem;
  border-radius: 4px;
  border: solid 1px var(--silver);
  color: var(--pelorous);
}

.chatWithUsButton {
  @extend .button;
}
