/*
  To override airbnb's component styles, no hash scoping can be used,
  so tell CRA to skip css modules by excluding `module` from `DateRangePicker.scss`
  This allows style overriding. References:
  - https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
  - https://github.com/airbnb/react-dates#overriding-styles
*/

.DateRangePicker_picker {
  z-index: 100;
}

.DateRangePickerInput__withBorder {
  border: 1px solid var(--very-light-grey);
}

.DateRangePickerInput__withBorder {
  border-radius: 5px;
}
.DateRangePickerInput_arrow_svg {
  fill: var(--apple-green);
}

.DayPicker_weekHeader_li {
  user-select: none;
}
