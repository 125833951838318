@import 'mixins';

div.missingImgStrip {
  display: flex;
  align-items: center;
  background-color: var(--alice-blue);
  min-height: 72px;
  border-radius: 10px;
  margin-bottom: 24px;
  line-height: 1.4rem;
  padding: 10px;

  > * {
    color: var(--pelorous);
  }

  .inlineLink {
    cursor: pointer;
    text-decoration: underline;
    color: inherit;

    &:hover {
      background-color: inherit;
      color: inherit;
    }
  }

  > .blupIcon {
    margin: 1rem;

    @include for-mobile {
      margin: 0.6rem;
    }
  }
}
