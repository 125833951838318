@import 'mixins';

%space-between-text {
  display: flex;
  justify-content: space-between;
}

.date {
  color: var(--dark-gray);
  font-size: 0.875rem;
  font-weight: 500;
}

.card {
  .topSection {
    @extend %space-between-text;
    align-items: center;

    .starRating {
      align-self: flex-end;
      margin-top: 0.5rem;
    }
  }
  .name {
    color: var(--light-black);
    font-size: 1rem;
    font-weight: 500;
  }
  .userComment {
    font-size: 1rem;
    line-height: 1.38;
    letter-spacing: 0.08px;
    color: var(--text-dark-gray);
    margin: 1rem 0;
  }
}

.replyComment {
  padding: 1rem;
  background-color: var(--white-smoke);
  border-radius: 8px; // fixed by design

  .replyHeader {
    @extend %space-between-text;

    > span {
      font-size: 1rem;
      font-weight: 500;
      color: var(--primary);
    }
  }
  > p {
    overflow-wrap: break-word;
    margin-bottom: 0;
  }

  .clampSecondLine {
    @include clamp-on-line;
  }
}

.reviewContainer {
  display: flex;
  flex-flow: column;
  padding: 0.6875rem 0;
  border-bottom: 1px solid var(--light-gray);

  .replyButton {
    height: 2.25rem;
    width: 6.875rem;
    background-color: var(--apple-green);
    justify-content: center;
    margin-left: auto;
    padding: 0;

    &:hover {
      background-color: var(--apple-green) !important; // hot fix
    }
  }

  .replyTextArea {
    margin: 1.0625rem 0;

    > textarea {
      width: 100%;
      height: 13.75rem;
      border-radius: 5px;
      border: 1px solid var(--forest-green);
    }
  }

  .replyButtonControl {
    display: flex;
    justify-content: flex-end;

    .baseClassButton {
      width: 7.3125rem;
      height: 2.25rem;
      border-radius: 5px;
      padding: 0;
      justify-content: center;
    }

    .submitButton {
      @extend .baseClassButton;
      color: var(--apple-green);
      border: 1px solid var(--apple-green);
      margin-left: 0.625rem;
    }

    .cancelButton {
      @extend .baseClassButton;
      color: var(--gray);
      border: 1px solid var(--gray);
    }
  }
}
