@import 'mixins';

.TableContainer {
  --border-size: 1px;
  --border-radius: 0.8rem;
  --border-color: var(--spindle);
  border: var(--border-size) solid var(--border-color);
  border-radius: var(--border-radius);
  margin: 2rem 0;
  overflow-x: auto;

  .Table {
    border-collapse: collapse;
    width: 100%;

    thead {
      th {
        border-bottom: var(--border-size) solid var(--border-color);
      }
    }

    th,
    td {
      @include for-desktop {
        padding: 1.25rem;
      }
      padding: 1rem;
      border-right: var(--border-size) solid var(--border-color);

      &:last-child {
        border-right: none;
      }
      text-align: left;
    }

    th {
      &:not(:first-child) {
        @include for-desktop {
          text-align: right;
        }
      }
    }

    td {
      &:not(:first-child) {
        text-align: right;
      }
    }
  }
}
