@import 'mixins';

.container {
  top: 0;
  position: fixed;
  z-index: 100;
  right: 0;
  left: 0;
  transform: translateY(-50vh);
  transition: all ease 0.5s;
  &.visible {
    transform: translateY(0);
  }
}
.content {
  position: relative;
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto auto;
  align-items: center;
  justify-content: center;
  padding: 1.75rem;
  background-color: transparent;
  color: var(--white);
  &[data-type='error'] {
    background-color: var(--red);
  }
  &[data-type='success'] {
    background-color: var(--aqua-spring);
    color: var(--forest-green);
  }
  &[data-type='warning'] {
    background-color: var(--tangerine-orange);
  }
  &[data-type='info'] {
    background-color: var(--alice-blue);
    color: var(--pelorous);
  }
}

.message {
  margin: 0;
  font-size: 0.875rem;

  @include for-mobile {
    grid-column: 1;
    grid-row: 1;
  }
}

.button.closeButton {
  &:hover:not([disabled]) {
    background-color: rgba(255, 255, 255, 0.15);
  }

  @include for-mobile {
    grid-column: 2;
    grid-row: 1;
  }
}

.ctaButton {
  background-color: transparent;
  border-color: var(--pelorous);
  color: var(--pelorous);

  @include for-mobile {
    grid-column: 1;
    grid-row: 2;
    justify-self: flex-start;
  }
}
