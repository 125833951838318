@import 'variables';

$link-color: var(--pelorous);

.inlineLink {
  cursor: pointer;
  color: $link-color;
  text-decoration: underline;

  &:focus,
  &:hover {
    color: $link-color;
  }

  &:hover {
    text-decoration: underline;
  }
}
