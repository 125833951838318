@import 'variables';

.container {
  position: relative;

  $transition-time: 0.3s;
  $dot-size: 0.4rem;

  .tabContent {
    width: 100%;
    overflow: hidden;
    will-change: transform;
    position: absolute;

    .swiper {
      transition: max-height $transition-time, transform $transition-time;
      display: inline-flex;
      max-height: fit-content;

      .slideItem {
        padding: 4px; //fixed for all screen sizes
      }
    }
  }
}
