@import 'mixins';

.overlay {
  background: var(--white);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .switchLanguageCard {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1.25rem 1.6875rem;
    @include for-desktop {
      padding: 2.25rem 2.6875rem;
    }
  }

  .title {
    color: var(--apple-green);
    font-weight: 500;
    font-size: 1rem;
    margin: 0 3rem 1.5rem 0;

    @include for-desktop {
      margin: 0 4rem 1.5rem 0;
    }
  }

  .languageIcon {
    background-color: var(--apple-green);
    height: 1.25rem;
    width: 1.25rem;
    margin-left: auto;
    mask: var(--icon-check) no-repeat center center/contain;
  }

  .languageBadge {
    font-size: 0.85rem;
    margin-right: 1rem;
    padding: 0.1rem 0.3rem;
    width: 2rem;
    background-color: var(--gainsboro);
    color: var(--black);
    font-weight: 500;
  }

  .activeLanguage {
    color: var(--apple-green);
    font-weight: 500;
  }
  .activeBadge {
    background-color: var(--granny-apple);
  }

  .chineseFont {
    font-size: 0.65rem;
    font-weight: 600;
  }
}

.languages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 70vh;
}

.loadingSpinnerWrapper {
  position: relative;
  padding: 3rem 0;
}

.button.languageButton {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 3rem;
  min-height: 3rem;
}
