.label {
  display: flex;
  flex-direction: column;
  flex: 1;

  input {
    font-size: 1.125rem;
    height: 3.125rem;
    line-height: 1;
    padding: 0 1.25rem;
    border-radius: 6px;
    border: 1px solid var(--very-light-grey);
    width: 100%;
    transition: border 0.2s linear;

    &:focus {
      border: 2px solid var(--forest-green);
      outline: none;
    }

    &::placeholder {
      color: var(--bermuda-grey);
    }
  }
}
