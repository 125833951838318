@import '../mixins';

.card {
  display: flex;
  flex-direction: column;

  .hero {
    border-bottom: 1px solid var(--very-light-grey);

    .heroImage {
      margin-top: 2.25rem;
      justify-self: center;
    }
    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: var(--charcoal-gray);
      margin: 0 0 1rem;
    }
    .paragraph {
      color: var(--fiord);
      line-height: 1.63;
      margin-bottom: 2.5rem;
    }
  }
  .tnc {
    font-size: 0.75rem;
    color: var(--charcoal-gray);
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tncRow {
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      > input {
        width: 1rem;
        height: 1rem;
      }
      > span {
        margin-left: 0.5rem;
      }
    }
  }

  .businessLayout {
    margin-top: 2.5rem;
  }

  .bankLayout,
  .outletLayout {
    margin-top: 3.0625rem;
  }
  .outletAddress {
    @include truncate;
    > p {
      margin: 0 0 1.525rem;
      font-weight: 500;
      line-height: 1.38;
      letter-spacing: 0.08px;
      color: var(--charcoal-gray);
      white-space: normal;
    }

    .addressButton [data-icon] {
      display: none;
      @include for-desktop {
        display: inline-block;
      }
    }
  }
}
