$button-size: 3rem;
$button-margin: 1rem;

.button {
  cursor: pointer;
  width: button-size;
  height: button-size;
  position: fixed;
  bottom: $button-margin;
  right: $button-margin;
  animation: fadeIn 200ms ease-in-out 100ms both;
  z-index: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
