@import 'mixins';

.wrapper {
  top: 0;
  position: sticky;
  z-index: 10;
}

.promoContainer {
  display: flex;
  width: 100%;
  background-color: var(--alice-blue);
  justify-content: center;
  align-items: center;
  padding: 0.813rem 0 0.813rem 1rem;
}

.promoTitle {
  font-family: Rubik;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.promoText {
  display: block;
  width: auto;
  font-family: Rubik;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--pelorous);

  @include for-mobile {
    font-size: 0.75rem;
  }

  &:before {
    content: '';
    display: flex;
    flex: 1;
  }
}

.empty {
  display: flex;
  flex: 1;
}

.promoInstallButton {
  background-color: transparent;
  border-color: 1px solid var(--pelorous);
  color: var(--pelorous);
  height: 2rem;
  min-height: unset;
  margin: 0 0.75rem;

  @include for-mobile {
    height: 1.5rem;
  }
}

.promoCloseButton {
  display: flex;
  flex: 1;
  justify-content: center;
  margin: auto;
  min-width: 2rem;
  color: var(--pelorous);

  &:hover:not([disabled]) {
    background-color: rgba(255, 255, 255, 0.15);
  }
}
