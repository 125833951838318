/*
  To override airbnb's component styles, no hash scoping can be used,
  so tell CRA to skip css modules by excluding `module` from `DateRangePicker.scss`
  This allows style overriding. References:
  - https://create-react-app.dev/docs/adding-a-css-modules-stylesheet
  - https://github.com/airbnb/react-dates#overriding-styles
*/

.DateInput_input {
  color: var(--very-light-grey);
  font-size: 1rem;
  text-align: center;
  line-height: 1;
}

.DateInput.DateInput_1 {
  width: 7.1875rem;
}

.DateInput_input,
.DateInput {
  background-color: transparent;
}

.DateInput_input__focused {
  border-bottom-color: var(--apple-green);
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border-color: var(--apple-green);
  background-color: var(--apple-green);
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background-color: var(--fern);
  border-color: var(--apple-green);
  &:active,
  &:hover {
    border-color: var(--fern);
    background-color: var(--apple-green);
  }
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background-color: var(--aqua-spring);
  border-color: var(--apple-green);
}

// Hide keyboard shortcuts button
.DayPickerKeyboardShortcuts_show {
  visibility: hidden;
}

.DayPicker_portal__vertical {
  height: 100%;
}
