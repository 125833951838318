@import 'mixins';

.container {
  width: 50%;
  margin-top: 1.5rem;

  @include for-mobile {
    width: 100%;
    margin-top: 1.688rem;
  }

  span {
    display: block;
    font-family: Rubik;
    font-size: 1rem;
    line-height: 1.188rem;
    color: var(--charcoal-gray);
  }
}

.title {
  display: block;
  margin-bottom: 1rem;

  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.313rem;
  color: var(--text-dark-gray);
}

.table {
  max-width: 25rem;
  padding: 0.375rem 0 0;
  border: 1px solid var(--very-light-grey);
  border-radius: 8px;
  margin-bottom: 2rem;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;

  .strike {
    text-decoration-line: line-through;
    color: var(--very-light-grey);
  }
}

.withBottomBorder {
  border-bottom: 1px solid var(--very-light-grey);
}

.withIcon {
  display: flex;
  align-items: center;
}

.takeback {
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  span {
    font-weight: 500;
    color: var(--light-black);
  }
}

.info {
  margin-bottom: 1.75rem;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.313rem;
  color: var(--charcoal-gray);
}

.term {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-bottom: 1.375rem;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: var(--charcoal-gray);

  span {
    padding-left: 1rem;
  }
}

.faq {
  margin-top: 0.875rem;
  background: var(--alice-blue);
  border-radius: 8px;
  border: 0;
  height: auto;
  width: 100%;
  max-width: 25rem;
  padding: 1rem;

  &:hover:not([disabled]) {
    background-color: var(--alice-blue);
  }

  div {
    margin-right: auto;
    white-space: initial;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  span:nth-child(1) {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: var(--pelorous);
    min-width: 1rem;
  }
  span:nth-child(2) {
    text-align: start;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: var(--pelorous);
    min-width: 1rem;
  }
}

.modal {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--charcoal-gray);
  text-align: center;

  .title {
    font-size: 1.125rem;
    font-weight: 500;
  }

  .description {
    white-space: pre-line;
  }

  .submitButton {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 3.125rem;
    margin-top: 2rem;
    min-width: 15rem;

    font-family: Rubik;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center;
  }
}
