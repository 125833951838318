@import 'mixins';

.cashbackContainer {
  display: flex;
  flex-flow: column;
}

.topContainer {
  margin-bottom: 2rem;
}

.title {
  font-size: 1.125rem;
  margin: 0;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-gray);
}

.subtitle {
  font-size: 1rem;
  margin: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-light-gray);
}

.cashbackRateOption {
  display: grid;
  grid-auto-rows: 3.5rem;
  column-gap: 1rem;
  row-gap: 2.5rem;
  border-radius: 10px;
  margin-bottom: 2.5rem;
  padding: 1rem 1.25rem 2rem;

  @include for-desktop {
    grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
    justify-content: space-around;
    align-items: center;
    padding: 1.75rem 2.375rem 2.75rem;
  }
}

.rectangle {
  border-radius: 6px;
  border: solid 1px var(--bermuda-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;

  font-size: 1.25rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.07px;
  text-align: center;
  color: var(--grayish-blue);

  @include for-desktop {
    height: 3.5rem;
  }

  &.active {
    color: var(--true-white);
    border-color: inherit;
    background-color: var(--forest-green);
  }
}

.rectangleLast {
  @extend .rectangle;
  grid-column: span 4;
}

.recommended {
  position: absolute;
  top: 3.5rem;
  left: 0;
  cursor: default;
  pointer-events: none;

  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--grayish-blue);

  margin-top: 0.5rem;
}

.recommendedSelected {
  @extend .recommended;
  color: var(--forest-green);
}

.outletsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  margin-top: 1.875rem;
  row-gap: 2rem;
}

.checkboxContainer {
  display: flex;
  flex: 1;
  align-items: center;
}

.checkbox {
  align-self: flex-start;
}

.checkboxText {
  margin-left: 1rem;
}

.outletNameText {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.07px;
  color: var(--grayish-blue);
}

.outletNameTextIsChecked {
  @extend .outletNameText;
  color: var(--text-dark-gray);
}

.outletLocationText {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.06px;
  color: var(--grayish-blue);
}

.outletLocationTextIsChecked {
  @extend .outletLocationText;
  color: var(--text-dark-gray);
}

.customCashbackRateContainer {
  display: flex;
  position: relative;
  grid-column: span 4;
}

.customCashbackRate {
  border-radius: 6px;

  > input {
    height: 100%;
    border: solid 2px var(--grayish-blue);
  }
}

.customCashbackRateButton {
  height: 3.5rem;
  margin-left: 0.5rem;

  p {
    text-align: center;
    width: 100%;
  }
}

.cashbackEditButton {
  height: 100%;
  width: 100%;
  grid-column: span 4;

  font-size: 1.25rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.2px;
  color: var(--true-white);

  > span[data-icon='true'] {
    margin-left: auto;
  }
}

.buttonSubmitDone {
  margin: 0 auto;
  width: 100%;
  height: 3.5rem;
  margin-top: 2.5rem;

  @include for-desktop {
    margin-left: auto;
    margin-right: 0;
    width: 22.5rem;
  }

  > span[data-icon='true'] {
    margin-left: auto;
  }
}

.errorMessage {
  @extend .recommended;
  color: var(--red);
}

.modalContainer {
  padding: 2.375rem 1rem 2.125rem;
  text-align: center;
  @include for-desktop {
    padding: 2.375rem 5rem 2.125rem;
  }
}

.modalTitle {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-dark-gray);
}

.modalSubtitle {
  margin: 0;
  display: inline-block;

  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-light-gray);
}

.modalSubtitleGreen {
  @extend .modalSubtitle;
  font-weight: 500;
  color: var(--forest-green);
}

.modalSubtitleBlack {
  @extend .modalSubtitle;
  color: var(--text-dark-gray);
}

.modalButtonGroup {
  display: flex;
  margin-top: 2rem;
  justify-content: space-around;

  button:nth-child(1) {
    border-radius: 5px;
    border-color: var(--forest-green);
    color: var(--forest-green);
    background-color: var(--true-white);
  }

  > button {
    height: 3.125rem;
    justify-content: center;
    max-width: 10rem;
    width: 100%;

    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
