@import '../../../assets/css/mixins';

.header {
  background: var(--true-white);
  box-shadow: 0 2px 12px 3px rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;

  .logo {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 3.75rem;
    min-width: 2rem;
    --logo: url(../images/insights-logo.svg);

    @include for-desktop {
      max-width: 6rem;
      margin-left: 2.75rem;
    }
  }

  .companyName {
    margin: auto;
    margin-left: 0;
    font-size: 1rem;
    font-weight: 500;
    display: none;

    @include for-desktop {
      display: block;
    }
  }

  .headerOptions {
    margin: 0;
    padding: 0.5625rem 1.125rem;
    display: grid;
    grid-column-gap: 1rem;
    align-items: center;
    color: var(--text-light-gray);
    border-left: 1px solid var(--very-light-gray);

    @include for-desktop {
      border-right: 1px solid var(--very-light-gray);
      grid-auto-columns: 10.5rem;
      grid-column-gap: 1.5rem;
    }

    > div {
      grid-row: 1;
      margin: 0;
      line-height: 1;
      align-self: baseline;

      &:last-of-type {
        @include for-desktop {
          padding-left: 0;
          grid-row: 2;
        }
      }

      @include for-desktop {
        grid-row: 2;
        align-self: normal;
        line-height: normal;
      }
    }
  }

  .shareButton {
    border: 0;
    height: 100%;
    background: var(--true-white);
    padding: 1rem;
    display: none;

    [data-icon-spacer] {
      margin: 0;
    }

    @include for-desktop {
      padding: 1rem;
      display: block;
    }
  }
  .shareModal {
    border-radius: 12px;
    background: var(--true-white);
    box-shadow: 0 2px 6px 2px rgba(189, 189, 189, 0.5);
    padding: 1rem;
    position: absolute;
    max-width: 35vw;
    top: 6rem;
    right: 1.5rem;
    color: var(--charcoal-gray);
    font-size: 0.75rem;
    opacity: 0;
    transform: translateY(-50vh);
    visibility: hidden;
    transition: 0.7s all cubic-bezier(0.01, 0.66, 0.7, 1.18);
    display: none;

    @include for-desktop {
      display: block;
    }

    &.active {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }

    & > div {
      display: grid;
      grid-template-columns: 60% 1fr;
      column-gap: 0.5rem;
    }

    .shareLink {
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
      padding: 0.87rem 0.4rem;
      border: 1px solid var(--light-grey);
      border-radius: 4px;
      color: var(--bali-hai);

      &Notification {
        color: var(--caribbean-green);
        opacity: 0;
        visibility: hidden;
        transform: translateY(1rem);
        transition: 0.3s all ease-out;

        &.active {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }

        &::before {
          content: '';
          @include checkmark;
          margin-right: 0.5rem;
          bottom: 0.1rem;
          width: 0.7rem;
        }
      }
    }

    .copyButton {
      background: var(--caribbean-green);
      color: var(--true-white);
      font-weight: 500;
      font-size: 0.87rem;
      border-radius: 24px;
      border: none;

      &:focus {
        background: var(--turquoise);
      }
    }
  }
}
