@import 'mixins';

.overlay {
  position: fixed;
  z-index: 11;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);

  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease-in;
}

.container {
  background-color: var(--true-white);
  align-self: center;
  width: auto;
  height: auto;
  border-radius: 10px;

  transform: translateY(100%);
  transition: 0.3s all ease-in;
  opacity: 0;
  position: fixed;
  max-height: 90vh;
  overflow-y: auto;
}

.isOpen {
  opacity: 1;
  visibility: visible;

  .container {
    transform: translateY(0);
    opacity: 1;
  }
}

.button.closeButton {
  position: absolute;
  z-index: 1;

  right: 0.5rem;
  top: 0.5rem;
  left: unset;
  font-size: 1.25rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  @include for-desktop {
    height: auto;
  }
}
