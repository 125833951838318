@import 'mixins';
@import 'variables';
.SalesSummary {
  .DateRange {
    margin-bottom: 1rem;
  }
  .Nav {
    --border-radius: 2rem;
    flex-flow: row;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    > button,
    > label {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
    > button {
      padding: 0;
    }

    &Title {
      flex: 100%;
      margin-top: 1.8125rem;
      margin-bottom: 1rem;
    }
  }

  &Results {
    --border-size: 1px;
    --border-radius: 0.8rem;
    --border-color: var(--spindle);
    border: var(--border-size) solid var(--border-color);
    border-radius: var(--border-radius);
    margin-bottom: var(--double-space);
    overflow-x: auto;
  }

  .total {
    font-weight: bold;
    color: var(--green);
  }
}
.insightsLink {
  width: 100%;

  .desktopInsightsBanner {
    display: block;
    width: 100%;
    @include for-mobile {
      display: none;
    }
  }
  .mobileInsightsBanner {
    display: none;
    width: 100%;
    @include for-mobile {
      display: block;
    }
  }
}
.neighbourhoodSlug {
  color: var(--primary);
}

.alertWrapper {
  margin: 1rem 0;
}
