@import '../../../assets/css/mixins';

.layout {
  position: relative;
}

.insightsMain {
  margin: auto;
  margin-bottom: 8rem;
  padding: 1rem;

  &.emailHeader {
    position: relative;
    padding-top: 0rem;
  }

  @include for-desktop {
    margin-bottom: 0;
    padding: 8rem 1rem;
    max-width: 60vw;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 850px) {
      max-width: 80vw;
    }

    @media (min-width: 1200px) {
      max-width: 48vw;
    }

    &.emailHeader {
      max-width: 80vw;
    }
  }
}

.insightsTitle {
  text-align: center;
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 0;
    font-weight: 500;
  }

  p {
    color: var(--suva-gray);
  }
}
