@import '../../../assets/css/mixins';

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6875rem 1rem;
  background-color: var(--alice-blue);
  color: var(--pelorous);
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;

  @include for-desktop {
    display: none;
  }

  img {
    width: 1.5rem;
    cursor: pointer;
  }
}
