@import 'placeholders';

.input {
  @extend %form-input;
  cursor: pointer;
  caret-color: transparent;
  margin-top: 0.5rem;

  &:disabled {
    cursor: not-allowed;

    background-color: var(--white-smoke);

    &::placeholder {
      color: var(--bali-hai);
    }
  }

  &.error {
    border-color: var(--persian-red);
  }

  &::placeholder {
    color: var(--light-black);
  }
}
