@import 'mixins';

.cardContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: '';
    opacity: var(--opacity-value);
    min-height: 5.375rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: var(--bg, var(--primary)) no-repeat center center/cover;

    @include for-desktop {
      min-height: 8.5625rem;
    }
  }

  .status {
    position: absolute;
    top: 0.875rem;
    left: 0.875rem;
    min-width: 5.7rem;
    padding: 0.3125rem 0.75rem;
    background-color: var(--status-color);
    font-size: 1rem;
    border-radius: 1rem;
    color: var(--white);
    text-align: center;
    text-transform: capitalize;
  }
}
