.brandLogo {
  background: var(--logo) no-repeat;
  background-position: var(--logo-position, center);
  background-size: contain;
  min-height: var(--brand-logo-height);
  width: 100%;
  cursor: pointer;
}

.brandLogoFixed {
  @extend .brandLogo;
  height: 4rem;
}
