@import 'mixins';

header.filterPane {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 1rem;
    transition: margin 0ms;

    @include for-mobile {
      margin-right: 0.2rem;
    }
  }
}
