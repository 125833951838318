@import 'mixins';

.button {
  cursor: pointer;
  line-height: normal;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  outline: none;
  user-select: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  text-decoration: none;
  position: relative;
  color: var(--text-link-color, var(--primary));

  &[disabled] {
    cursor: default;
    pointer-events: none;
    color: var(--gray);
    background: var(--gainboro);
  }

  @include for-mobile {
    font-size: 0.85rem;
  }
}

.iconSpacer {
  margin-right: 0.625rem;
}

.default {
  background-color: var(--white);
  color: var(--text-link-color, var(--primary));
  border: 1px solid var(--silver);

  &:hover:not([disabled]) {
    background-color: var(--light-gray);
  }

  &[disabled] {
    color: var(--disabled);
  }
}

.submit,
.primary {
  background-color: var(--apple-green);
  color: var(--white);
  border: 2px solid var(--apple-green);

  &:hover:not([disabled]) {
    background-color: var(--fern);
    border-color: var(--fern);
    color: var(--white);
  }

  &[disabled] {
    background-color: var(--disabled);
    border-color: var(--disabled);
    color: var(--dark-gray);
  }
}

.inversePrimary {
  background-color: var(--white);
  color: var(--apple-green);
  border: 2px solid var(--apple-green);
}

.noHoverPrimary {
  background-color: var(--apple-green);
  color: var(--white);
  border: 2px solid var(--apple-green);
}

.skeleton {
  color: var(--apple-green);
  border: 1px solid var(--apple-green);
}

.ghost {
  background-color: transparent;
  border: none;
}

.xs {
  padding: 0;
}

.md {
  padding: 0 1rem;
  height: 2.25rem;
  min-height: 2.25rem;
  /* safari: required should parent use height in vh, else collapses */
}

.lg {
  padding: 0 1.5rem;
  height: 3.125rem;
  min-height: 3.125rem;
  /* safari: required should parent use height in vh, else collapses */
}

.rounded {
  border-radius: 6px;
}

.text {
  border: none;
  background-color: transparent;
  color: var(--text-link-color, var(--dark-gray));
  text-decoration: none;
  align-self: baseline;
  /* min width if parent display flex */
  display: inline-flex;
  /* use min width */
  --text-button-padding: 0.1875rem;
  padding: var(--text-button-padding);

  &.textWithRightIcon {
    padding-left: calc(var(--text-button-padding) * 2);
  }

  &.textWithLeftIcon {
    padding-right: calc(var(--text-button-padding) * 2);
  }

  /* align direct children to middle - icon and text  */
  > * {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover:not([disabled]) {
    background-color: var(--light-gray);
  }
}

.space-between {
  justify-content: space-between;
}

.fullWidth {
  width: 100%;
}

.center {
  justify-content: center;
}

.bold {
  font-weight: 500;
}

.flex-end {
  justify-content: flex-end;
}
