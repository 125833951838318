@import 'mixins';

.pendingContainer {
  display: flex;
  align-items: center;
  height: 3.5rem;
  width: 100%;
  padding-left: 1rem;
  border-radius: 8px;
  background: var(--alice-blue);

  @include for-mobile {
    padding-left: 0.5rem;
  }

  :last-child {
    margin-left: auto;
    margin-right: 1.25rem;

    @include for-mobile {
      margin-right: 0.5rem;
      margin-top: 0.3rem;
    }
  }
}

.pendingStatement {
  padding-left: 0.625rem;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: var(--pelorous);

  @include for-mobile {
    padding-top: 0.3rem;
    padding-left: 0.5rem;
  }
}

.eCardsContainer {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, 18rem);
  grid-gap: 1rem;
  gap: 1rem;
  place-items: stretch;
  margin-top: 1.5rem;
  margin-bottom: 5rem;

  @include for-mobile {
    grid-template-columns: 1fr;
    justify-content: center;
    margin-bottom: 4rem;
  }
}

.buttonContainer {
  @include for-mobile {
    position: fixed;
    width: 100%;
    margin: 0 -1rem;
    padding: 0.625rem 1rem;
    bottom: 0;
  }
}

.eCardsEmptyPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-top: 5.563rem;

  img {
    width: 80%;
    max-width: 300px;
    margin-bottom: 2rem;
  }

  span {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    letter-spacing: 0.08px;
    color: var(--text-light-gray);
  }

  span:last-of-type {
    margin-bottom: 1rem;
  }

  @include for-mobile {
    margin-bottom: 3rem;

    span:last-of-type {
      margin-bottom: 0;
    }
  }
}

.createNewECards {
  width: 100%;
  height: 3.125rem;
  border-radius: 6px;
  justify-content: center;
  background: var(--forest-green);

  font-family: Rubik;
  font-weight: 500;
  text-align: center;
  font-size: 1rem;
}

.eCardsButton {
  @include for-desktop {
    position: absolute;
    right: 1.625rem;
    max-width: 19rem;
    width: 100%;
    bottom: 0;
  }
}

.eCardsEmptyPlaceholderButton {
  margin: unset;

  @include for-desktop {
    margin-top: 2.475rem;
  }
}
