@import 'mixins';

.CardLayout {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;

  .AnnouncementCard {
    margin-bottom: 1.375rem;

    @include for-desktop {
      &:nth-child(even) {
        margin-right: 1.25rem;
      }
    }
  }
}

.noAnnouncementLayout {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .noAnnouncementImage {
    background-image: var(--no-announcement-image);
    height: 15.563rem;
    width: 14.5rem;
  }
  .noAnnouncementCopy {
    color: var(--suva-gray);
    font-weight: 400;
    font-size: 0.875rem;
    text-align: center;
    width: 19.375rem;
  }
  .seeSampleLinkStyle {
    cursor: pointer;
    color: var(--link-color);
    font-size: 0.875rem;
    width: 12.375rem;
    text-align: center;
  }
}

.fullScreenSampleModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: left;
  flex-direction: column;
  background-color: white;
  width: 100vw;
  z-index: 100;
  padding: 1rem;

  .closeButtonPadding {
    padding: 0;
    margin: 0;
  }
}

.imageArea {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include for-desktop {
    width: 50vw;
  }

  .announcementSampleBubble {
    transform: scale(0);
    -webkit-animation: scaleUp 1.2s;
    -webkit-animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
    animation: scaleUp 1.2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    position: absolute;
    width: 98%;
    max-width: 31.25rem;
    bottom: 44%;
    z-index: 2;

    @include for-desktop {
      width: 400px;
    }

    @keyframes scaleUp {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }

    @-webkit-keyframes scaleUp {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  }

  .webviewAnnouncementSampleBubble {
    @extend .announcementSampleBubble;
    bottom: 40%;
  }

  .announcementSampleImg {
    position: absolute;
    height: 85%;
    z-index: 1;
  }
}

.newAnnouncementButton {
  margin: 2rem 0 0 auto;
  position: sticky;
  bottom: 1rem;
  width: 100%;
  justify-content: center;

  @include for-desktop {
    width: auto;
    padding: 0 2rem;
  }
}

.banner {
  padding: 0.75rem;
  border-radius: 6px;
  margin-bottom: var(--space);
  display: flex;
  align-items: baseline;
  background: var(--alice-blue);
  color: var(--link-color);
}
