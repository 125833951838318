@import 'mixins';

.postSubmittedModal {
  .title {
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--text-dark-gray);
    margin-bottom: 0;
    text-align: center;
  }

  .content {
    text-align: center;
    color: var(--text-dark-gray);
  }

  .okayButtonContainer {
    width: 70%;
  }
}

.slideContainer {
  position: relative;
  width: 100%;
  height: 250px;

  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .checked {
    display: block !important;
  }
}

.bold {
  font-weight: 500;
}

.moreOutletsModal {
  padding: 0;
  width: 30rem;

  .title {
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--charcoal-gray);
    margin: 0;
    width: 100%;
    padding: 1.2rem 2.5rem 0 1.2rem;
  }

  hr {
    height: 1px;
    width: 100%;
    background-color: var(--white-smoke);
    border: none;
  }

  .outletList {
    padding: 0 1.5rem;
    width: 100%;
    max-height: 15rem;
    overflow-y: auto;

    .outletName {
      margin-bottom: 1.5rem;
    }
  }
}

.selectOutletsModal {
  padding: 2rem;
  align-items: flex-start;
  width: 35rem;

  .title {
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--charcoal-gray);
    margin-bottom: 0;
    margin: 0;
  }

  .description {
    color: var(--text-light-gray);
    font-size: 0.875rem;
  }

  .form {
    width: 100%;
    position: relative;
    display: flex;
  }

  .searchOutletBar {
    width: 100%;
    border: 1px solid var(--very-light-grey);
    border-radius: 5px;
    padding: 0.5rem;

    &::placeholder {
      color: var(--very-light-grey);
    }
  }

  .closeIcon {
    border: 1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
    padding: 0;

    &:after {
      content: '\00D7';
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      background-color: var(--bali-hai);
      z-index: 1;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      padding: 1px;
      border-radius: 50%;
      text-align: center;
      color: white;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .selectAllButtonContainer {
    width: 100%;
    color: var(--pelorous);
    display: flex;
    justify-content: flex-end;

    .selectAll {
      width: fit-content;
      color: var(--pelorous);
      font-size: 0.875rem;
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  .availableOutletsList {
    height: 15rem;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    margin-bottom: 1rem;
  }

  .noOutletsFound {
    font-size: 0.875rem;
    color: var(--text-light-gray);
    margin-top: 2rem;
  }

  .doneButtonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
