.layout {
  position: relative;
}
.suggestionsLayout {
  visibility: hidden;
  display: none;
  position: absolute;
  z-index: 1;
  top: calc(100% - 0rem);
  border-radius: 6px;
  box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.03), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  height: auto;
  max-height: var(--suggestions-height);
  overflow-y: auto;
  width: 100%;

  &.noSuggestions {
    overflow-y: hidden;
  }
  &.visible {
    visibility: visible;
    display: block;
  }

  .suggestion {
    position: relative;
    padding: 1rem 1.125rem;
    background-color: var(--true-white);
    &:hover:not([disabled]):not(.noHover) {
      cursor: pointer;
      background-color: var(--light-gray);
    }
    &:before {
      position: absolute;
      z-index: 1;
      content: '';
      height: 1px;
      top: 100%;
      left: 0.75rem;
      right: 0.75rem;
      border-bottom: 1px solid var(--white-smoke);
    }

    .name {
      color: var(--charcoal-gray);
      line-height: 1.38;
      letter-spacing: 0.08px;
    }
    .description {
      font-size: 0.875rem;
      color: var(--bermuda-grey);
      line-height: 1.57;
      letter-spacing: 0.07px;
    }
  }
}
