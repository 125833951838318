.error {
  transition: all ease 0.3s;
  font-size: 0.875rem;
  color: var(--red);
  margin: 0 0.5rem;

  &.hasError {
    margin: 0.5rem;
  }
}
