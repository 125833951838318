@import 'mixins';

.outletCard {
  display: flex;
  flex-direction: column;
  position: relative;

  .cardImage {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    height: 6.9375rem;
    background: var(--bg, dodgerblue) no-repeat center center/cover;

    .gradientShadow {
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, var(--nero) 0%, transparent 50%);
    }
  }
  .cardContent {
    padding: 0.5625rem 1.1875rem;
  }

  .cashbackLabel {
    cursor: default;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.3);
    top: 1rem;
    left: 1rem;
    padding: 4px 12px;
    color: var(--true-white);
    position: absolute;
    backdrop-filter: blur(6px);
  }

  .title {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.005625rem;
    color: var(--charcoal-gray);
    margin: 0;
    @include truncate;
  }

  .neighbourhoodSlug {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.005625rem;
    color: var(--primary);
    margin: 0;
    @include truncate;
  }

  .address {
    margin-top: 0.3125rem;
    margin-bottom: 1.5625rem;
    @include truncate;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .links {
      max-width: 12rem;
      white-space: normal;
      text-align: left;
    }
  }
}
