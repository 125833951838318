@import 'mixins';

.tooltipComponent {
  background: var(--white);
  padding: 1rem;
  width: 300px;
  max-width: 90%;
  border-radius: 1rem;

  @include for-desktop {
    margin-left: auto;
  }

  .productTourContent {
    .stepNumber {
      background: var(--aqua-spring);
      border-radius: 50%;
      color: var(--forest-green);
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .stepTitle {
      margin-bottom: 0.5rem;
      font-weight: var(--bold);
    }
  }

  .tooltipFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .stepNumber {
      color: var(--text-light-gray);
    }

    .nextButton {
      margin-left: 1rem;

      .button {
        background: var(--white);
        border: 2px solid var(--forest-green);
        border-radius: 5px;
        color: var(--forest-green);
        padding: 0.5rem 1rem;
      }
    }
  }
}
