@import './mixins';
.exclusiveGuarunteeFee {
  font-size: 0.75rem;
  color: #8e8e8e;
  span {
    display: inline-flex;
    margin-left: 0.2rem;
  }

  .linkDiv {
    #linkStyle {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      padding-right: 0.2rem;
      font-size: 0.75rem;
      color: #2ba0ca;
    }
    #internalSpan {
      text-decoration: none;
      color: #8e8e8e;
      margin-left: 0.2rem;
    }
  }
}

.modalStyle {
  width: 41.68vw;
  padding: 1.875rem;
  padding-bottom: 3rem;
  @mixin for-mobile {
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
  }

  .titleStyle {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }

  .innerDiv {
    p,
    li {
      padding: 0.321rem;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.image {
  height: 0.75rem;
  width: 0.75rem;
  margin-left: 0.1rem;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.94526 2.58644V4.56002C9.94526 4.80855 10.1467 5.01002 10.3953 5.01002C10.6438 5.01002 10.8453 4.80855 10.8453 4.56002V1.75502C10.8453 1.36566 10.5296 1.05002 10.1403 1.05002H7.33527C7.08674 1.05002 6.88527 1.25149 6.88527 1.50002C6.88527 1.74855 7.08674 1.95002 7.33527 1.95002H9.3089L4.72207 6.53684C4.54634 6.71258 4.54634 6.9975 4.72207 7.17324C4.89781 7.34897 5.18273 7.34897 5.35847 7.17324L9.94526 2.58644ZM8.41515 5.31V9.66C8.41515 9.69314 8.38828 9.72 8.35515 9.72H2.23515C2.20201 9.72 2.17515 9.69314 2.17515 9.66V3.54C2.17515 3.50686 2.20201 3.48 2.23515 3.48H6.58515L7.35015 2.58H2.23515C1.70495 2.58 1.27515 3.00981 1.27515 3.54V9.66C1.27515 10.1902 1.70495 10.62 2.23515 10.62H8.35515C8.88534 10.62 9.31515 10.1902 9.31515 9.66V4.5L8.41515 5.31Z' fill='%232BA0CA'/%3E%3C/svg%3E ");
}
