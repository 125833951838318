@import '../../../assets/css/mixins';

.moreServicesHeader {
  text-align: center;

  h2 {
    margin: 0;
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
    color: var(--black);
    font-weight: 500;
  }

  p {
    color: var(--bali-hai);
    font-size: 0.875rem;
    margin: 0.2rem auto 0;
    max-width: 30ch;
  }

  &::after {
    content: '';
    width: 5rem;
    height: 0.125rem;
    background: var(--caribbean-green);
    display: block;
    margin: 1rem auto 0;
  }
}

.serviceCardContainer {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 1rem;
}

.serviceCard {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    max-height: 5.125rem;
    height: 100%;
    object-fit: cover;
  }

  .serviceCardContent {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
      color: var(--charcoal-gray);
      margin: 0 0 1rem;
      font-size: 0.9rem;
      font-weight: 500;
    }

    p {
      color: var(--light-black);
      font-size: 0.78rem;
      margin-top: 0;
    }

    a,
    button {
      @include caret-down($direction: right);
      color: var(--pelorous);
      font-weight: 500;
      text-decoration: none;
      position: relative;
      margin: auto 0.5rem 0 auto;
      padding-right: 0.5rem;
      font-size: 0.78rem;

      &::after {
        border-color: var(--pelorous);
        left: 100%;
        top: 55%;
        width: 0.4rem;
        height: 0.4rem;
        border-width: 0.08rem;
      }
    }
  }
}
