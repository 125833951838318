.touched {
  input {
    color: var(--apple-green);
  }
}

.closeButton {
  position: absolute;
  z-index: 2;
  right: 0;
  cursor: pointer;
  display: block;
  padding: 0.6875rem 1rem;
  margin: 0 0 0 auto;
  filter: brightness(0); /* make svg black regardless of color */
}
