@import 'mixins';

.modal {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--charcoal-gray);
  text-align: center;

  .title {
    font-size: 1.125rem;
    font-weight: 500;
  }

  .description {
    max-width: 40ch;

    p {
      margin: 0;
    }

    strong {
      font-weight: 500;
    }

    .link {
      margin: auto;
      margin-top: 2rem;
      color: var(--pelorous);
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  .commission {
    border: 1px solid var(--very-light-grey);
    border-radius: 10px;
    position: relative;
    font-weight: 500;
    font-size: 2rem;
    padding: 1.25rem 4rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    margin-bottom: 2rem;
    color: var(--apple-green);

    &.waived {
      color: var(--very-light-grey);

      span {
        font-size: 1.25rem;
        position: absolute;
        background: var(--aqua-spring);
        transform: translate(-31%, 50%) rotate(-15deg);
        padding: 0.8rem 2rem;
        border-radius: 10px;
        color: var(--apple-green);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
      }
    }
  }

  .button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: unset;
    margin-top: 1rem;
    font-weight: 500;
    width: 100%;
  }

  .optionsContainer {
    display: flex;
    width: 100%;
    margin-top: 1rem;

    @include for-mobile {
      flex-direction: column;
    }

    & > button:first-child {
      margin-right: 1rem;
      background: var(--true-white);
    }
  }

  .eCard {
    margin-bottom: 1.5rem;
    position: relative;

    @media (max-width: 475px) {
      min-width: unset;
      width: 100%;
      max-width: 15rem;
    }
  }

  .contact {
    margin: 1.5rem auto 0;
    padding: 1rem;
    max-width: 16rem;
    border: 1px solid var(--very-light-grey);
    border-radius: 6px;

    & > * {
      border: none;
      color: var(--link-color);

      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
}
