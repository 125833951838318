// for the fade for screenshot
.fader {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 12;
  box-shadow: 0 2px 14px 6px rgba(255, 255, 255, 1);
  background: linear-gradient(
    to bottom,
    transparent 80%,
    rgba(255, 255, 255, 0.6) 88%,
    #ffffff
  );
  height: var(--fade-offset-y);
}
