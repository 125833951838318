@import '../../../assets/css/mixins';

.nav {
  background: var(--true-white);
  margin: 1rem auto;
  padding: 0 1.875rem;
  border-radius: 5rem;
  box-shadow: 0 2px 6px 2px rgba(189, 189, 189, 0.5);
  position: fixed;
  top: calc(100% - 8rem);
  max-width: calc(95vw - 1rem);
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;

  &::after,
  &::before {
    top: 50%;
    position: absolute;
    color: var(--dark-gray);
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 0.1rem solid;
    border-right: 0.1rem solid;

    @include for-mobile {
      content: '';
    }
  }

  &::before {
    left: 1rem;
    transform: translateY(calc(-50% - 0.1rem)) rotate(135deg);
  }

  &::after {
    right: 1rem;
    transform: translateY(calc(-50% - 0.1rem)) rotate(-45deg);
  }

  @include for-desktop {
    max-width: 75vw;
    top: unset;
  }

  ul {
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(auto, 6rem);
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    .navItem {
      cursor: pointer;
      user-select: none;
      text-align: center;
      height: 100%;
      padding: 0.75rem 0.5rem 0.5rem;
      font-size: 0.75rem;
      color: var(--link-color);
      text-decoration: none;
      display: block;

      [data-icon] {
        margin-bottom: 0.4375rem;
      }

      &.isActive {
        --icon-color: var(--true-white);
        color: var(--true-white);
        background: linear-gradient(
          to bottom,
          var(--brilliant-rose),
          var(--salmon)
        );

        [data-icon] {
          filter: brightness(0) invert(1);
        }
      }
      &:hover:not([disabled]) {
        background-color: var(--white-smoke);
      }
    }
  }
}
