@import 'mixins';

.Container {
  border: 0.125rem solid var(--white-smoke);
  border-radius: 1rem;
  display: flex;
  margin: 1rem 0;

  flex-direction: column;
  height: auto;
  @include for-desktop {
    height: 11.25rem;
    flex-direction: row;
  }

  .image {
    margin: 1.25rem;
    height: 8.75rem;
    border-radius: 0.5rem;
    background-size: cover;

    margin: 1.25rem 1.25rem 0.5rem 1.25rem;
    width: auto;
    @include for-desktop {
      width: 15rem;
    }
  }
  .content {
    flex: 1 1 auto;

    margin: 0.5rem 1.25rem 1.25rem 1.25rem;
    @include for-desktop {
      margin: 2.625rem 0.5rem;
    }
    .dealID {
      font-size: 0.625rem;
      line-height: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.1em;
      color: var(--charcoal-gray);
      padding: 0.25rem 0;
    }
    .name {
      font-size: 0.875rem;
      line-height: 1.063rem;
      font-weight: 500;
      padding: 0.25rem 0;
    }
    .price {
      font-size: 0.875rem;
      line-height: 1.063rem;
      font-weight: 500;
      color: var(--forest-green);
      padding: 0.25rem 0;
      .originalPrice {
        color: var(--very-light-grey);
        text-decoration-line: line-through;
      }
    }
    .soldBox {
      background-color: var(--alice-blue);
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      line-height: 1.125rem;
      font-weight: 400;
      text-align: center;
      color: var(--pelorous);
      width: 6.813rem;
    }
  }
  .action {
    margin: 0 1.25rem 1.25rem 1.25rem;
    align-self: flex-start;
    @include for-desktop {
      margin: 1.25rem;
      align-self: center;
    }
    .deactivate {
      padding: 0.438rem 1rem;
      font-size: 1rem;
      font-weight: 500;
      color: var(--forest-green);
      border: 0.125rem var(--forest-green) solid;
      border-radius: 0.375rem;
      justify-content: center;
    }
  }
}

.inactive {
  filter: grayscale(1);
  color: var(--text-light-gray);
}
