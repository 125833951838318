.layout {
  display: flex;
  flex-flow: column;
}

.ratingsOverall {
  display: flex;
  justify-content: space-around;
  max-width: 26.3125rem;
  margin-bottom: 2rem;
  padding-top: 2rem;

  > div > h1 {
    text-align: center;
    color: var(--text-color);
  }
}

.reviewType {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .filterBadge {
    margin-right: 0.6rem;
    margin-bottom: 0.6rem;
  }
}

.reviewFilters {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;

  > :nth-child(1) {
    font-size: 0.8125rem;
    font-weight: normal;
    text-align: right;
    color: var(--dark-gray);
    margin-right: var(--half-space);
  }
}

.emptyReview {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 13.9375rem;
  padding: 3.3125rem 0;
  align-items: center;
}

.starIcon {
  height: 3.4375rem;
  width: 3.4375rem;
  margin-bottom: 1.875rem;
  background-color: var(--gray);
  mask: var(--icon-star) no-repeat center center/contain;
}

.companySelect {
  border: none;
  font-size: inherit;
  background: transparent;
  font-size: 0.8125rem;
  font-weight: normal;
  color: var(--dark-gray);
}

.pageControlButtons {
  padding: 0 1.5rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;

  p {
    flex: 1;
    text-align: center;
    line-height: 2;
  }

  .button {
    border: none;
  }
}
