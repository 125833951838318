@import './mixins';

.floatButton {
  position: fixed;
  z-index: 11;
  right: 0.7rem;
  bottom: 5rem;

  display: none;
  &.visible {
    display: block;
  }
}

.container {
  > h5 {
    margin: 0;
    color: var(--fiord);
    align-self: center;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

    > a {
      text-decoration: none;
      margin-top: 0.71875rem;

      @include for-mobile {
        margin-top: 0;
      }
    }

    @include for-mobile {
      flex-direction: row;
    }

    justify-content: space-evenly;
    padding: 0 1rem;

    .miniCardContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      cursor: pointer;
      padding: 1rem;
      border-radius: 5.2px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 1px 15px 0 rgba(0, 0, 0, 0.08);

      @include for-mobile {
        margin-top: 0;
        justify-content: center;

        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }

      &.receivePayment {
        color: var(--forest-green);
      }

      > h5 {
        margin: 0;
        color: var(--nobel);
        font-size: 0.7625rem;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: 0.06px;
      }
    }
  }
}

.pageMask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.floatingBar {
  z-index: 100;
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  width: 12.0625rem;
  padding: 1.75rem 0 1.875rem;
  border-radius: 16px;
  background: var(--true-white);
  transition: all 0.1s ease-out;

  display: none;
  &.visible {
    display: block;
  }

  @include for-mobile {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 11.0625rem;
    border-radius: 27px 27px 0 0;
  }

  > button {
    position: absolute;
    right: 1rem;
    top: -2.3rem;
  }
}
