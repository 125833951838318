@import 'mixins';

.newPostContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-bottom: 1rem;

  .postBackground {
    background-image: linear-gradient(135deg, #ff2e95 0%, #ff5858 100%);
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .postIcon {
    width: 228px; //fixed by design
    height: 173px; //fixed by design
    position: absolute;
    bottom: -1rem;
  }

  .postType {
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.1s linear;

    &.unlockedPostTypeOnHover:hover,
    &.selectedPostTypeBoxShadow {
      box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
    }

    &.unlockedPostTypeOnHover {
      cursor: pointer;
    }

    &.unselectedPostTypeFade {
      opacity: 0.5;
    }

    .postImage,
    .postLocked {
      position: relative;
      height: 185px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    .postImage {
      background-color: #e8f5e9;
    }

    .postLocked {
      background-color: rgba(74, 74, 74, 0.6);

      .postLockedImage {
        position: absolute;
        width: 100%;
        height: 100%;
        border-top-right-radius: inherit;
        border-top-left-radius: inherit;
      }

      .comingSoon {
        z-index: 1;
        color: var(--white);
        font-size: 1.25rem;
        text-align: center;

        .infoIcon {
          position: absolute;
          top: 1rem;
          right: 1rem;
        }

        p {
          margin: 1rem 0 0;
          line-height: 1.5rem;
          max-width: 220px;
        }
      }
    }

    .postContent {
      margin: 1rem;

      &.postContentLocked {
        color: #8e8e8e;
      }

      &:not(.postContentLocked) label {
        cursor: pointer;
      }

      .container {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .postTitleAndBanner {
          display: flex;
          column-gap: 1rem;
          row-gap: 1rem;

          .title {
            font-weight: 500;
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;

            .banner {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 0.5rem 0.75rem;
              border-radius: 2rem;
              font-size: 0.75rem;
              font-weight: 500;
              background-color: var(--alice-blue);
              color: var(--pelorous);
              // for safari
              margin-left: 1rem;

              &.hide {
                visibility: hidden;
              }
            }
          }
        }

        .description {
          font-weight: 400;
          font-size: 14px;
          color: #8e8e8e;
        }

        .title,
        .description {
          margin-top: 0;
        }

        /* Hide the browser's default radio button */
        input[type='radio'] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        /* Create a custom radio button */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: var(--light-gray);
          border-radius: 50%;
        }

        /* When the radio button is checked, add a blue background */
        input[type='radio']:checked ~ .checkmark {
          border: 2px solid var(--forest-green);
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .checkmark:after {
          top: 4px;
          left: 4px;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: var(--forest-green);
        }
      }
    }

    .blurFilter {
      position: absolute;
      background: rgba(74, 74, 74, 0.7);
      z-index: 1;
      height: 100%;
      width: 100%;
      backdrop-filter: blur(5px);
      background-blend-mode: multiply;
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }
  }
}

.nextStepContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .container {
    width: 100%;
    max-width: 265px;

    @include for-mobile {
      width: 100%;
      max-width: unset;
    }

    .nextStepButton {
      justify-content: center;
      margin-bottom: 0.5rem;
    }
  }
}

.inlineLink {
  color: var(--pelorous);
  text-decoration: none;
}

.fullWidth {
  width: 100%;
}

.featureLockedModal {
  .serviceContainer {
    display: grid;
    row-gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    .serviceType {
      border: 1px solid var(--very-light-grey);
      border-radius: 8px;
      padding: 1rem;
      color: var(--light-black);
      font-weight: 500;
      position: relative;
      display: flex;
      align-items: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.isSelectedServiceType {
        border: 1px solid var(--forest-green);
      }

      /* Hide the browser's default radio button */
      input[type='radio'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        right: 1rem;
        height: 25px;
        width: 25px;
        background-color: var(--light-gray);
        border-radius: 50%;
      }

      /* When the radio button is checked, add a blue background */
      input[type='radio']:checked ~ .checkmark {
        background-color: var(--forest-green);
        background-image: url('../images/FaveFeed/tick.svg');
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
      }

      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      /* Show the indicator (dot/circle) when checked */
      input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the indicator (dot/circle) */
      .checkmark:after {
        top: 4px;
        left: 4px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
      }
    }
  }
}
