@import '../mixins';

.formGrid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;

  @include for-desktop {
    grid-template-columns: 1fr 1fr;
  }
}
