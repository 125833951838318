@import 'mixins';

.modal {
  @include for-mobile {
    padding: 1.563rem;
  }
  .title {
    margin: 0.5rem;
    font-family: var(--font);
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--charcoal-gray);
    text-align: center;
  }
  .content {
    margin: 0.5rem;
    font-family: var(--font);
    font-weight: 400;
    font-size: 1rem;
    color: var(--charcoal-gray);
    max-width: 21.875rem;
    text-align: center;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
    .subSpan {
      display: block;
      padding-top: 1rem;
    }
  }
  .form {
    width: 100%;
    section,
    label {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .reason,
  .othersReason {
    max-width: 19.875rem;
    margin-bottom: 1.25rem;
  }
  .hide {
    display: none;
  }
  .buttonPane {
    display: flex;
    width: inherit;
    justify-content: space-around;
    flex-direction: row-reverse;

    @include for-mobile {
      flex-direction: column;
    }

    button {
      padding: 0.875rem 2.5rem;
      font-family: var(--font);
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.375rem;

      @include for-mobile {
        margin: 0.625rem 0;
      }
    }
    .cancel {
      margin-right: 1rem;
    }
  }
}
