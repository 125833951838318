@import 'mixins';

.layout {
  --qr-size: 20.3125rem;
  display: flex;
  flex-direction: column;
  margin-top: 3.25rem;

  @include for-desktop {
    flex-direction: row;
  }

  .qrCode {
    padding: 2.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5.8px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.19);

    width: 100%;
    height: auto;

    @include for-desktop {
      width: var(--qr-size);
      height: var(--qr-size);
    }
  }

  .malaysianQrCode {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .malaysianQrCodeFrame {
    background-image: var(--duit-now-qr-frame);
    display: flex;
    height: 354px;
    justify-content: center;
    transform: scale(0.85);
    padding-top: 39px;
    width: 328px;
    @include for-desktop {
      transform: scale(1);
    }
  }

  .qrCodeNotFound {
    margin-top: 2rem;
  }

  .qrCodeLayout {
    display: flex;
    flex-direction: column;

    @include for-desktop {
      margin-right: 2.5rem;
    }
  }

  .toggleQRFormButton {
    margin-top: 1.25rem;
    border: dashed 0.08rem;
    border-radius: 7px;
    border-color: var(--nobel);
    color: var(--nero);
    height: 4.8125rem;
    justify-content: center;
  }

  .learnMore {
    align-self: flex-start;
    margin-top: 1rem;
  }

  .contactUs {
    margin-top: 3rem;
  }

  .descriptionLayout {
    display: flex;
    flex-direction: column;
    line-height: 1.63;
    color: var(--charcoal-gray);
    margin-top: 2rem;

    @include for-desktop {
      margin-top: 0;
      max-width: 25rem;
      width: 100%;
    }
  }

  .qrForm {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;

    @include for-desktop {
      width: var(--qr-size);
    }

    .emailButton {
      margin-top: 1rem;
      justify-content: center;
    }
  }

  .faqHeading {
    font-weight: 500;
  }

  .faq {
    margin-top: 1.5rem;

    h4 {
      font-weight: 500;
      line-height: 1.5;
      margin: 1.5rem 0 0.25rem;
    }

    p {
      line-height: 1.25;
      letter-spacing: 0.07px;
      margin: 0;

      + p {
        margin-top: 1.5rem;
      }

      + button,
      + img {
        margin-top: 1rem;
      }
    }

    .panelFeature {
      display: flex;
      align-items: center;

      > img {
        max-width: var(--img-size);
        width: 100%;
        margin-right: 1rem;
      }

      > span {
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 0.07px;
      }
    }

    .paddedPanel {
      --img-size: 5rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .dividerOR {
      max-width: var(--img-size);
      width: 100%;
      line-height: 1.43;
      letter-spacing: 0.25px;
      text-align: center;
      font-size: 0.875rem;
      font-weight: 500;
      margin: 0.4375rem 0;
    }

    .divider {
      height: 1.5rem;
    }

    .paymentSection {
      display: flex;
      flex-direction: column;
      width: 100%;

      .weekSection {
        width: 100%;
        margin-bottom: 1rem;
        text-align: center;
        border-radius: 4px;
        border: solid 1px var(--very-light-grey);
        align-self: center;
        background-color: var(--white-smoke);

        &.weekOne {
          color: var(--forest-green);
          background-color: var(--aqua-spring);
          border-color: var(--sea-mist);
        }

        .weekDays {
          padding: 0.2rem;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;

          > span {
            height: 2.5rem;
            margin: 0.11rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
              padding: 0.2rem;
              height: 2.3rem;
              min-width: 2.3rem;
              background-color: var(--forest-green);
              color: var(--true-white);
              border-radius: 50%;
            }
            @include for-mobile {
              font-size: small;
            }
            @media (max-width: 20.625rem) {
              font-size: x-small;
            }
            @media (max-width: 17.5rem) {
              font-size: xx-small;
            }
          }
        }
      }

      .weekHeader {
        font-weight: 500;
        border-bottom: 1px solid var(--very-light-grey);
        padding: 0.5rem 1rem;
      }

      .weekOne .weekHeader {
        border-color: var(--sea-mist);
      }
    }
  }
}

.subheading {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--charcoal-gray);
  margin-top: 0;
  margin-bottom: 0.25rem;

  &.viewSubHeading {
    margin-top: 2rem;
  }
}

.subtitle {
  font-size: 0.875rem;
  color: var(--text-light-gray);
  margin: 0;
}

.paymentLinks {
  margin: 1.5rem 0 4rem 0;
  display: flex;
  flex-direction: column;

  @include for-desktop {
    flex-direction: row;
  }

  .linkButton {
    margin: auto;
    width: 100%;

    @include for-desktop {
      max-width: 20.625rem;
      margin: 0;
    }
  }

  .customLink {
    margin-right: 1.1rem;

    @include for-mobile {
      margin-bottom: 1rem;
    }
  }
}

.paymentModal {
  width: 37.5rem;
  max-width: 100%;
  padding: 2rem 1.25rem;

  .modalBackButton {
    margin: 1.25rem 0;
  }

  @include for-desktop {
    padding: 2.75rem 5.6875rem 2.5rem;
  }

  &Content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3,
    p {
      text-align: center;
    }

    p {
      margin: 0;
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      letter-spacing: 0.18px;
      color: var(--charcoal-gray);
    }
  }

  &Buttons {
    display: grid;
    row-gap: 1rem;
    flex-direction: row;
    margin: auto;
    width: 100%;
    margin-top: 1.5rem;

    @include for-desktop {
      display: flex;
      justify-content: space-between;
    }
  }

  &Button {
    width: 100%;
    transition: none;

    &:first-of-type {
      margin-right: 1rem;
      grid-row: 2;
    }
  }

  .copyConfirmation {
    margin: auto;

    &Text {
      margin: 0.5625rem 0 0;
      font-weight: 500;
      color: var(--forest-green);

      &::before {
        content: '';
        margin-right: 0.5rem;
        @include checkmark;
      }
    }
  }

  .paymentLink {
    &Box {
      width: 100%;
      border-radius: 5px;
      border: 1px solid var(--very-light-gray);
      padding: 1rem 1rem;

      @include for-desktop {
        padding: 1.125rem 2.0625rem;
      }

      margin: auto;

      p {
        margin: 0;
      }
    }

    &Text {
      color: var(--charcoal-gray);
      text-align: center;
      overflow: auto;
      max-height: 35vh;
    }
  }

  .amountInput,
  .remarkInput {
    position: relative;
    margin: auto;
    max-width: 26rem;
    width: 100%;
  }

  .amountInput {
    margin-bottom: 1.5rem;
    border-radius: 5px;
    border: 1px solid var(--very-light-grey);
    padding: 1rem;
    padding-left: 2.7rem;
    width: 100%;

    &:focus-within {
      border: 2px solid var(--apple-green);
    }

    span {
      pointer-events: none;
      position: absolute;
      left: 2.8rem;
      top: 1.05rem;
    }

    &::before {
      content: attr(data-currency);
      letter-spacing: 0.18px;
      position: absolute;
      padding-right: 1rem;
      left: 1rem;
      top: 51%;
      transform: translateY(-50%);
    }
  }

  .amountInputElement {
    color: transparent;
    cursor: none;
    border: none;
    width: 0;
    height: 0;

    &:focus {
      outline: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .remarkInput {
    textarea {
      border-radius: 5px;
      border: 1px solid var(--very-light-grey);
      padding: 1rem;
      width: 100%;
      min-height: 100px;
      resize: none;
      position: relative;

      &:focus {
        outline-color: var(--apple-green);
      }
    }

    span {
      color: var(--bali-hai);
      font-size: 0.75rem;
      position: absolute;
      right: 1rem;
      bottom: 1rem;
    }
  }
}
