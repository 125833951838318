@import 'mixins';

.educationModal {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 2.125rem;
  justify-content: space-between;
  overflow-y: auto;

  .modalImage {
    max-height: 330px;
    max-width: 100%;
  }

  @include for-desktop {
    max-width: 30rem;
    padding: 2.125rem;
  }

  @include for-mobile {
    max-width: 70vw;
    .modalImage {
      max-height: 220px;
    }
  }

  .modalSwiper {
    width: 100%;

    .slide {
      display: flex;
      flex-flow: column;
    }

    .indicatorContainer {
      position: initial;
    }

    .navigatorContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;

      .continueButton {
        width: 70%;
      }
    }
  }

  .slideContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .hideModalContent {
    display: none;
  }

  .content {
    text-align: center;

    .title,
    .description {
      color: var(--charcoal-gray);
    }

    .title {
      font-size: 1.125rem;
      font-weight: 500;

      @include for-mobile {
        font-size: var(--normal-font);
      }
    }

    .description {
      @include for-mobile {
        font-size: 0.9rem;
      }
    }
  }

  .progressPoint {
    width: 10px;
    height: 10px;
    background: var(--white-smoke);
    border-radius: 50%;
  }

  .isActiveProgressPoint {
    background: var(--grayish-blue);
  }
}
