@import 'mixins';

.dealsViewContainer {
  display: flex;
  flex-direction: column;

  .dealsHeader {
    color: var(--text-dark-gray);
    margin-bottom: 2rem;

    > h5 {
      font-weight: 500;
      margin: 0;
      color: var(--text-light-gray);
    }

    > h4 {
      font-weight: 500;
      font-size: 1.125rem;
      margin: 0 0 0.25rem;
    }
  }

  .dealsForms {
    .form {
      display: flex;
      flex-direction: column;

      @include for-desktop {
        flex-direction: row;
        justify-content: space-between;
      }

      .businessCity {
        @include for-desktop {
          width: 40%;
        }

        > h4 {
          font-weight: 500;
          color: var(--text-dark-gray);
          margin: 0.5rem 0;
        }
      }

      .businessDetails {
        > h4 {
          font-weight: 500;
          color: var(--text-dark-gray);
          margin: 0.5rem 0;
        }

        .detailEntry {
          > textarea {
            height: 8.3125rem;
            font-size: 0.888rem;
            padding: 1rem;
            border-radius: 5px;
            border-color: var(--very-light-grey);
          }
        }

        .businessInfo {
          font-size: 0.75rem;
          margin-top: 1rem;
          margin-bottom: 2.5rem;
        }

        @include for-desktop {
          width: 50%;
        }

        > button {
          width: 100%;
        }
      }
    }
  }
}
