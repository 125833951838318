@import 'mixins';

.BulkActionButton {
  margin-left: 1rem;
  margin-right: 1rem;
  position: relative;
}

.BulkActionDropDown {
  overflow: hidden;
  right: 0;
  z-index: 3;
  padding: 0.5rem;
  height: fit-content;
  display: none;
  position: absolute;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0px 0.5rem 1rem 0px rgba(0, 0, 0, 0.2);

  &.isOpen {
    display: flex;
    flex-direction: column;
  }

  .DropdownItemButton {
    display: flex;
    font-size: var(--small-font);
    width: 13rem;
    padding: 1.3rem;
    border-radius: 0.5rem;

    .dropdownItemIcon {
      padding-right: 1rem;
    }
  }

  .DropdownItemButtonAnimation {
    animation: slide-from-left 0.6s;
    -webkit-animation: slide-from-left 0.6s;
  }

  .EmailForm {
    animation: slide-from-right 0.6s;
    -webkit-animation: slide-from-right 0.6s;
    width: 23rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include for-mobile {
      width: 13rem;
    }

    h5 {
      color: var(--green);
    }

    .ErrorMessage {
      color: red;
      margin: 0.1rem;
    }

    .EmailHeader {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      h3 {
        margin: 0;
      }
    }

    p {
      font-size: 0.85rem;
      margin-bottom: 0.15rem;
      color: var(--gray);
    }

    .CCEmailForm {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .SendButtonContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem 0;

      .LoadingSpinnerContainer {
        margin-right: 1.25rem;
      }

      .SendButton {
        padding: 0.4rem;
      }
    }
  }
}

.EmailSent {
  animation: appear 0.3s ease-in;
  -webkit-animation: appear 0.3s ease-in;
  width: 23rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  @include for-mobile {
    width: 13rem;
  }

  .CheckIcon {
    width: 2.625rem;
    max-width: 2.625rem;
    height: 2.625rem;
    margin-top: 1rem;
    background-image: var(--icon-circle-check);
  }

  h3 {
    color: var(--green);
    max-width: 200rem;
  }

  p {
    color: var(--bali-hai);
  }

  .SubmitButton {
    margin-top: 1.3rem;
  }
}
