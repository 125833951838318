.banner {
  background: var(--bg) no-repeat center center/contain;
  position: relative;
  margin: 1.1875rem 0;
  height: 11rem;

  &Link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: transparent;
  }
}
