@import 'mixins';

.webviewSubheading {
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-dark-gray);
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 18rem);
  gap: 1rem;
  place-items: stretch;
  margin-top: 2rem;

  @include for-mobile {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
