$small-margin: 0.5rem;
$large-margin: 1rem;

.userTipBlock {
  .textTip:first-child {
    margin-top: $large-margin;
    margin-bottom: $small-margin;
  }

  .textTip:nth-child(n + 3) {
    margin-top: $small-margin;
  }

  .textTip:last-child {
    margin-top: $small-margin;
    margin-bottom: $large-margin;
  }

  .textTip:only-child {
    margin: $large-margin 0;
  }
}

.textTip {
  display: flex;
  align-items: center;

  .icon {
    margin-right: $small-margin;
  }
}
