@import '../../../assets/css/mixins';

.titleCard {
  padding: 1.5rem;
  border-radius: 32px;
  color: var(--true-white);
  background-image: var(--titleCardBg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 2rem 0;
  scroll-margin-top: var(--mobile-scroll-margin-top);

  @media only screen and (min-resolution: 192dpi) and (min-width: 900px) {
    background-image: var(--titleCardBgBig);
  }

  @include for-desktop {
    scroll-margin-top: 12.5rem;
    padding: 3rem;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .subtitle {
    font-size: 0.8rem;
    font-weight: 500;
  }
}
