@import 'mixins';

.backTransaction {
  color: var(--fiord-gray);
  margin-bottom: 1.625rem;
}

.formSize {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  @include for-desktop {
    align-items: flex-start;
  }
}

.alipayCard {
  max-width: 21.25rem;
  width: 100%;
  height: auto;
  padding: 2.125rem 0.75rem 1.75rem 0.75rem;
  margin-bottom: 1.813rem;
}

.confirmButton {
  max-width: 21.25rem;
  width: 100%;
  height: 3.125rem;
  display: flex;
  justify-content: center;
}

.description {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.18px;
  text-align: center;
  color: var(--bermuda-grey);
}

.textInputContainer {
  position: relative;
}

%text {
  font-size: 1.188rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--light-black);
}

.textPlaceholder {
  position: absolute;
  margin: 0.9rem;
  @extend %text;
}

.textInput > input {
  padding-left: 3rem;
  border-radius: 4px;
  border: 1px solid var(--green);
  height: 3.125rem;
  width: 100%;
  @extend %text;
}

.logoContainer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.alipayLogo {
  height: 3.5rem;
  width: 3.5rem;
}

.alipayLogoTitle {
  margin-top: 0.688rem;
  margin-bottom: 1rem;
  font-size: 1.063rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--light-black);
}

.logoText {
  font-size: 0.938rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--bermuda-grey);
}

.alipayQRContainer {
  max-width: 25rem;

  .qrCodeCard {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.063rem;
  }

  .logo {
    margin: 1.5rem 0;
  }

  .amountPayable {
    margin-bottom: 0.5rem;

    font-size: 1rem;
    color: var(--fiord-gray);
  }

  .price {
    margin-bottom: 1rem;

    font-size: 2rem;
    color: var(--apple-green);
  }

  .qrCode {
    margin-bottom: 1rem;
    width: 10rem;
    height: 10rem;
    background: var(--bg) no-repeat center center/cover;
  }

  .bottomText {
    margin-bottom: 2.813rem;
  }

  .buttonVerify {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3.125rem;
  }
}
