@import 'mixins';

@mixin gridify($name) {
  grid-area: #{$name}Title;
  & + dd {
    grid-area: #{$name};
  }
}

.smallTitle {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--bali-hai);
  letter-spacing: 0.06px;
}

.dealContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:before {
    content: '';
    background: var(--bg, dodgerblue) no-repeat center center/cover;
    height: 15rem;
  }

  dl {
    margin: 0;
  }

  dt {
    @extend .smallTitle;
  }

  dd {
    margin: 0;
    margin-top: 0.25rem;
    color: var(--charcoal-gray);
    line-height: 1.5;
    letter-spacing: 0.08px;
    font-size: 1rem;
    margin-bottom: 2rem;

    p {
      margin-top: 0;
    }
  }

  a {
    color: var(--link-color);
    text-decoration: none;
  }
}

.dealLink {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.08px;
  padding: 0;
  justify-self: end;
}

.dealHeader {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-row-gap: 1rem;
  padding: 1rem 0;

  .dealTitle {
    margin: 0;
    grid-column: span 2;
    font-size: 1.25rem;
    color: var(--charcoal-gray);
    line-height: 1.35;
    letter-spacing: 0.09px;
  }
}

.dealDetail {
  @include for-desktop {
    display: grid;
    grid-template-areas: 'dateTitle priceTitle outletsTitle' 'date price outlets' 'photosTitle photosTitle photosTitle' 'photos photos photos';
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2.875rem;
  }

  .date {
    @include gridify(date);
  }

  .price {
    @include gridify(price);

    & + dd span {
      color: var(--very-light-grey);
      text-decoration: line-through;
    }
  }

  .outlets {
    @include gridify(outlets);

    & + dd a {
      padding: 0;
      display: block;
      font-weight: 500;
    }
  }

  .photos {
    @include gridify(photos);
  }
}

.dealTNC {
  border-top: 1px solid var(--very-light-grey);
  padding-top: 2rem;

  @include for-desktop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'customerTitle redemptionTitle' 'customer redemption' 'finePrintTitle redemption' 'finePrint redemption';
    grid-auto-rows: min-content;
    grid-column-gap: 2.875rem;
  }

  ul {
    padding-left: 1.5rem;
    margin: 0;
  }

  dd:last-of-type {
    margin-bottom: 0;
  }

  .customer {
    @include gridify(customer);
  }

  .redemption {
    @include gridify(redemption);
  }

  .finePrint {
    @include gridify(finePrint);
  }
}

.dealGallery {
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @include for-desktop {
    grid-template-columns: repeat(auto-fit, 10rem);
  }

  li {
    margin: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
