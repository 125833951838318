@import 'mixins';

.title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--charcoal-gray);
}

.content {
  margin-bottom: 1.5rem;
  text-align: center;
}

.buttonPane {
  display: flex;
  justify-content: space-around;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  @include for-mobile {
    flex-flow: column-reverse;

    & > *:not(:first-child) {
      margin-bottom: 1rem;
    }

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
}
