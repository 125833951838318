.cancellationCheckBox {
  display: flex;
  align-items: center;

  .toggleLabel {
    color: var(--gray);
    font-size: 1rem;
    margin-left: 0.5rem;
  }

  > input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  > label {
    cursor: pointer;
    display: block;
    background: var(--gray);
    height: 1.125rem;
    width: 3.4125rem;
    border-radius: 6.25rem;
    display: flex;
    align-items: center;
    position: relative;

    .toggleButtonText {
      transition: all ease 0.3s;
      color: var(--white);
      margin-left: 0.25rem;
      transform: translateX(93%);
      font-size: 0.75rem;
      font-weight: 500;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: -0.08rem;
      width: 1.375rem;
      height: 1.375rem;
      background: var(--white);
      border-radius: 5.625rem;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
      transition: 0.3s;
    }
  }

  input:checked + label {
    background: var(--forest-green);

    .toggleButtonText {
      margin-left: 0.5rem;
      transform: translateX(0%);
    }
  }

  input:checked + label:after {
    left: 100%;
    transform: translateX(-96%);
    border-radius: 5.625rem;
  }

  input:checked[disabled] + label {
    cursor: not-allowed;
  }
}
