.ContractViewContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .buttonContainer {
    width: 100%;
  }

  .mailButton,
  .contractButton,
  .phoneButton {
    height: 3.125rem;
    font-weight: 500;
  }

  .contractButton {
    background: var(--white);
  }

  .mailButton {
    margin: 0rem 0 1rem 0;
  }

  .contractContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    > p {
      color: var(--charcoal-gray);
    }
  }
}

.modal {
  width: 40.0625rem;
  height: 27.1875rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modalContent {
    width: 28.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;

    > h5 {
      font-size: 1.25rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--charcoal-gray);
    }

    > p {
      text-align: center;
      font-size: 1rem;
      font-weight: normal;
      width: 70.2%;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--text-light-gray);
    }
  }

  .ButtonSection {
    display: flex;
    width: 63%;
    justify-content: space-between;
    font-weight: 500;

    > button {
      font-weight: 500;
    }
  }
}
