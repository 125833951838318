@import 'mixins';

.content {
  white-space: pre-wrap;
}

.showMoreContainer {
  & > * {
    word-break: break-word;
    overflow-wrap: break-word;
    @include clamp-on-line(2);
  }
}
.extendedContainer {
  & > * {
    word-break: break-word;
  }
}
