.signUpContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.loginHeader {
  align-self: center;
  color: var(--charcoal-gray);
}

.flagContainer {
  display: flex;
  justify-content: space-evenly;
}

.modal {
  width: 40.0625rem;
  height: 27.1875rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modalContent {
    width: 28.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;

    > h5 {
      font-size: 1.25rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--charcoal-gray);
    }

    > p {
      text-align: center;
      font-size: 1rem;
      width: 70.2%;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--text-light-gray);
    }
  }

  .ButtonSection {
    display: flex;
    width: 63%;
    justify-content: space-between;

    > a {
      text-decoration: none;
      font-weight: 500;
    }

    > button {
      font-weight: 500;
    }
  }
}
