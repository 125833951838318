@import 'mixins';

.title {
  font-size: 1.5rem;
  font-weight: 500;
}

.description {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
}
