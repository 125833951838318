@import './mixins';

.redemptionContainer {
  display: flex;
  margin-top: 1.45rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.125rem;

  @include for-desktop {
    align-items: flex-start;
  }

  .redemptionCard {
    @include for-mobile {
      width: 100%;
    }

    display: flex;
    flex-direction: column;
    padding: 1.75rem;
    max-width: 20rem;

    .redemptionCardContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      text-align: center;

      .redemptionIconSection {
        align-self: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        > img {
          height: 2.6875rem;
          width: 3.5rem;
        }

        > h4 {
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: -0.18px;
          margin: 0 0 1rem;
          padding: 0;
        }
      }

      .redemptionButtomSection {
        align-self: center;
        margin-bottom: 1rem;

        > h5 {
          font-size: 1rem;
          font-weight: normal;
          letter-spacing: -0.18px;
          margin: 0;
          padding: 0;
          align-self: center;
          color: var(--bermuda-grey);
        }
      }

      .webviewText {
        @include for-desktop {
          display: none;
        }

        span {
          display: block;
          color: var(--apple-green);
          margin-top: 1rem;
        }
      }
    }

    form {
      position: relative;

      .redemptionInput > input {
        border-radius: 4px;
        border: 1px solid var(--nobel);
        padding-right: 2.5rem;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: var(--very-light-grey);
        }
      }

      button {
        background: var(--very-light-grey);
        color: var(--white);
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1rem;

        &:hover {
          background: var(--very-light-grey);
        }
      }
    }
  }

  .redemptionButton {
    @include for-mobile {
      position: fixed;
      bottom: 0;
      width: 90%;
      margin: 1rem 0;
      max-width: 20rem;
    }

    margin-top: 1rem;
    width: 20rem;
    height: 3.125rem;
    justify-content: center;
  }

  .scanButton {
    @include for-desktop {
      display: none;
    }
  }
}

// Styling for voucher redemption details

.voucherDetailContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1.45rem;
  max-width: 57.5rem;

  .voucherDetailCard {
    width: 100%;

    @include for-mobile {
      margin-bottom: 2.5rem;
    }
  }

  .detailContentTitle {
    color: var(--bermuda-grey);
    font-size: 0.875rem;
    font-weight: 400;
  }

  .detailContentContent {
    color: var(--charcoal-gray);
    font-size: 1rem;
    font-weight: 500;
  }

  .detailContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .detailHeader {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include for-desktop {
        flex-direction: row;
        align-items: unset;
      }

      .voucherImage {
        > img {
          width: 154px;
          height: 154px;
          margin: 1rem 0;

          @include for-desktop {
            margin: 2.1875rem 1.6875rem 0 3.75rem;
          }
        }
      }

      .voucherHeader {
        display: flex;
        flex-direction: column;

        @include for-desktop {
          justify-content: center;
        }

        .title {
          color: var(--charcoal-gray);
          font-size: 1.125rem;
          font-weight: 500;
        }

        .priceSection {
          display: flex;
          justify-content: center;

          @include for-desktop {
            justify-content: unset;
          }

          margin: 0.3125rem 0;

          .realPrice {
            color: var(--grayish-blue);
            text-decoration: line-through;
            margin-right: 0.5rem;
          }
        }
      }
    }

    .detailDescription {
      display: flex;
      margin: 0 1.25rem;
      flex-wrap: wrap;

      @include for-desktop {
        justify-content: space-around;
      }
    }

    .detailDescriptionLeft {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include for-desktop {
        width: 21.5rem;
      }
    }

    .detailDescriptionRight {
      display: flex;
      width: 100%;
      flex-direction: column;

      @include for-desktop {
        width: 21.5rem;
        margin-left: 1rem;
      }
    }

    .detailContent {
      display: flex;
      flex-direction: column;
      margin-top: 0.90625rem;
      height: 3.40625rem;
      border-bottom: solid 0.5px #dddddd;
    }
  }

  .successContainer {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 3.125rem;
    border-radius: 6px;
    background-color: var(--aqua-spring);

    .successText {
      margin-left: 0.5rem;
    }
  }

  .redemptionButton {
    @include for-mobile {
      position: fixed;
      bottom: 0;
      width: 96%;
      margin: 0;
      left: 0.5rem;
      margin: 1rem 0;
    }

    align-self: flex-end;
    margin-top: 1rem;
    box-shadow: 0px 0px 49px 14px rgba(255, 255, 255, 1);
    width: 21.0625rem;
    height: 3.125rem;
    justify-content: center;
  }
}
