@import 'mixins';

.layout {
  display: grid;
  gap: 1.5rem 1rem;
  margin-top: 2rem;

  @include for-desktop {
    grid-template-columns: minmax(17.5rem, 1fr) 1fr;
  }
}

.eCardContainer {
  @include for-desktop {
    margin-top: 6.5rem;
  }
}

.previewText {
  color: var(--bali-hai);
  font-size: 0.75rem;
  text-align: center;
  display: block;
  margin: 1rem auto 0;
  max-width: 60%;
  line-height: 0.875rem;
}

.info {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: middle;
}

.setupContainer {
  border: 1px solid var(--very-light-grey);
  padding: 1.5rem;
  border-radius: 0.8rem;

  .heading {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--charcoal-gray);
    margin: 0;
  }

  .subheading {
    color: var(--text-light-gray);
    font-size: 0.875rem;
    margin: 0;
    margin-top: 0.25rem;
    line-height: 1.0625rem;
  }

  .profit {
    color: var(--forest-green);
    font-size: 0.875rem;
    font-weight: 500;

    small {
      color: var(--charcoal-gray);
      font-weight: 300;
    }
  }
}

.slidersContainer {
  background: var(--true-white);
  border-radius: 0.75rem;
  border: 1px solid var(--white-smoke);
  margin: 1rem 0 2rem;
  box-shadow: 0px 6px 20px rgba(31, 32, 65, 0.05);

  & + .heading {
    color: var(--light-black);
  }
}

.result,
.slider {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  padding: 1.125rem 1.25rem;

  .name,
  .value {
    color: var(--light-black);
    font-weight: 500;
    margin: 0;
    font-size: 1rem;
  }

  .value {
    grid-column: 2;
    justify-self: end;
  }
}

.slider {
  --slider-offset: 1.5rem;
  border-bottom: 1px solid var(--white-smoke);

  [data-reach-slider-input][data-orientation='horizontal'] {
    width: unset;
    outline: none;
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;
    grid-column: span 2;
    cursor: pointer;
    padding: 1.5rem 0 0 1rem;
  }

  [data-reach-slider-handle] {
    left: 5px;
    border: none;
    background: var(--forest-green);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    outline: none;
  }

  [data-reach-slider-track] {
    border-radius: 1.25rem;
    background: var(--aqua-spring);
    min-height: 8px;

    // slider offset at beginning
    &::before {
      background: var(--de-york);
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: var(--slider-offset);
      left: calc(-1 * var(--slider-offset));
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    // to achieve rounded edges on slider track
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0.7rem;
      height: 100%;
      right: 0;
      background: var(--aqua-spring);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  [data-reach-slider-track-highlight] {
    background: var(--de-york);
  }

  [data-reach-slider-marker] {
    background: var(--true-white);
    width: 3px;
    border: none;
  }

  [data-reach-slider-marker][data-recommended='true'] {
    &::after {
      content: attr(title);
      left: 0;
      position: absolute;
      transform: translateX(-50%);
      top: 1.6rem;
      font-size: 0.625rem;
      color: var(--pelorous);
    }
    &::before {
      content: '';
      left: 0;
      position: absolute;
      transform: translateX(-33%);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid var(--pelorous);
      top: 1.2rem;
      width: 1px;
      height: 1px;
    }
  }

  [data-recommended]:last-child {
    &::after {
      transform: translateX(-90%);
    }
  }
}

.submitButton {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: unset;
  margin-top: 1.5rem;
  font-weight: 500;
  min-width: 15rem;
}

.costContainer {
  justify-self: center;
  @include for-desktop {
    justify-self: initial;
  }

  .costStructure {
    width: 100%;
    margin-top: 0;
  }

  .submitButton {
    max-width: 25rem;
  }
}

.tnc {
  display: block;
  color: var(--light-black);
  font-size: 0.75rem;
  text-align: center;
  margin-top: 1rem;

  a {
    color: var(--pelorous);
    text-decoration: none;
  }
}
