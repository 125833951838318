@import 'mixins';

.container {
  display: flex;

  @include for-mobile {
    flex-direction: column;
  }
}

.webviewCard {
  flex: 1;
  margin: 0 auto;
}

.card {
  flex: 1;
  margin: 3.813rem auto;

  @include for-mobile {
    margin-top: 2rem;
    margin-bottom: 0;
    width: 100%;
  }
}

.createdDate {
  display: block;
  margin: 1rem auto 0;
  font-family: Rubik;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  color: var(--text-light-gray);
}
