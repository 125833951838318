@import 'mixins';

.modalLayout {
  padding: 1.5625rem;
  text-align: center;
  display: flex;
  flex-direction: column;

  @include for-desktop {
    min-width: 40vw;
    padding: 1.5625rem 5.31rem;
  }

  h3 {
    margin: 0.625rem;
  }

  p {
    max-width: 100%;

    @include for-desktop {
      margin: 0.625rem;
      margin-left: auto;
      margin-right: auto;
      padding: 0 2rem;
    }
  }

  .bottomButtonBar {
    display: flex;
    flex-direction: column-reverse;

    .individualButtonSpace {
      width: 100%;
      padding: 0.325rem;

      @include for-desktop {
        width: 100%;
        flex-direction: row;
        padding: 0.625rem;
      }
    }

    @include for-desktop {
      flex-direction: row;
    }
  }
}
