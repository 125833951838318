.ProgressBarContainer {
  display: flex;
  height: 0.3125rem;
  background: var(--light-grey);
  width: 100%;
}

.scrollBar {
  width: var(--width);
  background: linear-gradient(
    to right,
    var(--brilliant-rose),
    var(--salmon-red)
  );
  height: 0.3125rem;
}
