.cancellationCodeModal {
  text-align: center;
  padding: 1.8125rem;

  h3 {
    font-weight: 500;
    font-size: 1rem;
    color: var(--fiord);
    margin: 0;
  }

  p {
    font-size: 0.875rem;
    color: var(--fiord);
  }

  input {
    margin-bottom: 0.625rem;
  }

  .textInputError {
    > input {
      border-color: var(--red);
    }

    > div {
      text-align: left;
    }
  }

  .button.primary {
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 1.125rem;
  }

  .button.cancel {
    font-size: 1rem;
  }
}
