@import 'mixins';

.outletInfoEnvelope {
  display: flex;
  flex-flow: column;

  &:before {
    content: '';
    height: 12.875rem;
    margin-top: 1rem;
    border-radius: 0.3rem 0.3rem 0 0;
    background: var(--bg, dodgerblue) no-repeat center center/cover;
  }
}

.card {
  padding: 1rem 1.4375rem;
}

.outletInfoContent {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  @include for-mobile {
    flex-direction: column;
  }

  .labelTitle {
    color: var(--gray);
    font-weight: 400;
    font-size: 1.125rem;
    @include truncate;
  }

  .labelBoxTitle {
    color: var(--dark-gray);
    font-weight: 500;
    font-size: 1.0625rem;
  }

  .labelBoxDescription {
    font-size: 0.9em;
    white-space: normal;
  }

  .outletInfoLeft {
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .outletNeighbourhoodSlug {
    color: var(--primary)
  }

  .outletInfoRight {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    width: 100%;

    @include for-desktop {
      width: 50%;
    }

    .outletAuthorization {
      display: flex;
      flex-direction: column;
      min-height: 11.75rem;
      max-width: 21.6875rem;
      justify-content: space-around;
      width: 100%;

      @include truncate;

      > h5 {
        margin: 0;
      }

      .partnerCodeButtons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .authCodeAndFavepayInactiveBanner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 0.5rem;

        .authorizationCode {
          font-size: 2rem;
          font-weight: 700;
          margin-right: 1rem;
        }

        .favePayInactive {
          font-size: var(--small-font);
          color: var(--pelorous);
          background-color: var(--alice-blue);
          border-radius: 5px;
          padding: 0.4rem;
          margin: 0.5rem 0;
        }
      }

      .renewCodeButton {
        align-self: flex-start;
        margin: 0.5rem 0;
      }
    }

    .outletCancellation {
      display: flex;
      flex-direction: column;
      min-height: 11.75rem;
      max-width: 21.6875rem;
      justify-content: space-around;
      margin-top: 1rem;
      width: 100%;
      @include truncate;

      .cancelText {
        font-size: 1rem;
        font-weight: 400;
        white-space: normal;
      }

      .outletCancellationTop {
        align-items: center;
        margin-bottom: 1.6875rem;

        > h5 {
          margin: 0;
        }

        .cancellationTextAndButton {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .cancellationCheckBox {
          display: flex;
          align-items: center;
          width: 8rem;

          .toggleLabel {
            color: var(--gray);
            font-size: 1rem;
            margin-left: 0.5rem;
          }

          > input[type='checkbox'] {
            height: 0;
            width: 0;
            visibility: hidden;
          }

          --switch-padding: 0.18rem;

          > label {
            cursor: pointer;
            display: block;
            background: var(--gray);
            height: 1.5rem;
            width: 2.5rem;
            border-radius: 6.25rem;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: var(--switch-padding);
              left: var(--switch-padding);
              width: 1.125rem;
              height: 1.125rem;
              background: var(--white);
              border-radius: 5.625rem;
              transition: 0.3s;
            }
          }

          input:checked + label {
            background: var(--apple-green);
          }

          input:checked + label:after {
            left: calc(100% - var(--switch-padding));
            transform: translateX(-100%);
          }

          input:checked[disabled] + label {
            cursor: not-allowed;
          }
        }
      }
    }

    .cancellationCode {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray);
      @include truncate;
    }

    .resetCancellationCodeButton {
      align-self: flex-start;
      margin-top: 1rem;
    }

    .hidden {
      visibility: hidden;
    }

    .cancelEditActive {
      color: var(--black);
    }

    .cancellationForm {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .cancellationFormButtons {
        display: flex;
        margin-top: 1rem;
        flex-wrap: wrap-reverse;

        .formCancelButton {
          @include for-desktop {
            max-width: 8.8125rem;
            margin: 0.5rem 0.1rem 0 0;
          }

          color: var(--black);
          align-self: flex-start;
          width: 100%;
          border-radius: 0.3rem;
          margin-top: 0.5rem;
        }

        .formSubmitButton {
          @include for-desktop {
            max-width: 8.8125rem;
          }

          align-self: flex-start;
          width: 100%;
        }
      }

      .inputField {
        color: var(--gray);
        font-size: 1.125rem;
        width: 100%;
        height: 2.5625rem;
        border-radius: 0.375rem;
        padding: 0.5rem 1rem;
        margin-top: 0.5rem;
      }
    }
  }
}

.error {
  font-size: 0.7rem;
  color: var(--red);
}
