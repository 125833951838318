.optionsCard {
  background-color: var(--snow-white);
}

.optionsLabel {
  margin-bottom: 0.8rem;

  &.last {
    margin-top: 1.2rem;
  }
}

.dropdownContent {
  margin-top: 0.5rem;

  .monthOption {
    padding: 0.6rem !important; // cannot increase specificity of dropdown
  }

  .selected {
    background-color: var(--alto);
  }
}

.bankAccountRow {
  cursor: pointer;
}

.invoicesSpinner {
  margin-top: 4rem;
}

.docName {
  color: var(--bali-hai);
}
