@import 'mixins';

.wrapper {
  display: flex;
  min-height: 100vh;

  @include for-mobile {
    min-height: -webkit-fill-available;
  }
}

.intro,
.content {
  flex: 100%;
  @include for-desktop {
    flex: 50%;
  }
}

.intro {
  background: var(--bg) no-repeat center;
  background-size: cover;
  color: var(--white);
  position: relative;

  display: none;

  @include for-desktop {
    display: flex;
  }

  .maskOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.28),
      rgba(0, 0, 0, 0.66)
    );
  }

  .introDescription {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 4rem;
    padding-right: 9rem;

    .introHeader {
      font-size: 1.75rem;
      letter-spacing: 0.1px;
      line-height: 2.3125rem;
      font-weight: 500;
    }

    .introDescriptionText {
      font-size: 1rem;
      letter-spacing: 0.08px;
      line-height: 1.375rem;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 1.2rem 2.1875rem;

  @include for-desktop {
    padding: 3rem 2.8125rem 2.1875rem;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.topStrip {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  top: -1.5rem;

  & > * {
    margin-right: 0.5rem;
  }
}

.langBadge {
  font-size: 1rem;
  border: 1px solid var(--bali-hai);
  border-radius: 5px;
  padding: 0.3rem 0.4rem;
  color: var(--bali-hai);
  height: 2rem;
  text-align: center;
  font-weight: 500;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.chineseFont {
  font-size: 0.8rem;
  font-weight: 600;
}

.loginHeader {
  text-align: center;
  margin-top: 0.625rem;
  color: var(--text-light-gray);
  font-weight: 400;
  margin-bottom: 1.8125rem;
}

.footer {
  text-align: center;
  margin-top: 2rem;
}

.button {
  &.backButton {
    margin-top: 2.25rem;
    margin-bottom: 3.1875rem;
  }
}

.backButtonIcon {
  margin-right: 0.5313rem;
}
