/* https://loadingSpinner.io/css/ */

.loadingSpinner {
  display: inline-block;
  position: relative;
  width: calc(var(--loading-base-size) * 20);
  height: calc(var(--loading-base-size) * 14);
}
.loadingSpinner:after {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: auto;
  box-sizing: border-box;
  border: calc(var(--loading-base-size) * 8) solid;
  border-color: var(--loading-color) transparent var(--loading-color)
    transparent;
  animation: loadingSpinner 1.2s infinite;
}
@keyframes loadingSpinner {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.loadingOverlay {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
