@import '../../../assets/css/mixins';

.insightsCard {
  background: var(--true-white);
  padding: 1.5rem 1rem 1rem;
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 14px 6px rgba(0, 0, 0, 0.07);
  color: var(--fiord);
  margin: 2rem 0;
  display: grid;
  grid-gap: 1.5rem;

  @include for-desktop {
    padding: 3rem;

    &.column2 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: min-content 1fr;

      & > div:first-child {
        grid-column: 1;
      }

      & > div:nth-child(2) {
        grid-column: 1;
      }

      & > div:nth-child(3) {
        grid-column: 2;
        grid-row: 1 / span 2;
      }
    }
  }

  &::before {
    content: '';
    background: linear-gradient(
      to right,
      var(--gradientLeft),
      var(--gradientRight)
    );
    width: 100%;
    height: 0.5rem;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.5625;
    margin-top: 2.1875rem;
    margin-bottom: 1.125rem;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type:last-child {
      margin-bottom: 0;
    }
  }
}

.insightsHeading {
  &::after {
    content: '';
    width: 5rem;
    background: linear-gradient(
      to right,
      var(--gradientLeft),
      var(--gradientRight)
    );
    height: 0.125rem;
    display: block;
  }

  h2 {
    font-size: 1.25rem;
    color: var(--charcoal-gray);
    font-weight: 500;
    margin: 0.5rem 0 1rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
    color: var(--subheading-color);
    margin: 0;
  }
}

.errorTheme,
.isLoading {
  min-height: 27.9375rem;
}
.errorTheme {
  filter: grayscale(100%);
  .error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 1.25;
    grid-column: auto !important;

    img {
      margin-bottom: 0.75rem;
    }
  }
}
