.main {
  height: 100%;
  width: 100%;
  padding-top: 4rem;
}
.card {
  background-color: white;
  border-radius: 10px;
  text-align: center;
  padding: 2rem;
  margin: auto;
  max-width: 36rem;
}
.images {
  display: flex;
  flex-direction: column;
}
.textTitle {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
}
.textSub {
  font-size: 0.875rem;
  line-height: 20px;
}
