.form {
  display: flex;
  flex-flow: column;
  height: 100%;

  > label {
    margin-bottom: 1.25rem;
  }
}
.submitButton {
  margin-top: 1.65625rem;
}

.loginMethod {
  display: flex;
  margin-bottom: 1.65625rem;

  > label {
    text-align: center;
    flex: 50%;
    position: relative;
    padding-top: 0.71875rem;
    padding-bottom: 0.71875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--apple-green);
    transition: all ease 0.3s;
    border-bottom: 2px solid transparent;

    &.active {
      border-bottom-color: var(--apple-green);
    }

    &.inactive {
      color: var(--gray);
    }

    &:hover {
      cursor: pointer;
      background-color: var(--light-gray);
    }

    > input {
      position: absolute;
      visibility: hidden;
    }

    > [data-icon] {
      margin-bottom: 1rem;
    }
  }
}

.staffButton {
  margin: 10rem auto 0 auto;
  width: fit-content;
}
