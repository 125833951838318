.noFavePayCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2.9375rem;
  padding-bottom: 2.125rem;

  > p {
    margin-top: 0.875rem;
    margin-bottom: 3.25rem;
  }
}

.favePayInfoIcon {
  cursor: pointer;
}

.bottomButton {
  width: 100%;
  height: 2.25rem;
  margin-top: 0.813rem;
  margin-bottom: 0.313rem;
  justify-content: center;

  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.23px;
  text-align: center;
  color: var(--true-white);
}
