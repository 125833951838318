@import 'mixins';

.badge {
  display: inline-block;
  color: var(--text-dark-gray);
  border: 1px solid var(--spindle);
  border-radius: 16px;
  padding: 0.3125rem 0.75rem;
  font-size: 1rem;
  line-height: 1.375rem;
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &.active {
    color: var(--apple-green);
    border-color: var(--fern);
    background-color: var(--aqua-spring);
  }

  .checkbox {
    visibility: hidden;
    width: 0;
  }

  .checkmark {
    @include checkmark;
    display: none;

    &.active {
      margin-left: 0.5rem;
      display: inline-block;
    }
  }
}
