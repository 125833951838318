@import 'mixins';

.container {
  .cardContainer {
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 36.5rem;
    margin-top: 2.813rem;
    border-radius: 2.813rem;
    padding: 5rem 3.75rem 3.75rem 3.75rem;
    text-align: center;
    @include for-mobile {
      margin-left: 0.938rem;
      margin-right: 0.938rem;
      padding: 5rem 0.75rem 3.75rem 0.75rem;
    }

    .image {
    }
    .brandLogo {
      margin-top: 2.5rem;
      min-height: 2rem;
    }
    .errorHeader {
      font-size: 1rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--charcoal-gray);
      @include for-mobile {
        padding: 0 2.125rem;
      }
    }
    .description {
      font-size: 0.875rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--charcoal-gray);
      line-height: 1.25rem;
    }
    .loadingSpinner {
      margin-top: 1.563rem;
    }
    .loadingSpinner:after {
      content: ' ';
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: auto;
      box-sizing: border-box;
      border: 1rem solid;
      border-color: var(--primary) transparent var(--primary) transparent;
      animation: loadingSpinner 1.2s infinite;
    }
    @keyframes loadingSpinner {
      0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      100% {
        transform: rotate(1800deg);
      }
    }
  }
  .footerLine {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: var(--font);
    color: var(--charcoal-gray);
  }
}
