@import 'mixins';

.smallTitle {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--bali-hai);
  letter-spacing: 0.06px;
}

.redemptionCard {
  margin-top: 1.45rem;
  padding: 1.5rem 2rem;

  @include for-desktop {
    padding: 2rem 3rem;
  }

  header {
    display: flex;
    flex-direction: column;

    // unfortunately need to add floats to maintain layout for mobile and desktop
    @include for-desktop {
      display: block;
      &::after {
        content: '';
        clear: both;
        display: block;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.08px;
      line-height: 22px;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .title {
      order: 3;
      text-align: center;
    }

    .price {
      margin-bottom: 1rem;

      span {
        color: var(--very-light-grey);
        text-decoration: line-through;
      }
    }

    .redeemed {
      padding: 0.75rem;
      border-radius: 6px;
      color: var(--apple-green);
      background: var(--aqua-spring);
      order: 1;
      margin-bottom: 1rem;
      text-align: center;

      &::before {
        content: '';
        @include checkmark;
        margin-right: 0.5rem;
      }
    }

    img {
      min-width: 150px;
      object-fit: contain;
      width: 100%;
      border-radius: 5.4px;
      margin: auto;
      max-width: 100px;
      order: 2;
      margin-bottom: 1rem;

      @include for-desktop {
        float: left;
      }
    }

    @include for-desktop {
      .redeemed,
      .title {
        margin-left: calc(150px + 2rem);
        text-align: left;
      }
    }
  }

  dl {
    @include for-desktop {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: dense;
      grid-column-gap: 2rem;

      dt:nth-of-type(odd) {
        grid-column: 1;

        & + dd {
          grid-column: 1;
        }
      }

      dt:nth-of-type(even) {
        grid-column: 2;

        & + dd {
          grid-column: 2;
        }
      }

      dd:nth-last-of-type(-n + 2) {
        border-bottom: none;
      }
    }

    dt {
      @extend .smallTitle;
    }

    dd {
      font-weight: 500;
      letter-spacing: 0.07px;
      margin: 0.25rem 0 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--very-light-grey);

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
