@import '../mixins';

.layout {
  padding: 2rem 1.5625rem 1.8125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  width: 100%;
  @include for-desktop {
    height: auto;
    width: auto;
  }

  .heading {
    color: var(--charcoal-gray);
    font-weight: 500;
    position: relative;
    margin: 0;
    padding-bottom: 1rem;
    width: 100%;
    text-align: center;

    @include for-desktop {
      padding-bottom: 0;
      margin: 0 0 1.5rem;
    }
  }
}

.map {
  margin-bottom: 1rem;
  max-width: 100%;
  width: 100%;
  @include for-desktop {
    width: 37.5rem;
    max-width: 80vw;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  .button {
    width: 100%;
    justify-content: center;
    align-self: center;
    max-width: 100%;
    @include for-desktop {
      max-width: 17.5rem;
    }
  }

  .addressInput {
    margin-bottom: 1rem;
  }
}
