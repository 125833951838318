@import '../../../assets/css/mixins';

.footer {
  background-color: var(--white-smoke);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.6875rem 2rem 8rem;

  @include for-desktop {
    padding-bottom: 2rem;
  }
}

.image {
  height: 100%;
}

.iconContainer {
  display: flex;
}

.socialIcon:not(:last-of-type) {
  margin-right: 0.25rem;
}

.appLinkContainer {
  display: flex;
  .storeBadge {
    display: block;
    height: 1.875rem;
    &:not(:last-of-type) {
      margin-right: 0.25rem;
    }
  }
}

.externalLink {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--pelorous);
  margin: 0.75rem 0;
}
