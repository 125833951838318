.content {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  .errorHeader {
    font-size: 1.6rem;
    margin-top: 1.21875rem;
    margin-bottom: 0.625rem;
  }
  .errorDescription {
    font-size: 1.4rem;
    margin-bottom: 4.4125rem;
  }
  .errorImage {
    background: url('~assets/images/errorcat.png') no-repeat center/contain;
    height: 17.8125rem;
    width: 100%;
  }
}
