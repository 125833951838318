@import 'placeholders';

.container {
  display: flex;
  flex-flow: column;

  .input {
    @extend %form-input;
    min-height: 10rem;
    background-color: var(--true-white);
  }

  .lengthCounter {
    align-self: flex-end;
    color: var(--very-light-grey);
    margin-top: 0.6rem;

    &.invalidLength {
      color: var(--persian-red);
    }
  }
}
