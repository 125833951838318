@import 'mixins';

.outlet {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.outletStatus {
  position: absolute;
  top: 0.875rem;
  min-width: 5.7rem;
  padding: 0.3125rem 0.75rem;
  background-color: var(--status-color);
  font-size: 1rem;
  border-radius: 1rem;
  color: var(--white);
  text-align: center;
  text-transform: capitalize;
}

.outletStatusRight {
  @extend .outletStatus;
  right: 0.875rem;
}

.outletStatusLeft {
  @extend .outletStatus;
  left: 0.875rem;
}

.outletContentContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: '';
    opacity: var(--opacity-value);
    min-height: 5.375rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: var(--bg, var(--primary)) no-repeat center center/cover;

    @include for-desktop {
      min-height: 8.5625rem;
    }
  }
}

.outletPaymentTitle {
  margin: 0 0 0.5rem;
  color: var(--charcoal-gray);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.0625rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include for-desktop {
    margin: 0 0 0.875rem;
    font-size: 1rem;
    line-height: 1.1875rem;
  }
}

.content {
  padding: 0.5rem 0.6875rem;
  width: 100%;
  height: 100%;

  @include for-desktop {
    padding: 1.25rem 1.0625rem 1rem;
  }
}

.contentElement {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 0.5rem;

  :nth-child(1) {
    color: var(--suva-gray);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.875rem;
    text-align: left;

    @include for-desktop {
      font-size: 0.875rem;
      line-height: 1.0625rem;
    }
  }
}

.contentValue {
  margin-top: 0.1875rem;
  color: var(--light-black);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.0625rem;
  text-align: left;

  @include for-desktop {
    font-size: 1.3125rem;
    line-height: 1.5625rem;
  }
}

.bottom {
  display: flex;
  justify-content: flex-end;
}

.bottomButton {
  color: var(--dark-saturated-blue);
  max-width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
}
