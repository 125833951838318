@import '../../../assets/css/mixins';

.overlay {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: none;

  &.visible {
    display: block;
  }
}

.scrollableLayout {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;
}

.outletName {
  font-size: 0.875rem;
  color: var(--light-black);
  margin-right: 0.75rem;
  @include truncate;
  &.isActive {
    font-weight: 500;
  }
}

.outletCheckIcon {
  margin-left: auto;
}

.outletCard {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--true-white);
  padding: 1rem;
  max-width: 80vw;
  width: auto;

  .outletTitle {
    display: block;
    padding: 0 0.5rem 0.9375rem;
    font-size: 0.875rem;
    color: var(--bali-hai);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    button {
      padding: 0.5rem;
    }
  }
}

.switcherButtonLayout {
  display: grid;
  grid-template-columns: 1rem 1fr;
  grid-gap: 0.3rem;
  align-items: center;

  > img {
    width: 1rem;
  }

  .label {
    grid-column: 2;
    font-size: 0.625rem;
    padding-left: 0.375rem;
  }

  .switcherButton {
    min-height: unset;
    display: block;
    border-radius: 4px;
    border: solid 0.5px var(--gainsboro);
    height: 1.75rem;
    overflow: hidden;
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .switcherTitle {
      @include truncate;
      font-size: 0.875rem;
      color: var(--charcoal-gray);
      width: 100%;
    }

    .loadingSpinner {
      left: unset;
    }
  }
}
