@import './mixins';

.smallTitle {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--bali-hai);
  letter-spacing: 0.06px;
}

.dateFilter {
  label {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  margin-bottom: 2rem;
}

.totalRevenue {
  td {
    border-bottom: 1px solid var(--border-color);
  }
}

.dealDetails {
  display: grid;
  gap: 1rem;

  @include for-desktop {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
  }

  header {
    display: grid;
    align-items: center;
    grid-row-gap: 1rem;
    grid-auto-rows: min-content;

    @include for-desktop {
      grid-column: 2/3;
      grid-row-gap: 0.625rem;
    }

    h3 {
      grid-row: 2;
      grid-column: span 2;
      margin: 0;
      font-size: 1rem;
      line-height: 1.25;
      letter-spacing: 0.07px;
      color: var(--charcoal-gray);
    }

    h4 {
      grid-row: 1;
      @extend .smallTitle;
      margin: 0;
    }

    a {
      margin-left: auto;
      padding: 0;
      font-weight: 500;
    }
  }

  dl {
    margin: 0;
    display: grid;
    grid-auto-rows: min-content;
    gap: 0.25rem;

    @include for-desktop {
      grid-column: 2/3;
      gap: 0.5rem;
      grid-template-columns: repeat(2, 1fr);
    }

    dt {
      @extend .smallTitle;

      @include for-desktop {
        &.date {
          grid-column: 1;
          grid-row: 1;

          & + dd {
            grid-column: 1;
          }
        }

        &.price {
          grid-column: 2;
          grid-row: 1;

          & + dd {
            grid-column: 2;
          }
        }
      }
    }

    dd {
      margin: 0;
      margin-bottom: 1.5rem;
      color: var(--charcoal-gray);
      line-height: 1.5;
      letter-spacing: 0.08px;

      span {
        color: var(--very-light-grey);
        text-decoration: line-through;
      }
    }
  }

  img {
    grid-row: 1;
    object-fit: cover;
    width: 100%;
    max-height: 12.5rem;

    @include for-desktop {
      grid-row: 1/3;
      max-height: unset;
      min-height: 9.5rem;
    }
  }
}
