@import 'mixins';

.modal {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 90vw;
  padding: 2.125rem;
  justify-content: space-between;
  overflow-y: auto;

  @include for-desktop {
    justify-content: unset;
    max-width: 40rem;
    padding: 2rem 5rem 2rem;
  }

  .title {
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--charcoal-gray);
    margin-bottom: 0;
  }

  .description {
    text-align: center;
    color: var(--charcoal-gray);
    margin: 1rem 0;
  }
}
