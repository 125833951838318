@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.slick-slider {
  width: 85vw;
  max-width: 80vw;
  margin: auto;

  @include for-desktop {
    width: auto;
  }
}

.slick-slide {
  padding: 0 0.9375rem;

  @include for-desktop {
    padding-right: 0.9375rem;
  }
}

.slick-list {
  height: 100%;
}
