@import '../mixins';

.card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  @include for-desktop {
    flex-direction: row;
    padding-left: 0;
  }
}

.qrCodeWrapper {
  display: flex;
  flex: 2 1;
  justify-content: center;
  padding: 0 2.5625rem;
  margin-bottom: 2rem;
  @include for-desktop {
    margin-bottom: 0;
    align-self: flex-start;
  }
}
.details {
  max-width: 22.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--charcoal-gray);
    margin: 0 0 1rem;
  }
  .description {
    line-height: 1.625rem;
    color: var(--dark-gray);
    margin-bottom: 1rem;
  }
  .moreInfo {
    margin-bottom: 3rem;
  }

  .sendQRCTAButton {
    font-size: 1.125rem;
    font-weight: 500;
    justify-content: center;
    color: var(--grayish-blue);
    border-color: var(--bermuda-grey);
  }

  .form {
    display: flex;
    flex-direction: column;

    @include for-desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 0.5rem;
    }
    .email {
      grid-column: span 4;
      margin-bottom: 0.5rem;
      @include for-desktop {
        margin-bottom: 0;
      }
    }
  }
}
