@import 'mixins';

.cardWrapper {
  flex: 1;
  margin-top: 1.45rem;
}

.card {
  &.reportCard {
    padding-left: 1.4375rem;
    padding-right: 1.4375rem;
    padding: 1.8125rem 2.375rem;
  }
}

.selectReport {
  margin-top: 0;
  margin-bottom: 1.5625rem;
}

.reportTypeBadges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;

  > label {
    margin-bottom: 0.5rem;

    @include for-desktop {
      margin-bottom: 0;
    }
  }
}

.selectDateRange {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
}

.dateOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.statementsEmailContainer {
  text-align: right;
  display: flex;
  label {
    margin-right: 0.5rem;
  }
}
