.receiptCard {
  padding: 1.875rem;
  margin-top: 1.5rem;
}

.successContainer {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 3.125rem;
  border-radius: 6px;
  background-color: var(--aqua-spring);
}

.cancelledContainer {
  @extend .successContainer;
  margin-top: 1.125rem;
  background-color: var(--peep-pink);
}

.successText {
  margin-left: 1rem;

  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--green);
}

.cancelledText {
  @extend .successText;
  color: var(--watermelon-red);
}

.receiptTitle {
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: var(--bermuda-grey);
}

.receiptValue {
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.07px;
  color: var(--charcoal-gray);
}

.divider {
  margin-top: 0.875rem;
  border: solid 0.5px var(--gainsboro);
}

.cancelTransactionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 22rem;
  width: 100%;
  height: 3.125rem;
  margin-top: 2rem;
  margin-left: auto;
  border-radius: 4px;
  border-color: var(--persian-red);
  color: var(--persian-red);
  background: inherit;

  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: 0.26px;
  text-align: center;
}
