// Responsive breakpoint

@mixin for-mobile {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin for-desktop {
  @media (orientation: landscape) and (min-device-aspect-ratio: 1/1) {
    @content;
  }
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin clamp-on-line($lineNumber: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lineNumber;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  text-align: left;
  overflow: hidden;
}

@mixin caret-down(
  $color: var(--dark-gray),
  $borderWidth: 0.1rem,
  $size: 0.5rem,
  $direction: down
) {
  &::after {
    @if $direction==down {
      --rotation: 45deg;
      transform: translateY(calc(-50% - #{$borderWidth}))
        rotate(var(--rotation));
    }

    @if $direction==up {
      --rotation: -135deg;
      transform: translateY(calc(-50% + #{$borderWidth}))
        rotate(var(--rotation));
    }

    @if $direction==right {
      --rotation: -45deg;
      transform: translateY(calc(-50% - #{$borderWidth}))
        rotate(var(--rotation));
    }

    top: 50%;
    transition: all ease 0.3s;
    content: '';
    position: absolute;
    right: 1.25rem;
    border-bottom: #{$borderWidth} solid;
    border-right: #{$borderWidth} solid;
    color: $color;
    width: #{$size};
    height: #{$size};
  }
}

@mixin checkmark {
  display: inline-block;
  border-left: 2px solid;
  border-bottom: 2px solid;
  width: 0.875rem;
  height: 0.4rem;
  transform: rotate(-45deg);
  bottom: 0.25rem;
  position: relative;
}

@mixin mini-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */

  &::-webkit-scrollbar {
    width: 0.6rem;
    border-left: 1px solid var(--very-light-grey);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--very-light-grey);
    background-clip: padding-box;
    border-right: 0.15rem solid transparent;
    border-left: 0.15rem solid transparent;
    border-radius: 100px;

    &:hover {
      background: var(--text-light-gray);
    }
  }
}
