.announcement {
  height: 16.875rem;
  max-width: 25.75rem;
  padding: 1.3125rem;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.375rem;
  display: flex;
  flex-direction: column;

  .bottomButtonBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bottomButtonDiv {
      margin-left: 0.625rem;
    }
    .bottomButton {
      color: var(--bali-hai);
      font-weight: 500;
      align-self: flex-end;
      border-width: 1.2px;
    }
  }
}

.card {
  &Container {
    display: flex;

    &:before {
      content: '';
      background: var(--bg, dodgerblue) no-repeat center center/cover;
      height: 8.5625rem;
      width: 40%;
      margin-right: 1.25rem;
    }
  }

  &Date,
  &Title,
  &Content,
  &Outlet,
  &Date span {
    margin: 0;
    padding: 0;
  }

  &Content {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  &Title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6875rem;
    letter-spacing: 0.005625rem;
  }

  &Outlet {
    font-size: 1rem;
  }

  &Date {
    font-size: 1rem;
    display: flex;
    margin-top: 0.25rem;
    margin-bottom: 0.3125rem;
  }
}
