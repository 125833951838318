@import 'mixins';

.accordion {
  display: flex;
  flex-flow: column;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;

  .header {
    user-select: none;
    padding: 1.25rem 0;
    font-weight: 400;
    color: var(--light-black);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: 1rem;
    line-height: 1.1875rem;

    @mixin arrow($direction: down) {
      @include caret-down($color: var(--primary), $direction: $direction);
    }

    &.caretRight {
      @include arrow(right);
    }
    &.caretDown {
      @include arrow(down);
    }
    &:hover {
      background-color: var(--light-gray);
    }
  }

  .highlighted {
    color: var(--primary);
    font-weight: 500;
    font-size: 1rem;

    > :nth-child(1) {
      background-color: var(--primary);
    }
  }

  .selected {
    color: var(--black);
    font-weight: 500;
  }

  .icon {
    --icon-size: 1rem;
    height: var(--icon-size);
    width: var(--icon-size);
    margin-left: 0.4375rem;
    margin-right: 0.4375rem;
    background-color: var(--light-black);
    mask: var(--icon) no-repeat center center/contain;

    &.caret {
      position: absolute;
      right: 0;
      mask: unset;
      background-color: transparent;
    }
  }

  a.button {
    text-decoration: none;
    color: var(--light-black);
    padding: var(--space) 0 var(--space) 2.5rem;
    font-size: 1rem;
    line-height: 1.1875rem;
    margin-left: 0.4375rem;

    &[aria-current='page'] {
      font-weight: 400;
      color: var(--primary);
    }

    &:hover {
      background-color: var(--light-gray);
    }
  }
}
