@import 'mixins';

.OutletStatementsViewHeading {
  position: sticky;
  top: 1rem;
  background: var(--white);
  z-index: 5;
  padding-top: 0.5rem;

  .Heading {
    display: block;

    .backToAllStatements {
      display: flex;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  .SmallFont {
    font-size: var(--normal-font);
  }

  .Title {
    font-weight: normal;
    font-size: var(--heading-font);
  }

  .Subtitle {
    color: var(--gray);
    font-size: 0.9375rem;
    margin-top: 0.6875rem;
    margin-bottom: 2rem;
  }

  .datePickerAndSelectionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .ButtonBar {
    padding-top: 1rem;

    @include for-desktop {
      display: flex;
      flex-direction: column;
    }

    .DateButton {
      margin-right: auto;
    }
  }
}

.DropDownItemStyle {
  padding: 2rem;
}

.Statements {
  font-size: 0.8525rem;

  .StatementsSpace {
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .CheckBox {
      margin-right: 1.5rem;
    }

    .ReferenceNumber {
      display: flex;
      flex-direction: column;

      .ReferenceNumberStyle {
        font-size: 0.9375rem;
        margin: 0;
      }

      .NumberOfTransaction {
        margin: 0;
        color: var(--bali-hai);
      }
    }

    .Price {
      color: var(--green);
    }

    .PriceRed {
      color: var(--persian-red);
    }

    .PriceGrey {
      color: var(--gray);
    }

    .StatementsDataSpace {
      margin-right: 0.5rem;
      overflow-wrap: break-word;
      @include clamp-on-line(4);
    }

    .referenceNumberColumn {
      width: 25%;
    }

    .statementDateRangeColumn {
      width: 25%;
    }

    .statementIssueDateColumn {
      width: 15%;
    }

    .amountColumnColumn {
      width: 10%;
    }

    .actionButtonColumn {
      width: 15%;
    }
  }

  .SelectedStatementsSpace {
    @extend .StatementsSpace;
    background-color: var(--foam);
    border-radius: 0.4rem;
  }

  .StatementsHeader {
    @extend .StatementsSpace;
    border-bottom: 0.12rem solid var(--bali-hai);
    color: var(--bali-hai);
  }
}

.BulkActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @include for-mobile {
    margin-top: 0.5rem;
  }

  .BulkActionButton {
    border-radius: 1rem;
  }
}

.ContactPMTeamBanner {
  background-color: var(--foam);
  height: 12rem;
  padding: 2rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  filter: brightness(0.95);

  .InfoStyle {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .InfoIcon {
    height: 2.5rem;
    width: 2.5rem;
    color: white;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-image: var(--icon-info-circle);
  }
}

.modal {
  position: relative;

  // string '|' before 'view outlets'
  > span:nth-child(2) {
    padding: 0 1.5rem;
  }

  .outOfBoundModal {
    display: inline-block;
  }

  .viewOutletButton {
    color: var(--robin-egg-blue);
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
  }

  .modalContents {
    display: none;
    position: absolute;
    background: var(--true-white);
    border: 1px solid var(--alto);
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.03);
    height: 15rem;
    max-width: 20rem;
    border-radius: 10px;
    z-index: 3;
    left: 0;

    &.isOpen {
      display: flex;
      flex-direction: column;
    }

    .modalHeading {
      color: var(--charcoal-gray);
      font-size: var(--normal-font);
      font-weight: var(--bold);
      margin-bottom: 0;
    }

    .modalBankAccountNumber {
      color: var(--nobel);
      font-size: var(--small-font);
      margin-top: 0.5rem;
    }

    .modalOutletNames {
      color: var(--dark-black);
      overflow-y: scroll;
      margin-bottom: 1rem;
      font-size: 0.9rem;
    }

    > div {
      padding: 0 1rem;
    }

    > hr {
      border: 1px solid var(--white-smoke);
      width: 100%;
    }
  }
}

.loadingSpinnerLoadMore {
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptySpaceToLoadMore {
  display: flex;
}

.statementsEmpty {
  color: var(--dark-gray);
  font-weight: normal;
  font-size: 1.125rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 3.75rem 2rem;
  margin: 0;
  text-align: center;

  .statementImage {
    height: 10rem;
    width: 10rem;
  }

  .noBankAccountIcon {
    height: 5rem;
    width: 5rem;
  }

  .statementsEmptyData {
    margin-top: 0.94rem;
  }

  .noBankAccountText {
    p {
      font-weight: 500;
    }
  }

  .statementsEmptyDescription {
    margin-top: 3.1rem;
    margin-bottom: 0.31rem;
  }
}

@keyframes slide-from-left {
  0% {
    transform: translateX(-70%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(70%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
