.bulkActionsContainer {
  position: relative;

  .bulkActions {
    display: none;
    position: absolute;
    bottom: 3rem;
    border-radius: 8px;
    right: 0;
    background-color: var(--white);
    box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.1);
    width: 12rem;

    &.isOpen {
      display: flex;
      flex-direction: column;
    }

    .actionButton {
      border: none;
      justify-content: center;
    }

    .curvedTopBorder {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    .curvedBottomBorder {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    hr {
      border: none;
      height: 1px;
      background-color: var(--white-smoke);
      width: 80%;
      margin: 0 auto;
    }
  }
}
