@import 'mixins';

.mainBody {
  width: 30rem;
  padding: 1rem;
  overflow-x: hidden;

  .title {
    color: var(--text-dark-gray);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.625rem;
  }

  .slidesLeft {
    position: relative;
    right: 100%;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;

    @-webkit-keyframes slide {
      100% {
        right: 100%;
      }
    }

    @keyframes slide {
      100% {
        right: 100%;
      }
    }
  }

  .confirmPrompt {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;

    .alertIcon {
      width: 4.375rem;
      height: 4.375rem;
      margin: 1rem;
      background-image: var(--icon-alert);
    }

    dl {
      width: 100%;
      padding: 1rem;
      padding-bottom: 0rem;
      dt {
        color: var(--bermuda-grey);
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.1875rem;
      }
      dd {
        overflow-wrap: break-word;
        margin: 0 0 0.75rem;
        padding-bottom: 0.75rem;
        color: var(--text-dark-gray);
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.625rem;

        &:not(:last-child) {
          border-bottom: 1px solid var(--light-gray);
        }
        .paidVia {
          color: var(--bermuda-grey);
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.1875rem;
          padding-left: 0.35rem;
        }
      }
    }

    .buttonDiv {
      width: 100%;
    }
  }

  .cancelationCodePrompt {
    position: relative;
    right: -100%;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;

    .mainContent {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;

      .textField {
        margin-top: 1rem;
        margin-bottom: 2rem;
      }

      .buttons {
        margin-top: 1rem;
      }
    }

    @-webkit-keyframes slide {
      100% {
        right: 0;
      }
    }
    @keyframes slide {
      100% {
        right: 0;
      }
    }
  }

  @include for-mobile {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
}
