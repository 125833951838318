@import 'mixins';

.dealCard {
  .contentContainer {
    padding: 1rem;
  }

  .title {
    color: var(--charcoal-grey);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.08px;
    font-size: 1rem;
    @include truncate;
  }

  .smallTitle {
    color: var(--bali-hai);
    font-size: 0.875rem;
    letter-spacing: 0.5px;
    font-weight: 400;
  }

  h4 {
    margin: 0;
  }

  h3 {
    margin: 0.5rem 0 1rem;
  }

  dl {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.5rem;
    grid-auto-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
  }

  dt {
    grid-row-start: 1;
  }

  dd {
    margin: 0;
  }

  footer {
    padding-top: 0.5rem;
    border-top: 1px solid var(--very-light-grey);

    a {
      display: block;
    }
  }
}
