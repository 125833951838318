.uploadRoot {
  $item-width: 6rem;
  $border-width: 0.05rem;
  $margin-size: 0.6rem;
  $close-button-margin: 0.4rem;

  .uploadContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    > *:not(:last-child) {
      margin-right: $margin-size;
    }

    %upload-item {
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      color: var(--bali-hai);
      border: $border-width solid var(--very-light-grey);
      border-radius: 0.4rem;
      width: $item-width;
      height: $item-width;
      margin-bottom: $margin-size;
    }

    .addButton {
      @extend %upload-item;
      cursor: pointer;
      color: var(--very-light-gray);
      background-color: var(--white-smoke);
      border-color: var(--white-smoke);
      text-align: center;
    }

    .deleteButton {
      cursor: pointer;
      position: absolute;
      top: $close-button-margin;
      right: $close-button-margin;
    }

    .imagePreview {
      @extend %upload-item;
      border-width: 0;
    }
  }
}
