@import 'mixins';

.layout {
  width: 100%;
  max-width: 100%;
  height: 68vh;
  @include for-desktop {
    max-width: 37.5rem;
    height: 25rem;
  }
}
