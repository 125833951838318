@import 'mixins';
@import 'placeholders';
@import 'Carousel.module.scss';

$logo-size: 2rem;
$image-radius: 0.6rem;

.detailsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
  gap: 1rem;

  @include for-mobile {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .cardWrapper {
    @extend %vertical-flex;
    cursor: default;
    align-items: center;
    align-self: start;
    margin-bottom: 1rem;

    @include for-mobile {
      order: 2;
      justify-self: center;
      margin-top: 1rem;
    }

    @media (max-width: 411px) {
      width: 100%;
    }

    @media (min-width: 412px) {
      width: 21rem;
    }

    .postCard {
      @extend %vertical-flex;
      border-radius: 1rem;
      padding: 1rem;
      box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
      width: 100%;
      background-color: var(--true-white);
      font-family: 'Nunito';

      @include for-mobile {
        order: 2;
      }

      .header {
        display: flex;
        margin-bottom: 1rem;
        overflow: hidden;

        .logo {
          height: $logo-size;
          width: $logo-size;
          border-radius: 24%;
          margin-right: 0.5rem;
          flex-shrink: 0;
        }

        .headerContent {
          @extend %vertical-flex;
          flex: 1;
          overflow: hidden;

          .title {
            color: var(--text-dark-gray);
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .subtitle {
            color: var(--text-light-gray);
            font-size: small;
          }
        }
      }

      .description {
        color: var(--text-dark-grey);
        overflow-wrap: break-word; //fix for safari
        white-space: pre-wrap;
      }
    }

    .image {
      margin-top: 1rem;
      align-self: center;
      width: 100%;
      border-radius: $image-radius;
      box-shadow: 0 0 0 1px var(--whisper-white);
      overflow: hidden;
      will-change: transform; // fix for iOS border radius disappear on swipe

      .carouselImage {
        border-radius: $image-radius;
      }
    }

    .postSummary {
      display: flex;
      background-color: var(--alpha-black);
      color: var(--true-white);
      align-items: center;
      padding: 0.8rem 0;
      backdrop-filter: blur(100px);

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        margin-left: 1rem;
      }

      .chvron {
        margin-left: 0.5rem;
        margin-right: 0.8rem;
      }
    }
  }

  .detailsRowContainer {
    @include for-mobile {
      order: 1;
    }
  }
}

.detailsRow {
  margin-bottom: 1rem;

  .detailsTitle,
  .detailsContent,
  .remainingOutlets,
  .firstTwoOutlets {
    margin: 0 0 0.5rem 0;
  }

  .detailsContent {
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .detailsTitle {
    font-size: var(--small-font);
    text-transform: uppercase;
    color: var(--text-light-gray);

    @include for-mobile {
      letter-spacing: 0.156rem;
    }
  }

  .remainingOutlets {
    color: var(--pelorous);
    text-decoration: underline;
    cursor: pointer;
  }
}

.postTemplate {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--bali-hai);
  font-size: 0.8rem;
  background-color: var(--white-smoke);
  height: 100%;

  > img {
    margin: 0.5rem;
  }
}

.postDetailsButtons {
  margin-top: 1rem;
  display: flex;
  width: 100%;

  .editButton {
    justify-content: center;
  }

  .bulkActionsButton {
    margin-left: 1rem;
    font-size: 0.9rem;
  }
}

.insightsContainer {
  .insightsCard {
    display: flex;
    border: 1px solid var(--white-smoke);
    background-color: var(--true-white);
    border-radius: 8px;
    padding: 1rem;

    .insightsIcon {
      background-color: var(--aqua-spring);
      height: 5rem;
      width: 5rem;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }

    .insightsDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: var(--small-font);
        color: var(--bali-hai);
        margin-bottom: 0.5rem;
      }

      .count {
        font-weight: 500;
      }
    }
  }
}

.infoCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--white-smoke);
  border-radius: 8px;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  width: 100%;
  cursor: pointer;

  @include for-mobile {
    width: 100%;
  }

  .infoIcon {
    margin-right: 0.75rem;
  }

  .infoText {
    font-size: 0.875rem;
  }
}

.deletePostModal {
  .title,
  .content {
    margin-top: 0;
  }

  .title {
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--charcoal-gray);
  }

  .content {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .confirmationButtons {
    display: flex;
    justify-content: center;
    width: 100%;

    .button {
      width: 45%;
      max-width: 10rem;
      justify-content: center;
    }

    .marginRight {
      margin-right: 1rem;
    }
  }

  .errorMessage {
    color: var(--errors);

    p {
      margin-bottom: 0;
    }
  }
}

.shareLinkModal {
  .title {
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--charcoal-gray);
  }

  .copyLink {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    border: 1px solid var(--white-smoke);
    border-radius: 8px;
    width: 100%;

    .link {
      @include truncate;
      margin: 0 0.5rem 0 0;
    }
  }

  .copyLinkToClipboard {
    display: none;
    width: 100%;
    color: var(--forest-green);
    font-size: 0.875rem;

    &.isOpen {
      display: block;
    }
  }
}

.marginTop {
  margin-top: 2rem;
}

.bold {
  font-weight: 500;
}
