@import 'mixins';

.overlay {
  background: var(--white);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .switchOutletCard {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1.25rem 1.6875rem;
    @include for-desktop {
      padding: 2.25rem 2.6875rem;
      width: unset;
    }
  }

  .title {
    color: var(--dark-gray);
    font-weight: 500;
    font-size: 1rem;
    margin: 0 3rem 2rem 0;

    @include for-desktop {
      margin: 0 4rem 2rem 0;
    }
  }
}

.outlets {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 70vh;

  @include for-desktop {
    max-width: 100vh;
  }
  
  @include for-mobile {
    max-width: 40vh;
  }
}

.loadingSpinnerWrapper {
  position: relative;
  padding: 3rem 0;
}

.button {
  @include clamp-on-line(1);
  &.outletButton {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      margin-left: 1rem;
    }
  }
}
.outletName {
  @include truncate;
}
.backButton {
  margin-bottom: 1rem;
  padding-right: 0.75rem;
  > [data-icon] {
    margin-right: 0.75rem;
  }
}
