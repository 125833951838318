.businessInfo {
  display: flex;
  flex-flow: column;
  margin-bottom: 3.8125rem;
  .banner {
    position: relative;
    height: 16.3125rem;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--bg) no-repeat center center/cover;
      border-radius: 11px;
    }
  }
  .businessName {
    position: relative;
    font-size: 1.5rem;
    color: var(--apple-green);
    line-height: 1.8125rem;
    font-weight: 500;
    margin: 5.5rem 0 1.375rem;
    &:before {
      --logo-size: 8.6875rem;
      content: '';
      position: absolute;
      left: 1.8125rem;
      transform: translateY(calc(var(--logo-size) * -1 - 1.375rem));
      background: var(--logo-bg) no-repeat center center/cover;
      height: var(--logo-size);
      width: var(--logo-size);
      border-radius: 50%;
    }
  }

  .businessLinks {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .description {
    margin: 3.8125rem 0 1.6875rem;
  }
}

.gallery {
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  .galleryImage {
    width: 100%;
  }
}
