@import 'mixins';

.title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--charcoal-gray);
}

.content {
  margin-bottom: 1.5rem;
  text-align: center;
}
