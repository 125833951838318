.layout {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

@mixin base {
  input {
    visibility: hidden;
    position: absolute;
  }

  .partialIcon {
    display: block;
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0.1rem;
      right: 0;
      margin: 0 auto;
      transform: rotate(90deg);
      border-style: solid;
      border-width: 0px 2.5px 0px 0;
      width: 0.5rem;
    }
  }

  .checkboxIcon {
    display: inline-block;
    background: transparent;
    border-radius: 4px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      display: none;
      margin: 0 auto;
      transform: rotate(40deg);
      border-style: solid;
      border-width: 0px 2px 2px 0;
      width: 0.5rem;
      height: 1rem;
    }
  }

  input:checked ~ .checkboxIcon:after {
    display: block;
  }
}

.default {
  @include base;

  input:checked ~ .checkboxIcon {
    background-color: var(--pale-pink);
  }

  .checkboxIcon {
    width: 1.25rem;
    height: 1.25rem;
    border: solid 1px;
    border-color: var(--primary);

    &:hover {
      background-color: var(--pale-pink);
    }

    &:after {
      border-color: var(--primary);
      width: 0.4rem;
      height: 0.9rem;
    }
  }
}

.green {
  @include base;

  input:checked ~ .checkboxIcon {
    background-color: var(--forest-green);
    border-color: var(--forest-green);
  }

  .checkboxIcon {
    width: 1rem;
    height: 1rem;
    border: solid 2px;
    border-color: var(--very-light-grey);

    &:after {
      border-color: var(--true-white);
      width: 0.33rem;
      height: 0.65rem;
    }
  }
}

.menuHeader {
  @extend .green;

  .partialIcon {
    width: 1.5rem;
    height: 1.5rem;
    background: var(--forest-green);

    &:after {
      border-color: var(--true-white);
      width: 0.35rem;
      height: 1rem;
    }
  }

  .checkboxIcon {
    width: 1.5rem;
    height: 1.5rem;
    border: solid 2px;
    border-color: var(--gray);

    &:after {
      width: 0.35rem;
      height: 0.95rem;
    }
  }
}

.subMenuHeader {
  @extend .menuHeader;
}

.circular {
  @include base;

  input:checked ~ .checkboxIcon {
    border: 5px solid var(--true-white);
    box-shadow: 0 0 0 1px var(--forest-green);
    background-color: var(--forest-green);
  }

  .checkboxIcon {
    width: 1.25rem;
    height: 1.25rem;
    border: solid 1px;
    border-color: var(--grayish-blue);
    background: transparent;
    border-radius: 100%;

    &:after {
      border-color: var(--primary);
      width: 0.4rem;
      height: 0.9rem;
      border: none;
    }

    &:hover {
      border: 5px solid var(--true-white);
      box-shadow: 0 0 0 0.5px var(--grayish-blue);
      background-color: var(--grayish-blue);
    }
  }
}

.withChildren {
  display: grid;
  grid-template-columns: 1rem 1fr;
  grid-column-gap: 1rem;

  span {
    position: relative;
  }
}
