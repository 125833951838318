@import 'mixins';

.layout {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 2.125rem;
  justify-content: space-between;
  overflow-y: auto;

  @include for-desktop {
    justify-content: unset;
    max-width: 40rem;
    padding: 2rem 5rem 2rem;
  }
}

.featureLayout {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1.2rem;
}

.title {
  color: var(--apple-green);
  font-size: 1.6875rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  padding: 0 1rem;
  margin-bottom: 1rem;

  @include for-desktop {
    text-align: left;
  }
}

.bannerImage {
  width: 100%;
  margin-bottom: 1rem;
}

.subtitle {
  color: var(--charcoal-gray);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.8125rem;
  margin-bottom: 0.2rem;
}

.description {
  color: var(--text-light-gray);
  font-size: 1.125rem;
  width: 100%;

  @include for-desktop {
    width: 72%;
  }
}

.button.welcomeButton {
  height: 2.625rem;
  min-height: 2.625rem;
}
