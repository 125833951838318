@import 'variables';
@import 'mixins';

$logo-size: 3rem;

.preview {
  color: var(--true-white);
  display: flex;
  flex-flow: column;
  padding: 1rem;
  //min-height: 169px; //need this value to remain here
  font-size: 1rem;

  .logo {
    max-width: 50%;
    height: $logo-size;

    @include for-mobile {
      max-width: 60%;
    }
  }

  .space {
    flex: 1;
  }

  .name {
    font-size: 120%;
    font-weight: 800;
    font-family: 'Nunito';
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Nunito';

    .value {
      display: flex;
      margin-right: 0.5rem;

      .label {
        font-size: 64%;
        margin-right: 0.2rem;
      }

      .amount {
        font-weight: bold;
        @include for-mobile {
          font-size: 64%;
        }
      }
    }

    .bonus {
      border-radius: 10px;
      padding: 4px 8px; // fixed
      font-size: 80%;

      @include for-mobile {
        font-size: 60%;
      }

      color: var(--true-white);
      background-color: var(--primary);
      text-align: center;
      margin-left: auto;
    }
  }
}
