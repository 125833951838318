.sectionHeader {
  display: flex;
  align-items: flex-end;
  color: var(--charcoal-gray);
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0 0 1rem;
  > img {
    margin-right: 0.5rem;
  }
}
