.overlay {
  padding: 2rem;
  max-width: 25rem;
  width: 100%;
  text-align: center;

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--charcoal-gray);
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
    color: var(--text-light-gray);
    margin-bottom: 1.6875rem;
  }
}

.actionButtons {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
