.webviewHeading {
  display: grid;
  grid-row-gap: 1rem;
  margin: 2.125rem -1rem 1rem;
  padding: 0 1rem 0.625rem;
  border-bottom: 1px solid var(--very-light-grey);

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.32;
    color: var(--charcoal-gray);
    margin: 0;
  }

  .subtitle {
    margin: 0;
  }

  .backButton {
    grid-row: 1;
    padding: 0;
  }

  .closeWindow {
    display: flex;
    justify-content: flex-end;

    .closeWindowIcon {
      cursor: pointer;
    }
  }
}

.divide {
  grid-template-columns: 1fr auto;

  h2 {
    grid-column: 1;
  }

  button + * {
    grid-row: 2;
    grid-column: 2;
  }
}
