.header {
  margin-top: 0;
  margin-bottom: 0.3125rem;
}

.errorPinValue {
  color: var(--red);

  > p {
    margin-top: 0;
    font-size: 0.875rem;
  }
}

.paragraph {
  font-weight: 500;
  line-height: 1.1875rem;
  color: var(--dark-gray);
  margin-bottom: 2.125rem;
}

.form {
  display: flex;
  flex-direction: column;
  > button,
  > label {
    margin-bottom: 1.25rem;
  }
}

.button {
  &.backButton {
    margin-top: 0;
    margin-bottom: 2.1875rem;
  }
}

.resendOTP {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    margin-right: 0.5rem;
  }
}
