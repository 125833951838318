.button.cancel {
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  color: var(--persian-red);
  border-color: var(--persian-red);
  background-color: var(--true-white);

  &:hover:not([disabled]) {
    background-color: var(--light-gray);
  }

  &[disabled] {
    background: var(--disabled);
    border-color: var(--disabled);
  }
}
