@import 'mixins';

.announcementForm {
  width: 100%;

  .announcementFormTitle {
    font-size: 1.25rem;
    line-height: 2.3125rem;
  }

  .imagePreview {
    width: calc(1.25rem * 5);
    height: calc(1.25rem * 5);
    object-fit: cover;
    display: block;
    margin-top: 1rem;
  }

  .characterLimit {
    display: block;
    text-align: end;
    margin-top: 1rem;
  }

  .formOutlets {
    > * {
      margin: 0.625rem;
    }

    @include for-desktop {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .formPhoto {
    .imageUpload {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

      & + label {
        display: inline-block;
        color: var(--forest-green);
        border: 1px solid var(--forest-green);
        cursor: pointer;
        padding: 1rem;
        border-radius: 0.3rem;
      }
    }
  }

  .error {
    color: var(--red);
    text-align: right;
    margin-top: var(--half-space);
    font-size: 0.75rem;
  }

  .formElement {
    max-width: 26rem;
  }

  .field {
    width: 100%;
    background: transparent;
    border-radius: 5px;
    border: 1px solid var(--very-light-grey);
    outline: none;
    padding: 0.625rem 1rem;

    &::placeholder {
      color: var(--bali-hai);
    }
  }

  .announcement {
    width: 100%;
    min-height: 10rem;
    resize: none;
  }

  .outletOptions {
    cursor: pointer;
    color: var(--forest-green);

    span:first-child {
      padding-right: 0.6rem;
      border-right: 1px solid var(--forest-green);
    }

    span:nth-child(2) {
      padding-left: 0.6rem;
    }
  }
}
h5 {
  margin: 1rem 0;
  font-size: 0.875rem;
  font-weight: 500;
}
h3 {
  font-size: 1.125rem;
  margin-bottom: 0rem;
  font-weight: 500;
}

.submitButton {
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 5rem;

  @include for-desktop {
    max-width: 19rem;
    margin-left: auto;
  }
}
.announcementFormSubtitle {
  font-size: 0.875rem;
  color: var(--text-light-gray);
}

.neighbourhoodSlug {
  color: var(--primary);
  display: contents;
}
