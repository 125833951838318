@import 'mixins';

.datePickerButtonsContainer {
  display: flex;
  z-index: 1;

  .unSelectedDatePickerContainer,
  .selectedDatePickerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0rem;
    border-radius: 1rem;
  }

  .unSelectedDatePickerContainer {
    cursor: pointer;
    width: 100%;

    .searchOutletBar {
      width: 100%;
      border: 1px solid var(--very-light-grey);
      border-radius: 5px;
      padding: 0.5rem;

      &:disabled {
        cursor: not-allowed;
        background-color: var(--very-light-grey);
      }

      &::placeholder {
        color: var(--very-light-grey);
      }
    }
  }

  .selectedDatePickerContainer {
    border: 2px solid var(--forest-green);
    background: var(--panache);
    margin-right: 2rem;
    @include clamp-on-line(2);
  }

  &.popupDatepicker {
    padding-bottom: 1rem;
    position: absolute;
  }
}

.datepicker {
  position: relative;
  background: var(--white);

  .container {
    border-radius: 5px;
    width: fit-content;

    .dateInputBox {
      cursor: pointer;
      display: flex;
      border-radius: 1rem;
      align-items: center;
      padding: 0.5rem 0.75rem;
      border: 1px solid var(--alto);
      background: var(--true-white);
      margin: 0.5rem 0;

      &.greenText {
        color: var(--apple-green);
        border-color: var(--fern);
        background-color: var(--aqua-spring);
      }
    }

    .dateInputBadge {
      cursor: pointer;
      border: 1px solid var(--regent-gray);
      border-radius: 1rem;
      padding: 0.5rem 1rem;

      &.active {
        border: 2px solid var(--forest-green);
        background-color: var(--panache);
      }
    }

    &.disabled .dateInputBox {
      cursor: not-allowed;
      background-color: var(--very-light-grey);
      color: var(--emperor);
    }
  }
}

.datepickerpanel {
  background: var(--white);
  border: 1px solid var(--alto);
  border-radius: 5px;

  &.timeSelection {
    width: 19rem;
    max-width: 80vw;
  }

  .monthContainer {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .monthNavigator {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: var(--normal-font);
      margin-bottom: 0.5rem;

      .monthName {
        margin: 0;
        font-weight: var(--bold);
      }

      .monthNavigatorButton {
        border: 1px solid var(--iron);
        border-radius: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2.5rem;
      }

      .invisibleNavigatorButton {
        height: 2rem;
        width: 2.5rem;
      }
    }
  }
  .calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--small-font);

    .dates {
      display: grid;
      grid-template-columns: repeat(7, 2rem);
      text-align: center;
    }

    .week {
      color: var(--dove-gray);
      padding-bottom: 0.5rem;
    }

    .day {
      padding: 0.5rem;
      border-right: 1px solid var(--iron);
      border-bottom: 1px solid var(--iron);

      @include for-mobile {
        padding: 0.25rem;
      }

      // each sundays' date boxes' left border
      &:nth-child(7n + 1) {
        border-left: 1px solid var(--iron);
      }
    }

    .borderRight {
      border-right: 1px solid var(--iron);
    }

    .borderBottom {
      border-bottom: 1px solid var(--iron);
    }

    .selectedDate {
      background: var(--aqua-spring);
    }

    .disabledDate {
      cursor: not-allowed;
      color: var(--iron);
    }
  }

  .timeSelectionFooter {
    padding: 0.5rem 1rem;
    border-top: 1px solid var(--alto);

    .selectTimeContainer {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      row-gap: 1rem;
      column-gap: 1rem;
      margin-bottom: 1rem;

      &.selectTimeContainerInModal {
        display: flex;
      }

      .selectTime {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        max-width: 140px;

        .selectHour,
        .selectMinute {
          border: 2px solid var(--forest-green);
          border-radius: 7px;
          width: 100%;
        }

        .hour,
        .minute {
          padding: 0.5rem 1rem;
        }

        .time {
          color: var(--forest-green);
          white-space: pre;
          display: flex;
          justify-content: center;

          .navigation {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }

      .selectMeridien {
        display: flex;
        background-color: var(--very-light-grey);
        border-radius: 7px;
        justify-content: space-between;
        align-items: center;
        color: var(--white);
        padding: 3px;
        width: 100%;
        max-width: 120px;

        .meridien {
          padding: 0.5rem 1rem;
        }

        .activeMeridien {
          background-color: var(--forest-green);
          border-radius: 7px;
        }
      }
    }

    .confirmButton {
      margin-left: auto;

      &.confirmButtonInModal {
        margin-bottom: 0.5rem;
      }
    }
  }

  .applyClearDatesFooter {
    padding: 0.5rem 1rem;
    border-top: 1px solid var(--alto);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.outOfBoundContainer {
  position: relative;
}

.timeSeparator {
  margin: 0 0.4rem;
}

.timeModalContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .hourModal {
    display: none;
    position: absolute;
    background-color: var(--white);
    top: 3rem;
    left: 0;
    width: 100%;

    &.isOpen {
      border: 2px solid var(--forest-green);
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      max-height: 130px;
      overflow-y: scroll;
      z-index: 2;
    }

    .timeValue {
      padding: 1rem;
      margin: 0;
      text-align: center;
    }

    @include mini-scrollbar;
  }
}
