@import 'mixins';

.sidebar {
  flex: 0 0 var(--sidebar-size);
  background: var(--white);
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  padding: 1.25rem;
  overflow-x: hidden;

  &.open {
    display: block;
    overflow-y: scroll;
  }

  @include for-desktop {
    display: block;
    position: unset;
    z-index: unset;
  }
}

.content {
  position: relative;
  flex: 1;
  margin: 0 var(--applayout-spacing);
  margin-bottom: 2rem;

  @include for-desktop {
    padding: 0 1.625rem;
  }

  &Wrapper {
    display: flex;
    flex-flow: column;
    min-height: 100vh;

    @include for-desktop {
      flex-flow: row;
    }
  }
}

.button {
  @include for-desktop {
    display: none;
  }
  &.toggleClose {
    display: block;
    margin: 0;
    margin-left: auto;

    @include for-desktop {
      display: none;
    }
  }
}

.stagingLabel {
  padding: 0.5rem;
  margin-top: 1rem;
  text-align: center;
}

.menuDivider {
  margin-bottom: 3.5625rem;

  &.smallDivider {
    //when staging label is visible
    margin-bottom: 1.5rem;
  }
}

.outletTitle {
  display: block;
  color: var(--text-light-gray);
  font-weight: 300;
  font-size: 0.875rem;
  margin: 0;
}

.outletNeighbourhoodSlug {
  display: block;
  color: var(--primary);
  font-weight: 300;
  font-size: 0.875rem;
  margin: 0;
}

.businessTitle {
  display: block;
  color: var(--fiord);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.0625rem;
  text-align: left;
  margin: 2rem 0 0.8125rem;
}

.mobileNav {
  @include for-desktop {
    display: none;
  }

  margin-top: 1rem;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  z-index: 10;
  background: var(--white);
  box-shadow: 1px -2px 7px 0px #0000002b;

  a {
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: block;
    text-align: center;

    span {
      display: block;
      margin: 0 auto 0.3rem;
    }

    &.activeTab {
      background: var(--aqua-spring);
      color: var(--apple-green);
      --icon-color: var(--apple-green);
    }
  }
}
