@import './mixins';

.Wrapper {
  display: flex;
  flex-flow: column;
  padding-bottom: var(--space);
  border-bottom: 1px solid var(--light-gray);
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;

  @include for-desktop {
    margin-bottom: 2.5rem;
    margin-top: 3.0625rem;
  }
}

.Title,
.Subtitle {
  margin: 0;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.Title {
  font-weight: normal;
  font-size: var(--heading-font);
  margin-right: 1rem;
}

.Subtitle {
  color: var(--gray);
  font-size: 0.9375rem;
  margin-top: 0.6875rem;
}

.divide {
  display: grid;
  grid-template-columns: 1fr auto;

  & > h2 {
    grid-row: 1;
  }

  & > p {
    grid-row: 2;
  }

  & > h2,
  & > p {
    grid-column: 1;
  }

  & > p + * {
    grid-column: 2;
    grid-row: 1;
  }
}
