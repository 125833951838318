@import 'variables';

.container {
  display: flex;
  flex-flow: column;

  .icon {
    display: flex;
    justify-content: center;

    & > img {
      justify-content: center;
      object-fit: contain;
    }
  }
  .text {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;

    .title {
      margin-bottom: 0.5rem;
      color: var(--charcoal-gray);
    }
    .message {
      color: var(--charcoal-gray);
    }
  }
}
