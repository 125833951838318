.postLimitBanner {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  border-radius: 10px;
  margin-bottom: 1rem;

  .bannerIcon {
    margin-right: 0.5rem;
  }

  .bannerText {
    font-weight: 400;
  }
}

.postInfo {
  background: var(--alice-blue);
  color: var(--pelorous);
  white-space: pre-wrap;
}

.postWarning {
  background: var(--bridesmaid);
  color: var(--persian-red);
}

.noMarginBottom {
  margin-bottom: 0;
}
