@import 'mixins';

.eCard {
  background: var(
    --background-color,
    url('~assets/images/ecards/ecard_base.svg')
  );
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 19rem;
  min-width: 18rem;
  height: 9rem;
  padding: 1rem;
  margin: auto;
  display: grid;
  row-gap: 0.5rem;
  justify-content: space-between;
  align-items: start;
  border: 1px solid var(--gainsboro);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  text-align: left;

  @include for-mobile {
    max-width: unset;
  }

  h3,
  p {
    margin: 0;
  }

  .companyLogo {
    height: 2.75rem;
    border-radius: 0.5rem;
  }

  .companyName {
    color: var(--text-color, var(--charcoal-gray));
    font-weight: 500;
    font-size: 1.125rem;
    grid-column: span 2;
  }

  .value {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.25rem 0.625rem;
    color: var(--text-color, var(--true-white));
    border: 1px solid var(--text-color, var(--true-white));
    border-radius: 0.5rem;
    display: inline-block;
    grid-row: 1 / 2;
    grid-column: 2/3;
    justify-self: end;
  }

  .price {
    grid-column: span 2;
    color: var(--text-color, var(--forest-green));
    font-size: 0.875rem;
    font-weight: 500;
  }

  .originalPrice {
    margin: 0 0.5rem;
    color: var(--charcoal-gray);
    font-size: 0.6875rem;
    text-decoration: line-through;
  }

  .discount {
    font-size: 0.6875rem;
    color: var(--true-white);
    background: var(--forest-green);
    border-radius: 0.5rem;
    display: inline-block;
    padding: 0.25rem 0.625rem;
  }

  .success {
    width: 8rem;
    position: absolute;
    right: -3.5rem;
    top: 4rem;

    @media (max-width: 475px) {
      width: 6rem;
      right: -2rem;
      top: 6rem;
    }
  }
}
