// placeholder selectors

%ellipsised-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

%vertical-flex {
  display: flex;
  flex-flow: column;
}

%form-input {
  padding: 0.6rem;
  border-radius: 0.4rem;
  color: var(--light-black);
  background-color: inherit;
  border: 0.05rem solid var(--very-light-grey);
  resize: none;
  outline: none;

  &:focus {
    border-color: var(--forest-green);
  }

  &::placeholder {
    color: var(--very-light-grey);
  }
}
