.card {
  display: flex;
  flex-direction: column;
  padding-top: 4.25rem;
}

.heroLayout {
  padding-bottom: 2.5rem;
  border-bottom: 1px solid var(--very-light-grey);

  .heroImage {
    justify-self: center;
  }
  .heroTextLayout {
    display: flex;
    flex-direction: column;

    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: var(--charcoal-gray);
      margin: 0 0 1rem;
    }
    .paragraph {
      line-height: 1.63;
      color: var(--dark-gray);
      margin: 0 0 2rem;
    }
    .subheader {
      font-weight: 500;
      margin: 0 0 1rem;
      color: var(--dark-gray);
    }
    .kitDetails {
      margin: 0;
      padding-left: 1rem;
      color: var(--dark-gray);
      > li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.deliveryLayout {
  margin-top: 2.5rem;
}

.recipientLayout {
  margin-top: 3rem;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 5rem;
}
