@import 'mixins';

.switcherContainer {
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  position: relative;
  max-width: 100%;

  &.modalMode {
    z-index: 20;
    background: var(--true-white);
  }

  .switcherButton {
    height: 4.0625rem;
    padding: 0 1.1875rem;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
  }

  .switcherButtonWithCaretDown {
    @extend .switcherButton;

    @include caret-down($color: var(--primary));
  }
}

.outletCard {
  position: absolute;
  top: 89%;
  z-index: 1;
  padding: 0;
  background-color: var(--white);
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: none;
  &.visible {
    display: block;
  }

  .outletButton {
    display: flex;
    justify-content: space-between;
    padding: 1.75rem 1.1875rem;
  }

  .outletButtonDisabled {
    opacity: 0.5;
  }
}

.outletTitle {
  color: var(--dark-gray);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
  text-align: left;
  margin: 1.125rem;
  display: block;
  user-select: none;
}

.switcherTitle {
  color: var(--black);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.0625rem;
  @include truncate;
}

.switcherMapleOutletName {
  color: var(--black);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.0625rem;
  @include clamp-on-line(1);
}

.switcherMapleOutletNeighbourhoodSlug {
  color: var(--primary);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.0625rem;
  @include clamp-on-line(1);
}

.switcherMapleTitle {
  @include truncate;
}

.outletName {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--light-black);
  max-height: 100%;
  @include clamp-on-line;
}

.mapleOutletName {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--light-black);
  max-height: 100%;
  @include clamp-on-line(1);
}

.mapleOutletNeighbourhoodSlug {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--primary);
  max-height: 100%;
  @include clamp-on-line(1);
}

.button {
  .outletButtonWrapper {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    width: 90%;
    height: inherit;
  }
}

.outletImage {
  margin-right: 0.75rem;
  min-width: 2.25rem;
  min-height: 2.25rem;
  border-radius: 50%;
  border: var(--border-solid-light-gray);
  background: var(--bg, '') center/contain;
}

.outletCheckIcon {
  margin-left: auto;
}
