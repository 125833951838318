@import 'placeholders';

.stepsContainerLite {
  cursor: default;
  @extend %vertical-flex;

  .stepSegement {
    display: flex;

    .stepItem {
      flex: 1;
      color: var(--text-disabled-gray);
      overflow: hidden;

      .stepBar {
        border-radius: 1rem;
        height: 0.6rem;
        background-color: var(--white-smoke);
      }
      .title {
        @extend %ellipsised-text;
        font-size: medium;
        white-space: nowrap;
        font-weight: 500;
        margin-top: 0.4rem;
        display: block;
      }
      .subtitle {
        @extend %ellipsised-text;
        font-size: small;
        white-space: nowrap;
        font-weight: 500;
        margin-top: 1rem;
        display: block;
      }
    }

    .activeItem {
      .stepBar {
        background-color: var(--forest-green);
      }
      color: var(--forest-green);
    }

    .stepItem:not(:last-child) {
      margin-right: 0.8rem;
    }
  }
}
