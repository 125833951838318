.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: wrap;

  .method {
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--bali-hai);
    margin: 0;
  }

  .valueSection {
    display: flex;
    justify-content: space-between;

    .value {
      margin: 0.5rem 0 1.5rem 0;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: var(--charcoal-gray);
    }
  }

  > label {
    margin-bottom: 1.25rem;
    flex: unset;
  }

  .button {
    &.forgotPIN {
      color: var(--link-color);
      align-self: center;
      margin-bottom: 2rem;
    }
  }

  .submitButtonContainer {
    margin-top: auto;
  }

  input[type='tel'] {
    font-family: 'text-security-disc';
    -webkit-text-security: disc;
    &::placeholder {
      font-family: 'Rubik';
    }
  }
}
