.container {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.replyForm {
  margin-top: auto;

  &.marginUnset {
    margin-top: 0;
  }

  .replyInput {
    min-height: 6rem;
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;

    :first-child {
      margin-right: 1rem;
    }
  }
}
