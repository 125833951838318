@import 'mixins';

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14.7rem, 1fr));
  gap: var(--row-gap) var(--column-gap);

  @include for-desktop {
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--col-min-width, 100%), 1fr)
    );
  }
}
