div.emptyListBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;

  .img {
    background-size: unset;
  }

  .noOutletText {
    color: var(--text-light-gray);
  }
}
