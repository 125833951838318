.telWrapperContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.telWrapper {
  display: flex;

  .telCode {
    min-width: 10.3125rem;
  }

  &.compact {
    .telCode {
      min-width: 6rem;
    }
  }

  .telNumber {
    margin-left: 0.375rem;
  }

  .error {
    color: var(--red);
    margin: 0.5rem;
    flex: 100%;
  }
}
