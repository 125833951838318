.table {
  overflow: unset;
  border-color: transparent;

  .head {
    border-bottom: 0.12rem solid var(--bali-hai);
    color: var(--bali-hai);
    font-size: 0.8rem;
  }

  .body {
    font-size: 0.8rem;
  }

  .row {
    &.groupRow {
      border-bottom: 0.05rem solid var(--bali-hai);
    }
  }

  /*using important because original css targets all th, td*/
  .headerCell,
  .bodyCell {
    border-right-color: transparent !important;

    &.textLeft {
      text-align: left !important;
    }

    &.textCenter:not(:first-child) {
      text-align: center !important;
    }

    &.textRight:not(:first-child) {
      text-align: right !important;
    }
  }

  .headerCell {
    font-weight: normal;
  }

  .bodyCell {
    vertical-align: top;
  }
}
