@import './mixins';

.dateFilter {
  label {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  margin-bottom: 2rem;
}

.noDealsCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.9375rem 2rem 2.125rem;

  .buttonSection {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .request {
      margin: 1rem 0 0 0;
      background: var(--white);
    }

    @include for-desktop {
      flex-direction: row;

      .request {
        margin: 0 0 0 1rem;
      }
    }
  }

  > p {
    text-align: center;
    margin-top: 0.875rem;
    margin-bottom: 3.25rem;
  }
}

.newDealButton {
  margin: 2rem 0 0 auto;
  position: sticky;
  bottom: 1rem;
  width: 100%;
  justify-content: center;

  @include for-desktop {
    width: auto;
  }
}
