@import '../mixins';

.layout {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.actionsBelowCTA {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: var(--charcoal-gray);
  letter-spacing: 0.004375rem;
}

.actionsBelowCTA,
.nextButton {
  width: 100%;

  @include for-desktop {
    max-width: 25rem;
  }
}
