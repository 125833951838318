@import 'placeholders';
@import 'mixins';
@import 'Carousel.module.scss';

$logo-size: 2rem;
$image-radius: 0.6rem;

.cardWrapper {
  @extend %vertical-flex;
  cursor: default;
  align-items: center;
  align-self: start;
  margin-bottom: 1rem;

  &.reversed {
    flex-direction: column-reverse;

    .hintText {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  &.centered {
    align-self: center;
  }

  @include for-mobile {
    margin-top: 1rem;
  }

  @media (max-width: 411px) {
    width: 100%;
  }

  @media (min-width: 412px) {
    width: 21rem;
  }

  .postCard {
    @extend %vertical-flex;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.1);
    width: 100%;
    background-color: var(--true-white);
    font-family: 'Nunito';

    .header {
      display: flex;
      margin-bottom: 1rem;
      overflow: hidden;

      .logo {
        height: $logo-size;
        width: $logo-size;
        border-radius: 24%;
        margin-right: 0.5rem;
        flex-shrink: 0;
      }

      .headerContent {
        @extend %vertical-flex;
        flex: 1;
        overflow: hidden;

        .title {
          color: var(--text-dark-gray);
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .subtitle {
          color: var(--text-light-gray);
          font-size: small;
        }
      }
    }

    .description {
      color: var(--text-dark-grey);
      overflow-wrap: break-word; //fix for safari
      white-space: pre-wrap;
    }

    .placeholder {
      color: var(--very-light-grey);
    }
  }

  .forceMobile {
    width: 100%;
  }

  .hintText {
    width: 70%;
    color: var(--bali-hai);
    text-align: center;
    margin-top: 1rem;
    font-size: small;
  }
}

.image {
  background-color: var(--white-smoke);
  margin-top: 1rem;
  align-self: center;
  width: 100%;
  border-radius: $image-radius;
  box-shadow: 0 0 0 1px var(--whisper-white);
  overflow: hidden;
  will-change: transform; // fix for iOS border radius disappear on swipe

  .carouselImage {
    border-radius: $image-radius;
  }
}

.postSummary {
  display: flex;
  background-color: var(--alpha-black);
  color: var(--true-white);
  align-items: center;
  padding: 0.8rem 0;
  backdrop-filter: blur(100px);

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    margin-left: 1rem;
  }

  .chvron {
    margin-left: 0.5rem;
    margin-right: 0.8rem;
  }
}
