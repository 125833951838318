@import 'placeholders';
@import 'mixins';

.postForm {
  display: flex;
  margin-top: 3rem;
  height: 100%;

  .datePicker {
    width: unset;
    color: var(--black);
    border-radius: 0.4rem;
  }

  .formLabel {
    color: var(--text-dark-gray);
    margin: 0;

    &.marginTop {
      margin-top: 1rem;
    }
  }

  .formCaption {
    color: var(--text-light-gray);
    margin-top: 0.3rem;
    font-size: 0.9rem;
  }

  .postPreview {
    @extend %vertical-flex;
    flex: 1;
    align-items: center;

    @include for-desktop {
      padding: 0 1rem 0 0;
    }
  }

  .postDetails {
    flex: 1;
    display: flex;
    flex-flow: column;
    padding: 0 0.1rem;
    width: 100%;

    .postImages {
      margin: 1rem 0;
    }

    .postDescription {
      margin: 1rem 0;
    }

    .outletPicker {
      margin-bottom: 0.5rem;
      background-color: var(--true-white);
    }

    .noOutletsFound {
      cursor: not-allowed;
    }
  }
}

.viewOutletsModalContainer {
  @include for-desktop {
    width: 30rem;
  }
}

.modalTitle {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--charcoal-gray);
}

.modalContent {
  margin-bottom: 1.5rem;
  text-align: center;
}
