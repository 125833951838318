@import 'mixins';

.alert {
  padding: 1.5rem 1.3rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;

  @include for-mobile {
    padding: 1.5rem 1rem;
    font-size: 0.75rem;
  }
}

.alertInfo {
  color: var(--pelorous);
  background: var(--alice-blue);
}
