@import 'mixins';
$max-image-post-container-height: 219px;

.postSubmittedModal,
.featureLockedModal {
  .title {
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--charcoal-gray);
    margin-bottom: 0;
  }

  .content {
    text-align: center;
    color: var(--charcoal-gray);
  }
}

.feedPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    text-align: center;
  }

  .noPostsFound {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .noActivePosts,
  .noScheduledPosts,
  .noInactivePosts {
    height: 10rem;
    width: 10rem;
    background-repeat: no-repeat;
    background-position: center;
  }

  .noActivePosts {
    background-image: url('../images/FaveFeed/active-post-empty-state.svg');
  }

  .noScheduledPosts {
    background-image: url('../images/FaveFeed/scheduled-post-empty-state.svg');
  }

  .noInactivePosts {
    background-image: url('../images/FaveFeed/inactive-post-empty-state.svg');
  }

  .statement {
    text-align: center;
    max-width: 18rem;
    color: var(--charcoal-gray);
    margin: 0 0 1rem 0;
  }

  .buttonsContainer {
    &.webview {
      display: flex;
      justify-content: space-between;
      column-gap: 1rem;
      position: fixed;
      bottom: 1rem;
      width: 100%;
      padding: 0 1rem;
    }

    &:not(.webview) {
      row-gap: 1rem;
      column-gap: 1rem;
      display: -ms-flexbox;
      display: flex;
      margin: 0;
      padding: 0;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .feedContainer {
    width: 100%;
    position: relative;

    .post {
      border: 1px solid var(--white-smoke);
      border-radius: 16px;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      .postID {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 49px;
        padding: 0 1rem;
        font-size: 0.875rem;
      }

      .activePostID {
        background: var(--aqua-spring);
        color: #2da92f;
      }

      .nonActivePostID {
        background: var(--white-smoke);
        color: var(--bali-hai);
      }

      .postPicture {
        min-height: $max-image-post-container-height;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        color: var(--bali-hai);
        font-size: 0.75rem;
        overflow: hidden;

        .postEcard {
          border-radius: 12px;
          width: 90%;
          margin: 1rem 0;
        }

        > img {
          margin: 0.5rem;
        }
      }

      .noImage {
        min-height: $max-image-post-container-height;
        background-color: var(--white-smoke);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--bali-hai);
        font-size: 0.75rem;
      }

      .postContent {
        padding: 1rem;
        display: grid;
        grid-template-rows: 1.5rem 2.8rem 1rem 4rem;

        p {
          margin-top: 0;
        }

        hr {
          height: 1px;
          width: 100%;
          background-color: var(--white-smoke);
          border: none;
        }

        .postCreated {
          font-size: 0.85rem;
          color: var(--bali-hai);
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
        }

        .postDetails {
          color: var(--charcoal-gray);
          margin-bottom: 0;
          height: fit-content;
          word-break: break-word;
          @include clamp-on-line;
        }

        .postActive {
          display: grid;
          grid-template-columns: auto auto 2rem;
          column-gap: 1rem;
          align-items: center;

          .count {
            font-weight: 500;
            margin-bottom: 0.5rem;
          }
          .title {
            font-size: 0.8rem;
            white-space: nowrap;
            color: var(--bali-hai);
          }
        }

        .postScheduled {
          display: grid;
          grid-template-columns: auto 2rem;
          column-gap: 0.5rem;
          color: var(--bali-hai);
          font-size: 0.85rem;
          align-items: center;

          p {
            margin-bottom: 0;
          }
        }

        .postInactive {
          display: flex;
        }

        .viewPostDetails {
          align-self: unset;
        }
      }
    }
  }
}

.webviewLayout {
  overflow-y: scroll;
  height: 100vh;
}

.createPostButtonContainer {
  position: fixed;
  bottom: 1rem;
  width: 90%;
}

.bold {
  font-weight: 500;
}
