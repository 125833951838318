@import 'mixins';

.card {
  padding: var(--space);
  margin: var(--space) 0;

  .notification {
    padding: 0.75rem;
    border-radius: 6px;
    margin-bottom: var(--space);
    display: flex;
    align-items: baseline;

    & > p::first-letter {
      text-transform: capitalize;
    }

    &.paid {
      color: var(--apple-green);
      background: var(--aqua-spring);

      &::before {
        content: '';
        @include checkmark;
      }
    }

    &.cancel {
      background: var(--we-peep);
      color: var(--wild-watermelon);

      &::before {
        content: '✕';
        vertical-align: middle;
      }
    }

    p {
      margin: 0;
      margin-left: 0.75rem;
      display: inline-block;
    }
  }

  dl {
    margin: 0;
    dt {
      color: var(--bermuda-grey);
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.1875rem;
    }

    dd {
      overflow-wrap: break-word;
      margin: 0 0 0.75rem;
      padding-bottom: 0.75rem;
      color: var(--text-dark-gray);
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.625rem;

      &:not(:last-child) {
        border-bottom: 1px solid var(--light-gray);
      }
      .paidVia {
        color: var(--bermuda-grey);
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        padding-left: 0.35rem;
      }
    }
  }

  .additionalDetails {
    margin: 0;
  }
}

.buttonContainer {
  @include for-desktop {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 330px));
    grid-gap: 1rem;
    justify-content: flex-end;
  }
}

.button.orderDetails {
  background: unset;
  border: 1px solid var(--apple-green);
  color: var(--apple-green);
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 1rem;

  @include for-desktop {
    margin-bottom: 0;
  }
}

.button.cancel,
.button.orderDetails {
  width: 100%;
}

.cancellationCodeModal {
  text-align: center;
  padding: 1.8125rem;

  h3 {
    font-weight: 500;
    font-size: 1rem;
    color: var(--fiord);
    margin: 0;
  }

  p {
    font-size: 0.875rem;
    color: var(--fiord);
  }

  input {
    margin-bottom: 0.625rem;

    & + div {
      align-self: start;
    }
  }

  .button.primary {
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 1.125rem;
  }

  .button.cancel {
    font-size: 1rem;
  }
}
