@import 'mixins';

.overlay {
  background: var(--white);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .switchOutletCard {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1.25rem 1.6875rem;

    @include for-desktop {
      padding: 2.25rem 2.6875rem;
      width: unset;
    }
  }

  .title {
    color: var(--dark-gray);
    font-weight: 500;
    font-size: 1rem;
    margin: 0 3rem 2rem 0;

    @include for-desktop {
      margin: 0 4rem 2rem 0;
    }
  }

  .companyImage {
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 0.75rem;
    border-radius: 50%;
    border: 1px solid var(--light-gray);
  }
}

.outlets {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 70vh;
  overflow-x: auto;
  max-width: 50vh;

  @include for-desktop {
    max-width: 100vh;
  }
  
  @include for-mobile {
    max-width: 40vh;
  }
}

.loadingSpinnerWrapper {
  position: relative;
  padding: 3rem 0;
}

.button.outletButton {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.outletCode {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-top: 0.3rem;
  color: var(--dark-gray);
  font-weight: 500;
}

.outletNeighbourhoodSlug {
  color: var(--primary)
}