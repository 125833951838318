.container {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
}

.card {
  margin: 0;
  box-shadow: unset;
}

.cardPerformance {
  display: flex;
  margin: 0.75rem 0.5rem 0;
  padding: 0 0.75rem 0.75rem;
  border-bottom: 1px solid var(--very-light-grey);
}

.cardItem {
  flex: 1;
}

.cardItemTitle {
  font-family: Rubik;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: var(--text-light-gray);
}

.cardItemValue {
  font-family: Rubik;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: var(--light-black);
}

.button {
  height: 2.566rem;
  margin-left: 0.875rem;

  font-family: Rubik;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;
}
