.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  text-align: center;
}

.heading {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--charcoal-gray);
  margin: 0.75rem 0 1rem;
}

.paragraph {
  margin-bottom: 2rem;
  color: var(--text-light-gray);
  font-size: 1.125rem;
}

.image {
  max-width: 16.375rem;
  width: 100%;
}
