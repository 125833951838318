/* wrapper to contain select and custom arrow icon */
.selectWrapper {
  position: relative;
  align-self: flex-start;

  &::after {
    content: '';
    position: absolute;
    right: 1.25rem;
    width: 0.625rem;
    height: 0.625rem;
    border-bottom: 0.125rem solid;
    border-right: 0.125rem solid;
    color: var(--apple-green);
    top: calc(1.25rem - 0.125rem);
    transform: rotate(45deg);
  }
}

.select {
  appearance: none;
  /* hide default arrow icon - https://stackoverflow.com/a/20464860 */
  outline: none;
  height: 3.125rem;
  width: 100%;
  line-height: 1;
  padding: 0 2.75rem 0 1.25rem;
  border-radius: 6px;
  border: 1px solid var(--very-light-grey);
  color: var(--charcoal-gray);
  background-color: var(--true-white);
  font-size: 1.125rem;

  &:hover {
    cursor: pointer;
  }
}
