@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-display: swap; /* avoid Flash of Invisible Text (FOIT) */
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap; /* avoid Flash of Invisible Text (FOIT) */
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap; /* avoid Flash of Invisible Text (FOIT) */
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-display: swap; /* avoid Flash of Invisible Text (FOIT) */
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-display: swap; /* avoid Flash of Invisible Text (FOIT) */
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap; /* avoid Flash of Invisible Text (FOIT) */
}

@font-face {
  font-family: 'text-security-disc';
  src: url('../fonts/text-security-disc.eot');
  src: url('../fonts/text-security-disc.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text-security-disc.woff2') format('woff2'),
    url('../fonts/text-security-disc.woff') format('woff'),
    url('../fonts/text-security-disc.ttf') format('truetype'),
    url('../fonts/text-security-disc.svg#text-security') format('svg');
}
