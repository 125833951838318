.form {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-top: 1rem;

  .radioLabel {
    text-align: center;
  }

  > label {
    margin-bottom: 1.25rem;
  }

  > button {
    margin-top: 1rem;
  }
}

.telWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 0.375rem;

  .telNumber {
    grid-column: span 3;
  }

  .telCode {
    grid-column: span 2;

    &:checked {
      color: var(--dark-black);
    }
  }

  .error {
    color: var(--red);
    margin: 0.5rem;
    flex: 100%;
  }
}

.loginMethod {
  display: flex;
  margin-bottom: 1.65625rem;

  > label {
    flex: 50%;
    position: relative;
    padding-top: 0.71875rem;
    padding-bottom: 0.71875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--apple-green);
    transition: all ease 0.3s;
    border-bottom: 2px solid transparent;

    &.active {
      border-bottom-color: var(--apple-green);
    }

    &.inactive {
      color: var(--gray);
    }

    &:hover {
      cursor: pointer;
      background-color: var(--light-gray);
    }

    > input {
      position: absolute;
      visibility: hidden;
    }

    > [data-icon] {
      margin-bottom: 1rem;
    }
  }
}

.inviteCode {
  display: flex;
  justify-content: center;

  > button {
    margin-top: -0.22rem;
  }
}

.tnc {
  text-align: center;
  margin-top: 0.5rem;

  a {
    color: var(--link-color);
    text-decoration: none;
  }
}

.footerSection {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: flex-end;
}
