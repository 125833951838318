@import './mixins';

.cardLayout {
  padding: 0;
  margin: 0;
  min-height: 18.75rem;
  overflow: hidden;
  position: relative;
  background: var(--true-white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .body {
    justify-content: space-between;
    flex-grow: 1;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
  }

  .notice {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(74, 74, 74, 0.6);
    z-index: 1;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--white);

    .lockedIcon {
      margin-bottom: 0.5rem;
    }

    .unlockParagraph {
      margin: 0.3rem;
    }
  }
}

.imageLayout {
  width: 100%;
  height: 8.625rem;
  position: relative;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.title {
  font-size: 1rem;
  margin: 0.5rem 0;
  font-weight: 500;
  color: var(--text-dark-gray);
}

.description {
  font-size: 0.875rem;
  color: var(--light-black);
}

%button-layout {
  display: flex;
  margin-bottom: 0.875rem;
  margin-top: 1.25rem;
  justify-content: space-around;
}

.buttonLayout {
  @extend %button-layout;
  flex-wrap: wrap-reverse;

  @include for-desktop {
    flex-wrap: nowrap;
  }
}

//for specific laguages
.buttonLayoutVertical {
  @extend %button-layout;
  flex-wrap: wrap-reverse;

  .ctaButton {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
}

.button {
  &.exploreButton {
    font-size: 1rem;
    border-radius: 5px;
    font-weight: 500;
    line-height: 1.0625rem;
    margin-bottom: 0.5rem;
    flex-basis: 100%;
    text-align: right;
    justify-content: center;
  }
}
.ctaButton {
  justify-content: center;
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  @include for-desktop {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0.5rem;
  }

  line-height: 1.0625rem;
  flex-basis: 100%;
  text-align: right;
}
