@import 'mixins';

.dropdown {
  position: relative;
  display: flex;
  flex-flow: column;
  align-self: center;
}

.toggle {
  padding: 0.5rem 2.5rem 0.5rem 0.8rem;
  cursor: pointer;
  transition: all ease 0.3s;
  position: relative;
  user-select: none;

  @include caret-down(var(--apple-green));

  &:hover:not([disabled]) {
    background-color: var(--light-gray);
  }
}

.content {
  display: none;
  position: absolute;
  top: 100%;
  min-width: 100%;
  right: 0;
  z-index: 10;

  margin-top: var(--half-space);
  border-radius: var(--border-radius);
  overflow: hidden;

  background-color: var(--white);
  padding: var(--half-space);
  box-shadow: var(--menu-shadow);

  &.isOpen {
    display: block;
  }
}

.dropdownItems {
  display: flex;
  flex-flow: column;

  .separator {
    margin: 0 1.1rem;
  }

  button,
  a {
    &.button {
      width: 100%;
      display: flex;

      &.dropdownItem {
        padding: 1rem 1.25rem;
      }

      &[aria-current='page'] {
        font-weight: bold;
      }
    }
  }
}
