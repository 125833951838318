@import 'mixins';

:root {
  --card-inner-padding: 1.875rem;
}

.revenue {
  grid-column-end: span 1;

  @include for-desktop {
    grid-column-end: span 2;
  }
}

.dashboardGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.375rem;
  margin-bottom: 1rem;

  @include for-desktop {
    // Chrome 80 handles 1fr differently: https://stackoverflow.com/questions/60193734/grid-template-columns-in-chrome-80-inconsistently-computed
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .whatsNew {
    grid-column-end: span 1;

    @include for-desktop {
      grid-column-end: span 2;
    }
  }
}

.revenueSection {
  display: flex;
  margin: 1.375rem var(--card-inner-padding) 0;
  flex-flow: row wrap;
  justify-content: space-between;

  @include for-desktop {
    flex-direction: column;
  }

  > div > span {
    color: var(--grayish-blue);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1875rem;
    text-align: left;
  }

  > div {
    > p {
      color: var(--light-black);
      font-size: 1.625rem;
      font-weight: 500;
      line-height: 1.625rem;
      text-align: left;
      margin-top: 0.4375rem;
      margin-bottom: 0;
    }
    &:not(:last-of-type) > p {
      margin-bottom: 1.5rem;
    }
  }

  button {
    flex: 100%;
    margin: 1rem 0 2rem;
  }
}

.revenueSectionEmpty {
  height: 60%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem 1rem;

  .revenueSectionEmptyTitle {
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--grayish-blue);
    margin: 1rem;
  }

  .revenueSectionEmptyButton {
    white-space: normal;
    padding: 0.5rem 1rem;
    height: unset;

    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 0;
    color: var(--true-white);
    font-size: 1rem;
  }
}

.dashboardSlider {
  @include for-desktop {
    max-width: calc(var(--max-width) - var(--sidebar-size));
  }
}

.dashboardIcon {
  margin: 0 var(--half-space);
}

.dashboardRecentReview {
  @extend .outletsSection;
}

.dashboardReview {
  background-color: var(--white);
  border-radius: 0.625rem;
  margin-top: 1.75rem;
  margin-bottom: 2.5rem;
  height: 19.875rem;
  padding: 0.9375rem 1.563rem;
  overflow: hidden;

  .replyButton {
    background-color: transparent;
    margin-left: auto;
  }
}

.star {
  display: flex;
  justify-content: flex-end;
}

.topSection {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .name {
    color: var(--light-black);
    font-size: 1rem;
    font-weight: 500;
  }

  .date {
    color: var(--dark-gray);
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.comment {
  font-size: 1rem;
  line-height: 1.38;
  letter-spacing: 0.08px;
  color: var(--text-light-gray);
  margin: var(--space) 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
}

.replyComment {
  .replyTitle {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
  }

  .replyContent {
    font-size: 1rem;
    line-height: 1.38;
    letter-spacing: 0.08px;
    color: var(--text-dark-gray);
    margin: var(--space) 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
  }
}

.outletsSection {
  background-color: var(--white-smoke);
  @include for-desktop {
    grid-column: span 2;
  }

  [data-top-section] {
    background-color: var(--true-white);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  [data-top-children] {
    color: var(--bali-hai);
    font-size: 0.875rem;
  }
  [data-layout] {
    padding: 1.75rem 1.625rem;
    max-height: 24rem;
    overflow-y: auto;
    margin-bottom: 0.1rem;

    [data-outlet] {
      background-color: var(--true-white);
      border-radius: 10px;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.3125rem;
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
      > * {
        padding: 1rem;
      }
      [data-address] {
        display: grid;
        grid-template-columns: 1fr 7.3rem;
        border-bottom: 1px solid var(--very-light-grey);
        > span {
          @include truncate;
          margin-right: 1rem;
          > div {
            @include truncate;
          }
          .neighbourhoodSlug {
            color: var(--primary)
          }
        }
        > button {
          font-size: 1rem;
        }
      }
      [data-cta] {
        font-size: 1rem;
      }
    }
  }
}

.lastReviewSlider {
  @extend .dashboardReview;
  background-color: inherit;
  display: flex !important; // only way to override slick slider injected style;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  > p {
    color: var(--text-dark-gray);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1875rem;
    text-align: center;
  }

  .viewAll {
    display: flex;
    align-items: center;

    > [data-icon] {
      margin-left: 0.625rem;
    }
  }
}

.rootNoOverflow {
  @include for-mobile {
    overflow: hidden;
  }
  @include for-desktop {
    overflow: unset;
  }
}

.newDashboardBanner {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  height: 150px;
  background-image: url('../images/new-dashboard-banner.png');
  background-size: cover;
  background-position: center;

  .imageBackground {
    width: 100%;
    border-radius: 0.5rem;
  }

  .content {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .textAndButton {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .textContent {
        max-width: 300px;
      }
    }
  }

  .closeNewDashboardBanner {
    cursor: pointer;
  }
}
