@import 'variables';

.carousel {
  overflow: hidden;
  position: relative;

  $transition-time: 0.3s;
  $dot-size: 0.4rem;

  .navButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    background-color: inherit;
    transition: all $transition-time;

    &.navPrev {
      left: 0;
    }

    &.navNext {
      right: 0;
    }
  }

  .swiper {
    transition: max-height $transition-time, transform $transition-time;
    display: flex;
    max-height: fit-content;

    .carouselItem {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      // for safari
      height: 100%;

      &.fitAspect > img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .bottomPane {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .indicators {
      display: flex;
      justify-content: center;
      margin-bottom: 0.5rem;

      & > *:not(:last-child) {
        margin-right: 0.6rem;
      }

      & > .dot {
        cursor: pointer;
        width: $dot-size;
        height: $dot-size;
        border-radius: 50%;
        background-color: var(--whisper-white);
        transform: matrix(1, 0, 0, -1, 0, 0);
        transition: width $transition-time, border-radius $transition-time;
      }

      .activeDot {
        cursor: default;
        width: 1.5rem;
        border-radius: 0.5rem; //fixed: by design
        background-color: $primary;
      }
    }
  }
}
