@import 'mixins';

.topbar {
  background: var(--white);
  border-bottom: 1px solid var(--gray-light);
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 0.125rem 0.375rem 0.125rem rgba(0, 0, 0, 0.09);

  .brandLogo {
    display: none;

    @include for-desktop {
      display: block;
    }
  }

  .dropdownButton {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    &::after {
      color: var(--primary);
    }
  }

  .button {
    &:hover:not([disabled]) {
      color: var(--dark-gray);
      background-color: transparent;
    }

    @include for-desktop {
      display: none;
    }

    &.toggle {
      align-self: center;

      @include for-desktop {
        display: none;
      }
    }
  }

  &Content {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem 0.5rem;
  }

  .profileIcon {
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    border: 1px solid var(--light-gray);
    background: var(--profile-icon, '') center/contain;
  }

  .menuWrapper {
    display: flex;
    align-items: center;
    margin-right: auto;
    max-width: 40vw;

    .mobileOutletName {
      user-select: none;
      visibility: visible;
      margin-left: 0.6875rem;
      color: var(--dark-gray);
      @include truncate;

      @include for-desktop {
        visibility: hidden;
      }
    }
  }

  .staffBadge {
    background: var(--apple-green);
    color: var(--white);
    padding: 0.3rem 0.75rem;
    border-radius: 0.25rem;
    white-space: pre;
    font-size: 0.625rem;
  }
}
