@import './mixins';

.pinViewContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pinField {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.25rem 0.375rem;

  @include for-desktop {
    grid-template-columns: 1fr 1fr;
  }
}

.telWrapper {
  position: relative;

  .checkIcon {
    position: absolute;
    top: 37%;
    right: 5%;
  }

  .error {
    color: var(--red);
    margin: 0.5rem;
    flex: 100%;
  }

  .errorField {
    border: 2px solid var(--red);
    border-radius: 6px;
    flex: 100%;

    > input {
      &:focus {
        border: none;
      }
    }
  }
}

.verifyCode {
  height: inherit;

  > h3 {
    color: var(--dark-gray);
  }

  > h5 {
    color: var(--text-light-gray);
  }
}

.errorPinValue {
  color: var(--red);
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: inherit;
}

.verifyPinSubmit {
  margin-top: 2rem;
  width: 100%;
}
