.button.inverted {
  font-weight: 500;
  justify-content: center;
  color: var(--apple-green);
  border-color: var(--apple-green);
  background-color: var(--true-white);
  &:hover:not([disabled]) {
    background-color: var(--light-gray);
  }
}
