.dashboardCard {
  width: 100%;
  padding: 0;
  margin: 0;

  .dashboardCardTopSection {
    height: 3.75rem;
    padding: 0 var(--card-inner-padding);
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--light-gray);

    .iconAndTitle {
      display: flex;
      align-items: center;
    }

    .dashboardPagingControl {
      display: flex;
    }
  }

  .dashboardCardTitle {
    color: var(--grayish-blue);
    font-size: 1.125rem;
    line-height: 1.3125rem;
    text-align: left;
    margin-left: 0.5625rem;
    max-width: 41vw;
  }

  .dashboardPagingButton {
    margin: 0;

    &:last-child {
      margin-left: 0.5rem;
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;

  .cardButton {
    color: var(--dark-saturated-blue);
    font-size: 0.875rem;
    font-weight: 500;
    margin-right: var(--card-inner-padding);
    margin-bottom: 1rem;
  }
}
