.questionContainer {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  img {
    width: 4rem;
    height: 4rem;
  }

  h2 {
    color: var(--text-dark-gray);
    font-weight: 500;
    font-size: 1.5rem;
  }

  p {
    color: var(--text-dark-gray);
  }

  .buttonsContainer {
    margin-top: auto;

    button,
    a {
      margin: 0.5rem 0;
    }
  }
}
