@import 'mixins';

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .formContent {
    display: flex;
    flex-direction: column;
  }

  > label {
    margin-bottom: 1.25rem;
  }
}

.partnerCode {
  width: fit-content;
  align-self: center;
  justify-content: center;
  margin-bottom: auto;
  margin-top: 1.5rem;
  color: var(--link-color);

  > p {
    margin: 0;
  }
}

.modal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 2.5rem 1.25rem;

  @include for-desktop {
    padding: 3.813rem 4.5rem 3.5rem;
    max-width: 40.063rem;
  }

  p {
    margin: 0;
  }

  .staffIcon {
    margin-bottom: 1.75rem;
  }

  .modalTitle {
    font-family: Rubik;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    color: var(--text-dark-gray);
  }

  .modalDesc {
    font-family: Rubik;
    font-size: 1rem;
    text-align: center;
    color: var(--text-light-gray);
    margin-top: 1rem;
    margin-bottom: 1.875rem;
  }

  .box {
    border-radius: 4px;
    border: solid 1px var(--silver);
    padding: 0.75rem 0.5rem;

    @include for-desktop {
      padding: 0.75rem 1.688rem;
      margin: 0 2.375rem 1.5rem;
    }

    > p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.08px;
      text-align: center;
      color: #8e8e8e;
    }

    p:nth-child(1) {
      font-weight: 500;
    }
  }

  .contactSupport {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);

    a {
      padding-left: 1rem;
    }

    @include for-desktop {
      div:not(:last-of-type),
      a:not(:nth-last-child(-n + 2)) {
        border-bottom: 1px solid var(--silver);
      }
    }

    @include for-mobile {
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      row-gap: 0.75rem;
      justify-items: center;

      div:not(:first-child) {
        width: 100%;
        justify-content: center;
        border-top: 1px solid var(--silver);
        padding-top: 1rem;
      }
    }
  }

  .countryFlag {
    display: flex;
    align-items: center;

    p {
      padding-left: 0.5rem;
    }

    @include for-desktop {
      padding: 1rem 0 1rem 1rem;
    }
  }

  .modalButton {
    color: var(--pelorous);
  }
}
