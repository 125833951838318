@import 'mixins';

.steps {
  --complete-color: var(--charcoal-gray);
  --current-color: var(--primary);
  --incomplete-color: var(--very-light-grey);

  display: flex;
  justify-content: space-evenly;
  width: 100%;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    --step-size: 2.25rem;
    --step-color: var(--incomplete-color);
    &.complete {
      --step-color: var(--complete-color);
    }
    &.current {
      --step-color: var(--current-color);
      font-weight: 500;
    }
    color: var(--step-color);

    .text {
      width: 7.625rem;
      width: 7.9375rem;
      font-size: 1rem;
      line-height: 1.25rem;
      margin-top: 0.5rem;
    }

    /* 
      wrapper to make use of :before :after
      to draw overlapping lines with differing z-index behind step circle,
      wrapped step circle acts as relative positioning reference
    */
    .iconWrapper {
      position: relative;
      display: inline-flex;
      transition: all ease 0.8s;
      &:before,
      &:after {
        content: '';
        position: absolute;
        z-index: var(--step-z-index);
        top: 50%;
        border-top: 1px solid;
      }
      &:before {
        right: calc(var(--step-size) + 0.375rem);
        width: 50vw;
        border-color: var(--step-color);
      }
      &:after {
        right: -0.375rem;
        width: calc(var(--step-size) + (0.375rem * 2));
        border-color: var(--white);
      }
    }

    &:first-child {
      .iconWrapper {
        &:before {
          z-index: -1;
          border-color: var(--white);
        }
      }
    }

    .number,
    .icon {
      background-color: var(--white);
      height: var(--step-size);
      width: var(--step-size);
    }

    .number {
      height: var(--step-size);
      border: 2px solid;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
