@import './mixins';

.financing {
  @include for-desktop {
    max-width: 40rem;
    margin: auto;
  }

  h2 {
    color: var(--light-black);
    font-size: 1.375rem;
    font-weight: 500;
    margin: 0;
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: var(--pelorous);
  }

  text-align: center;
}

.financingOffer {
  margin: 5rem auto;
  border-radius: 25px;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.11);
  padding: 2rem;
  position: relative;

  & > svg {
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  div svg {
    margin-top: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .amount {
    font-size: 1.25rem;

    span {
      color: var(--apple-green);
      font-size: 2.75rem;
      font-weight: 500;
      display: block;
      margin-top: 1rem;
    }
  }

  .details {
    font-size: 1.125rem;
    color: var(--text-dark-gray);

    strong {
      font-weight: 500;
      color: #000;
    }
  }

  .financeCompany {
    padding-top: 2rem;

    &::before {
      content: '';
      display: block;
      width: 3.75rem;
      height: 1px;
      background: var(--text-dark-gray);
      margin: auto;
      margin-bottom: 2rem;
    }

    p {
      color: var(--bali-hai);
      font-size: 0.875rem;
    }

    img {
      height: 2.5rem;
      margin: 1rem 0;
    }

    .companyName {
      color: var(--charcoal-gray);
      letter-spacing: 0.4px;
      font-size: 0.75rem;
    }
  }
}

.submitted {
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  max-width: 20rem;

  svg {
    width: 100%;
    margin: 1.5rem;
  }

  p {
    color: var(--charcoal-gray);
  }

  button {
    margin-top: 2rem;
  }
}

.usp {
  ul {
    padding: 0;
    margin: 0;
    display: grid;
    grid-gap: 2rem;

    @include for-desktop {
      grid-template-columns: repeat(3, 7rem);
      justify-content: space-around;
    }

    li {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: var(--bali-hai);

      svg {
        margin: 0.5rem 0 1rem;
      }
    }
  }
}

.howItWorks {
  margin: 3rem 0;

  p {
    color: var(--charcoal-gray);
  }
}

.interest {
  margin-bottom: 2rem;
  form {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;

    p {
      margin: 0;
      font-size: 0.75rem;
      text-align: left;
      color: var(--charcoal-gray);
    }

    button {
      grid-column: span 2;
      margin: auto;
      width: 100%;

      @include for-desktop {
        max-width: 26rem;
      }
    }
  }
}

.feedbackModal {
  padding: 2rem;
  display: grid;
  grid-row-gap: 1rem;
  max-width: 25rem;

  @include for-desktop {
    padding: 2rem 4rem;
  }

  h3 {
    color: var(--charcoal-gray);
    font-size: 1.125rem;
    font-weight: 500;

    span {
      color: var(--apple-green);
    }
  }

  small {
    font-size: 0.75rem;
    color: var(--text-light-gray);
    font-style: italic;
    line-height: 1.33;
    letter-spacing: 0.4px;
  }

  h3,
  p {
    margin: 0;
  }

  .needLoan {
    margin: 1rem 0;
    font-weight: 500;
    color: var(--charcoal-gray);
  }

  form {
    label {
      color: var(--charcoal-gray);
      margin-bottom: 0.5rem;
    }

    input[type='checkbox'] {
      &[disabled] {
        & ~ p {
          color: var(--very-light-gray);
        }
      }
    }

    input[type='text'] {
      margin-left: 2rem;
      border: none;
      border-bottom: 1px solid var(--text-light-gray);
      outline: none;

      &::placeholder {
        color: var(--text-light-gray);
      }

      &[disabled] {
        border-bottom: 1px solid var(--very-light-gray);

        &::placeholder {
          color: var(--very-light-gray);
        }
      }
    }

    small {
      margin: 1rem 0;
      display: block;
    }

    button {
      margin-top: 2rem;
      width: 100%;
    }
  }
}
