.touched {
  input {
    color: var(--apple-green);
  }
}

.buttonLayout {
  display: flex;
}

.icon {
  margin-right: 0.625rem;
}

.dateDisplay {
  color: var(--light-black);
  font-size: 1.0625rem;
  font-weight: 500;
  text-decoration: underline;
}

.layout {
  position: relative;
}

.airbnbSingleDatePicker {
  position: absolute;
  visibility: hidden;
  top: 0;
}
