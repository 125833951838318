@import 'mixins';

.statementsPageControl {
  display: flex;
  justify-content: flex-end;

  .statementsButtonDivider {
    width: 1px;
    height: 1.25rem;
    background-color: var(--dark-gray);
    margin: 0 var(--half-space);
  }
}

.statementsList {
  list-style: none;
  padding: 0;
  margin-top: 1.625rem;
  padding-bottom: 1rem;

  .statementsCard {
    margin: 0;
    padding: 1.6rem 0;
    margin-bottom: 1rem;
  }

  .statementsListContainer {
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 1.9rem;
  }

  .statementsListImage {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
  }

  .statementsListContent {
    display: flex;
    flex-flow: column;
    flex: 1;
    padding: 0 var(--space);

    .statementsListDate {
      color: var(--dark-saturated-blue);
      font-size: 0.8rem;
      font-weight: normal;
    }

    .statementsListPrice {
      color: var(--light-black);
      font-size: 1.125rem;
      font-weight: var(--bold);
      margin: 0;
    }

    .statementsAccountInfo {
      @extend .statementsListPrice;
      font-weight: 400;
      font-size: 1rem;
    }
  }

  .statementsListSelect {
    align-self: center;
  }
}

.revampedStatementList {
  position: relative;

  @keyframes moving-gradient {
    0% {
      background-position: -250px 0;
    }
    100% {
      background-position: 250px 0;
    }
  }

  .loadingState {
    height: 1rem;
    margin-right: 1.5rem;
    background: linear-gradient(
      to right,
      var(--light-gray) 20%,
      var(--gainsboro) 50%,
      var(--light-gray) 80%
    );
    border-radius: 10px;
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .tableHeader {
    @include for-desktop {
      padding-bottom: 2rem;
    }

    padding-bottom: 1.25rem;
    border-bottom: 1px solid var(--light-gray);

    .tableHeaderContainer {
      font-size: 0.8rem;
      color: var(--bali-hai);
      display: flex;
    }
  }

  .tableBody {
    .tableBodyContainer {
      height: 5rem;
      font-size: 0.9rem;
      color: var(--tundora);
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .tableRow {
    @include clamp-on-line(4);
    margin-right: 1rem;
    overflow-wrap: break-word;
    width: 20%;

    // outlets name
    &:first-child {
      width: 30%;
    }

    // right arrow icon
    &:last-child {
      width: 10%;
      text-align: right;
    }
  }
}

main .statementsEmpty {
  color: var(--dark-gray);
  font-weight: normal;
  font-size: 1.125rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 3.75rem 2rem;
  margin: 0;
  text-align: center;

  .statementImage {
    height: 10rem;
    width: 10rem;
  }

  .statementsEmptyData {
    margin-top: 0.94rem;
  }

  .statementsEmptyDescription {
    margin-top: 3.1rem;
    margin-bottom: 0.31rem;
  }
}

.statementsBottom {
  position: fixed;
  left: 0;
  bottom: 0;
  background: var(--light-gray);
  width: 100%;

  .statementsBottomContent {
    margin-left: auto;
    text-align: right;
    margin-right: auto;
    max-width: var(--max-width);
    padding: 0 var(--double-space);
    margin-bottom: var(--space);
  }

  .statementsSelectText {
    font-size: 0.875rem;
  }

  .statementsBottomIcon {
    margin-left: auto;
  }
}

.statementsEmailContainer {
  display: flex;
  width: 20rem;
  height: 5.313rem;
  margin-left: auto;
  justify-content: center;
  align-items: flex-start;

  label,
  button {
    margin: 0;
  }

  input {
    height: 3.125rem;
    border-radius: 5px;
    border-color: var(--very-light-grey);
    margin-right: 0.5rem;
  }

  button[type='submit'] {
    height: 3.125rem;
    border-radius: 5px;
    border: 1px solid var(--forest-green);
    background: var(--true-white);
    color: var(--forest-green);

    font-size: 1.125;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 0.26px;
    text-align: center;
    color: var(--forest-green);

    &:hover:not([disabled]) {
      color: var(--true-white);
    }

    &[disabled] {
      cursor: default;
      color: var(--gray);
      background: var(--light-gray);
    }
  }
}
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.8125rem;
  color: var(--gray);
  &Items {
    padding: 0.5rem;
    cursor: pointer;
  }
  &Active {
    border-radius: 50%;
    width: 2rem;
    padding-left: 0.75rem;
    background-color: var(--primary);
    color: var(--white);
  }
}
.statementsButtonRight {
  padding-left: 0.5rem;
}
.statementsButtonLeft {
  padding-right: 0.5rem;
}

.modal {
  // string '|' before 'view outlets'
  .separator {
    padding: 0 0.5rem;
  }

  .outOfBoundModal {
    display: inline-block;
  }

  .viewOutletButton {
    color: var(--robin-egg-blue);
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  .modalContents {
    display: none;
    position: absolute;
    background: var(--true-white);
    border: 1px solid var(--alto);
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.03);
    height: 15rem;
    max-width: 20rem;
    border-radius: 10px;
    z-index: 3;
    left: 0;
    margin-top: 0.5rem;

    @include for-mobile {
      width: 100%;
    }

    &.isOpen {
      display: flex;
      flex-direction: column;
    }

    .modalHeading {
      color: var(--charcoal-gray);
      font-size: var(--normal-font);
      font-weight: var(--bold);
      margin-bottom: 0;
    }

    .modalBankAccountNumber {
      color: var(--nobel);
      font-size: var(--small-font);
      margin-top: 0.5rem;
    }

    .modalOutletNames {
      color: var(--dark-black);
      overflow-y: scroll;
      margin-bottom: 1rem;
    }

    .modalContentTitle,
    .modalOutletNames {
      padding: 0 1rem;
    }

    .horizontalLine {
      border: 1px solid var(--white-smoke);
      width: 100%;
    }
  }
}

.loadingSpinner {
  display: flex;
  justify-content: center;
}
