.pinViewContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.verifyCode {
  height: 80%;

  > h3 {
    color: var(--fiord);
    font-weight: 500;
    margin: 0;
    font-size: 1.25rem;
  }

  > h5 {
    color: var(--text-light-gray);
    margin: 0.3125rem 0 1.5rem;
    font-weight: 500;
  }
}

.form {
  height: inherit;
  display: flex;
  flex-direction: column;
}

.verifyPinSubmit {
  margin-top: 2rem;
  width: 100%;
}

.resendSection {
  display: flex;
  justify-content: center;

  h5 {
    font-weight: 400;
    font-size: 0.875rem;
  }

  .resendButton {
    margin-left: 0.5rem;

    color: var(--pelorous);
    cursor: pointer;

    &[disabled] {
      color: var(--disabled);
    }

    &[disabled]:hover {
      cursor: not-allowed;
    }
  }
}

.footerSection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: inherit;
  align-content: center;
}

.resendTimerSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  .timer {
    color: var(--primary);
    font-size: 0.875rem;
    font-weight: 500;
  }

  .resendText {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;

    > p {
      margin: 0;
    }
  }
}
