@import '../mixins';

.layout {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  line-height: 1.38;
  padding: 1rem 0;
  transition: all ease 0.3s;
  border-bottom: 1px solid var(--gainsboro);
  position: relative;

  @mixin arrow($direction: down) {
    @include caret-down(
      $color: var(--bermuda-grey),
      $direction: $direction,
      $borderWidth: 0.095rem,
      $size: 0.7rem
    );
  }

  &.caretUp {
    @include arrow(up);
  }
  &.caretRight {
    @include arrow(right);
  }
  &.caretDown {
    @include arrow(down);
  }

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: var(--light-gray);
  }
}
