@import 'mixins';

.filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  button {
    margin-right: 0.75rem;
    margin-bottom: 0.5rem;
    padding-left: 0;
  }
}
.webviewSpacing {
  margin-top: 2rem;
}
.summaryLayout {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0 0;
  padding-top: 1rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border-top: 1px solid var(--whisper);
    width: 150%;
    left: -5rem;
  }

  dt {
    color: var(--bermuda-grey);
    font-size: 0.875rem;
  }

  dd {
    color: var(--fiord);
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.33;
  }

  dt,
  dd {
    margin: 0;
    grid-column: 1;

    &:last-of-type {
      grid-column: 2;
    }
  }

  dt:last-of-type {
    grid-row: 1;
  }
}

.cardLayout {
  max-width: 25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
  padding: 0.75rem 1.125rem;
  overflow: hidden;

  @include for-desktop {
    padding: 1.3125rem 1.875rem;
  }
}

.transactionsLayout {
  position: relative;
  margin-left: calc(var(--applayout-spacing) * -1);
  margin-right: calc(var(--applayout-spacing) * -1);
}

.transactionSpinner {
  top: 5rem;
}

.transactionList {
  padding: 0;
  list-style: none;
  margin-top: 1.5rem;

  &.noTopMargin {
    margin-top: 0;
  }

  > li {
    display: flex;
    justify-content: space-between;
    padding: 1rem var(--applayout-spacing);
    border-bottom: 1px solid var(--light-gray);

    &[disabled] {
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.125rem;
      font-weight: 500;
      color: var(--text-light-gray);
      > img {
        margin-bottom: 1.5rem;
      }
    }

    &.pickOutlet {
      font-size: 1.125rem;
      font-weight: 500;
      color: var(--text-light-gray);
    }

    &.loadMore {
      position: relative;
      justify-content: center;
    }

    &.pending_payment {
      background-color: var(--hint-yellow);
      border-radius: 4px;

      .pendingText {
        color: var(--tangerine-orange);
        font-size: 0.75rem;
      }

      .rightColumn {
        time {
          background-color: var(--true-white);
        }
      }
    }

    &.cancelled {
      background-color: var(--tutu);
      border-radius: 4px;

      .cancelledText {
        color: var(--primary);
        font-size: 0.75rem;
      }

      .rightColumn {
        time {
          background-color: var(--true-white);
        }
      }
    }

    &:hover:not([disabled]) {
      cursor: pointer;
      background-color: var(--light-gray);
    }

    .leftColumn {
      display: flex;
      color: var(--dark-gray);
      max-width: 100%;

      .transactionType {
        font-size: 0.5625rem;
        text-align: center;
        width: 3rem;
      }
      .transactionTypeIcon {
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;
        border: 1px solid var(--light-gray);
        margin-bottom: 0.25rem;
      }

      .transactionDetails {
        font-size: 0.8125rem;
        line-height: 1rem;
        margin-left: 0.75rem;

        .transactionID {
          max-width: 15ch;
          overflow-wrap: break-word;
          @include truncate;
        }
        .paidVia {
          margin-top: 0.25rem;
        }
      }
      .transactionHeading {
        color: var(--fiord);
        font-weight: 500;
        margin: 0 0 0.25rem;
      }
    }

    .rightColumn {
      text-align: right;

      time {
        display: inline-block;
        font-size: 0.8125rem;
        font-weight: 500;
        color: var(--heather);
        background-color: var(--white-smoke);
        border-radius: 5px;
        padding: 0.3125rem 0.625rem;
      }

      &.now {
        time {
          background-color: var(--aqua-spring);
          color: var(--apple-green);
        }
      }

      .amount {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5;
        color: var(--text-dark-gray);
        margin-top: 0.25rem;
      }
    }
  }
}

.newTransactions {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  transform: translate(0%, -100vh);
  margin-left: auto;
  margin-right: auto;
  border-radius: 22px;
  height: 2.75rem;
  padding: 0 1.75rem;
  letter-spacing: -0.39px;
  line-height: 1.1875rem;
  opacity: 0;

  .view {
    color: var(--light-green);
    margin-left: 0.5rem;
    text-decoration: underline;
  }

  &.visible {
    opacity: 1;
    position: sticky;
    transform: translate(-0%, 0rem);
    top: var(--buttonTop);
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.mapleQRFAB {
  position: fixed;
  z-index: 11;
  right: 1rem;
  bottom: 3rem;
  display: block;
}
