.newCardContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 16.8125rem;

  > div {
    margin-top: 1rem;
    color: var(--gray);
  }
}
