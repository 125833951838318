.label {
  textarea {
    color: var(--charcoal-gray);
    border-radius: 5px;
    line-height: 1.38;
    padding: 1rem;
    border: 1px solid var(--very-light-grey);
    resize: none;
    width: 100%;
  }
}
